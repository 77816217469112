import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  FunctionField,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { formatDate } from "../utils";
import { useTranslate } from 'react-admin';

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const exporter = (fuelConsumptions: any) => {
  console.log(fuelConsumptions);
  const fuelConsumptionsForExport = fuelConsumptions.map(
    (fuelConsumption: any) => {
      if (!fuelConsumption.vehicle) {
        fuelConsumption.vehicle = {
          "@id": "",
          "@type": "",
          parkingNumber: "",
          gear: "",
        };
      }
      const {
        "@id": arobasVehicleId,
        "@type": arobasVehicleType,
        createdAt,
        updatedAt: vehicleUpdatedAt,
        brand,
        model,
        ...vehicleForExport
      } = fuelConsumption.vehicle;
      fuelConsumption.quantityPerDiffMileage =
        fuelConsumption.quantityPerDiffMileage
          ? (fuelConsumption.quantityPerDiffMileage * 100).toFixed(1)
          : "-";
      fuelConsumption.quantityPerDiffTimeMeter =
        fuelConsumption.quantityPerDiffTimeMeter
          ? fuelConsumption.quantityPerDiffTimeMeter.toFixed(1)
          : "-";
      fuelConsumption.vehicle = vehicleForExport;
      if (!fuelConsumption.driver) {
        fuelConsumption.driver = {
          "@id": "",
          "@type": "",
          firstName: "",
          lastName: "",
          registrationNumber: "",
          attachments: [],
          createdAt: "",
          updatedAt: "",
        };
      }
      const {
        "@id": arobasDriverId,
        "@type": arobasDriverType,
        createdAt: driverCreatedAt,
        attachments: driverAttachments,
        registrationNumber: driverRegistrationNumber,
        updatedAt: driverUpdatedAt,
        ...driverForExport
      } = fuelConsumption.driver;
      fuelConsumption.driver = driverForExport;
      const {
        "@id": arobasId,
        "@type": arobasType,
        id,
        place,
        originId,
        endOfDayMeter,
        distribution,
        createdAt: fuelConsumptionCreatedAt,
        updatedAt: fuelConsumptionUpdatedAt,
        number,
        ...fuelConsumptionForExport
      } = fuelConsumption;
      fuelConsumptionForExport.date = formatDate(fuelConsumptionForExport.date);
      // fuelConsumptionForExport.date // omit backlinks and author
      return fuelConsumptionForExport;
    }
  );
  jsonexport(
    fuelConsumptionsForExport,
    {
      headers: [
        "date",
        "vehicle.parkingNumber",
        "vehicle.gear",
        "driver.firstName",
        "driver.lastName",
        "assignment",
        "type",
        "quantity",
        "mileage",
        "timeMeter",
        "diffMileage",
        "quantityPerDiffMileage",
        "diffTimeMeter",
        "quantityPerDiffTimeMeter",
      ],
      rename: [
        "Date de distribution",
        "N° de parc",
        "Équipement",
        "Prénom conducteur",
        "Nom conducteur",
        "Affectation",
        "Type de fluide",
        "Quantité (L)",
        "Kilométrage",
        "Heures Moteur",
        "KM parcouru",
        "Qté/Km parcouru(%)",
        "Hrs service",
        "Qté/Hrs service",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Consommations"); // download as 'posts.csv` file
    }
  );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;
const fullName = (choice: { firstName: string; lastName: string }) =>
  `${choice.firstName} ${choice.lastName}`;
const code = (choice: { code: string }) => `${choice.code}`;
const VehicleInfo = (choice: { parkingNumber: string }) =>
  `${choice.parkingNumber}`;

const FuelListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const FuelFilters = (translate: any) => {
  return [
    <TextInput /* label="Recherche" */ source={"query"} alwaysOn resettable />,
    <SelectInput
      source={"type"}
      //label="Type de fluide"
      alwaysOn
      choices={[
        { id: "Essence", name: translate('labels.Essence') },
        { id: "Gasoil", name: translate('labels.Gasoil') },
        { id: "AdBlue", name: translate('labels.AdBlue') },
        { id: "Huile moteur", name: translate('labels.Huilemoteur') },
      ]}
      resettable
    />,
    <DateInput /* label="Date de distribution" */ source={"date"} alwaysOn />,
    // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
  ];
};

export const FuelList: React.FC = (props) => {
  const permissions = localStorage.getItem("permissions");
  const translate = useTranslate();
  return (
    <List
      actions={<FuelListActions />}
      title={translate('labels.consumptions')}
      filters={FuelFilters(translate)}
      empty={<Empty create />}
      exporter={exporter}
      {...props}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <NumberField source={"number"} /* label={"Réf. de distribution"} */ />
        <DateField
          locales="fr-FR"
          source={"date"}
        //label={"Date de distribution"}
        />
        <WrapperField source="vehicle" /* label="Véhicule" */ sortBy="vehicle.parkingNumber">
          <TextField source={"vehicle.parkingNumber"} />
        </WrapperField>
        <TextField source={"vehicle.gear"} /* label="Équip." */ />
        <WrapperField source="driver" sortBy="driver.lastName">
          <TextField source={"driver.lastName"} />
          &nbsp;
          <TextField source={"driver.firstName"} />
        </WrapperField>
        <SelectField source={"assignment"} /* label={"Affectation"} */ choices={[
          { id: "Collecte OM", name: translate('labels.CollecteOM') },
          { id: "Collecte DV et grâvat", name: translate('labels.CollecteDVetgrâvat') },
          { id: "Nettoiement mécanisé", name: translate('labels.Nettoiementmécanisé') },
          { id: "Utilitaire", name: translate('labels.Utilitaire') },
        ]} />
        <SelectField source={"type"} /* label={"Type"} */ choices={[
          { id: "Essence", name: translate('labels.Essence') },
          { id: "Gasoil", name: translate('labels.Gasoil') },
          { id: "AdBlue", name: translate('labels.AdBlue') },
          { id: "Huile moteur", name: translate('labels.Huilemoteur') },
        ]} />
        <NumberField source={"quantity"} /* label={"Qté (L)"} */ />
        <FunctionField
          source={"diffMileage"}
          //label={"Km parcouru"}
          textAlign="right"
          render={(record: any) =>
            record.diffMileage ? record.diffMileage : "-"
          }
        />
        <FunctionField
          source={"quantityPerDiffMileage"}
          //label={"Qté/Km (%)"}
          textAlign="right"
          render={(record: any) =>
            record.quantityPerDiffMileage
              ? (record.quantityPerDiffMileage * 100).toFixed(1)
              : "-"
          }
        />
        <FunctionField
          source={"diffTimeMeter"}
          //label={"Hrs service"}
          textAlign="right"
          render={(record: any) =>
            record.diffTimeMeter ? record.diffTimeMeter : "-"
          }
        />
        <FunctionField
          source={"quantityPerDiffTimeMeter"}
          //label={"Qté/hrs service"}
          textAlign="right"
          render={(record: any) =>
            record.quantityPerDiffTimeMeter
              ? record.quantityPerDiffTimeMeter.toFixed(1)
              : "-"
          }
        />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /* label="Modifier" */ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const FuelCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/fuel-consumptions/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.Adddistribution')}
      mutationOptions={{ onSuccess }}
      {...props}
    >
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} /* label="Date de distribution" */ />
          <TextInput source={"number"} /* label="Réf. de distribution" */ />
          <ReferenceInput
            source={"vehicle"}
            reference="vehicles"
            perPage={1000}
            filter={{ exclude: "elec" }}
          >
            <AutocompleteInput
              optionText={VehicleInfo}
              //label="Véhicule"
              filterToQuery={filterToQuery}
            />
          </ReferenceInput>
          <ReferenceInput
            source={"driver"}
            reference="employees"
            queryOptions={{ meta: { query: "driver" } }}
            perPage={1000}
          >
            <AutocompleteInput optionText={fullName} /* label="Conducteur" */ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <ReferenceInput source={"card"} reference="cards" perPage={1000}>
            <AutocompleteInput optionText={code} /* label="Carte" */ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <SelectInput
            source={"type"}
            //label="Type de fluide"
            choices={[
              { id: "Essence", name: translate('labels.Essence') },
              { id: "Gasoil", name: translate('labels.Gasoil') },
              { id: "AdBlue", name: translate('labels.AdBlue') },
              { id: "Huile moteur", name: translate('labels.Huilemoteur') },
            ]}
          />
          <SelectInput
            source={"assignment"}
            //label="Affectation"
            choices={[
              { id: "Collecte OM", name: translate('labels.CollecteOM') },
              { id: "Collecte DV et grâvat", name: translate('labels.CollecteDVetgrâvat') },
              { id: "Nettoiement mécanisé", name: translate('labels.Nettoiementmécanisé') },
              { id: "Utilitaire", name: translate('labels.Utilitaire') },
            ]}
          />
          <NumberInput source={"timeMeter"} /* label="Heures Moteur" */ />
          <NumberInput source={"mileage"} /* label="Kilométrage" */ />
          <SelectInput
            source={"distribution"}
            //label="Type de distribution"
            choices={[
              { id: "Citerne", name: translate('labels.Citerne') },
              { id: "Carte", name: translate('labels.Carte') },
            ]}
          />

          <NumberInput source={"quantity"} /* label="Quantité (L)" */ />
          <NumberInput source={"price"} /* label="Montant (MAD)" */ />
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple
        >
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

interface FuelTitleProps {
  record: RaRecord;
}

const FuelTitle: React.FC<FuelTitleProps> = ({ record }) => {
  return <span>Distribution {record ? `"${record.title}"` : ""}</span>;
};

export const FuelEdit: React.FC<FuelTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été modifié`);
    redirect("/fuels/");
    refresh();
  };
  return (
    <Edit
      title={<FuelTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}
    >
      <SimpleForm>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} /* label="Date de distribution" */ />
          <TextInput source={"number"} /* label="Réf. de distribution" */ />
          <ReferenceInput
            source={"vehicle[@id]"}
            reference="vehicles"
            perPage={1000}
          >
            <AutocompleteInput
              optionText={VehicleInfo}
              //label="Véhicule"
              filterToQuery={filterToQuery}
            />
          </ReferenceInput>
          <ReferenceInput
            source={"driver[@id]"}
            reference="employees"
            queryOptions={{ meta: { query: "driver" } }}
            perPage={1000}
          >
            <AutocompleteInput optionText={fullName} /* label="Conducteur" */ />
          </ReferenceInput>
          <SelectInput
            source={"type"}
            //label="Type de fluide"
            choices={[
              { id: "Essence", name: translate('labels.Essence') },
              { id: "Gasoil", name: translate('labels.Gasoil') },
              { id: "AdBlue", name: translate('labels.AdBlue') },
              { id: "Huile moteur", name: translate('labels.Huilemoteur') },
            ]}
          />
          <SelectInput
            source={"assignment"}
            //label="Affectation"
            choices={[
              { id: "Collecte OM", name: translate('labels.CollecteOM') },
              { id: "Collecte DV et grâvat", name: translate('labels.CollecteDVetgrâvat') },
              { id: "Nettoiement mécanisé", name: translate('labels.Nettoiementmécanisé') },
              { id: "Utilitaire", name: translate('labels.Utilitaire') },
            ]}
          />
          <NumberInput source={"timeMeter"} /* label="Heures Moteur" */ />
          <NumberInput source={"mileage"} /* label="Kilométrage" */ />
          <SelectInput
            source={"distribution"}
            //label="Type de distribution"
            choices={[
              { id: "Citerne", name: translate('labels.Citerne') },
              { id: "Carte", name: translate('labels.Carte') },
            ]}
          />

          <NumberInput source={"quantity"} /* label="Quantité (L)" */ />
          <NumberInput source={"price"} /* label="Montant (MAD)" */ />
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple
        >
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export const FuelShow: React.FC<FuelTitleProps> = (props) => (
  <Show
    actions={<EditAction />}
    title={<FuelTitle record={props.record} />}
    {...props}
    sx={{ fontSize: "20px" }}
  >
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%"
      >
        <Labeled label="Véhicule">
          <DateField
            locales="fr-FR"
            source={"date"}
            //label="Date de distribution"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Réf. de distribution">
          <TextField
            source={"number"}
            //label="Réf. de distribution"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Véhicule">
          <Box display="flex" fontWeight="bold">
            <TextField source={"vehicle.parkingNumber"} />
            &nbsp;-&nbsp;
            <TextField source={"vehicle.brand"} />
            &nbsp;
            <TextField source={"vehicle.model"} />
          </Box>
        </Labeled>
        <Labeled label="Conducteur">
          <Box display="flex" fontWeight="bold">
            <TextField source={"driver.lastName"} />
            &nbsp;
            <TextField source={"driver.firstName"} />
          </Box>
        </Labeled>
        <Labeled label="Carte">
          <TextField source={"card.code"} />
        </Labeled>
        <Labeled label="Type de fluide">
          <TextField source={"type"} /* label="Type de fluide" */ fontWeight="bold" />
        </Labeled>
        <Labeled label="Affectation">
          <TextField
            source={"assignment"}
            //label="Affectation"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Heures Moteur">
          <TextField
            source={"timeMeter"}
            //label="Heures Moteur"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Kilométrage">
          <TextField source={"mileage"} /* label="Kilométrage" */ fontWeight="bold" />
        </Labeled>
        <Labeled label="Type de distribution">
          <TextField
            source={"distribution"}
            //label="Type de distribution"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Quantité (L)">
          <TextField source={"quantity"} /* label="Quantité (L)" */ fontWeight="bold" />
        </Labeled>
      </Box>
      <Labeled /* label="Fichiers" */ marginTop="16px">
        <FileField
          source={"attachments"}
          src="contentUrl"
          title="title"
          target="_blank"
        />
      </Labeled>
    </SimpleShowLayout>
  </Show>
);
