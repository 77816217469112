import { Download } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  FilterButton,
  FunctionField,
  List,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  required,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
} from "react-admin";
import { useState } from "react";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const permissions = localStorage.getItem("permissions");
const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;
const VehicleInfo = (choice: {
  parkingNumber: string;
}) => `${choice.parkingNumber}`;

const MaintenanceHistoryListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const MaintenanceHistoryFilters = [
  <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const MaintenanceHistoryList: React.FC = (props) => {

  const translate = useTranslate();

  return (
    <List
      actions={<MaintenanceHistoryListActions />}
      title={translate('titles.Historiquemaintenance')}
      filters={MaintenanceHistoryFilters}
      {...props}
    >
      <DatagridConfigurable bulkActionButtons={false}>
        <DateField locales="fr-FR" source={"date"} /*label={"Date de maintenance"}*/ />
        <WrapperField source="vehicle" /*label="Véhicule"*/ sortBy="vehicle.parkingNumber">
          <TextField source={"vehicle.parkingNumber"} />
          &nbsp;-&nbsp;
          <TextField source={"vehicle.brand"} />
          &nbsp;
          <TextField source={"vehicle.model"} />
        </WrapperField>
        <TextField source={"type"} /*label="Type de maintenance"}*/ />
        <FunctionField
          //label="Fiche"
          render={(attachments: { status: string; url: string }) => {
            return (
              <IconButton
                aria-label="Télécharger"
                onClick={() =>
                  window.open("../images/logo.png", "_blank", "noreferrer")
                }
              >
                <Download />
              </IconButton>
            );
          }}
        />
        <FieldWrapper label="Actions">
          &nbsp;
          {isGranted("ROLE_MANAGER", permissions) && (
            <EditButton /*label="Modifier"*/ />
          )}
          &nbsp;
          {isGranted("ROLE_MANAGER", permissions) && <DeleteWithConfirmButton />}
        </FieldWrapper>
      </DatagridConfigurable>
    </List>
  );
};

export const MaintenanceHistoryCreate: React.FC = (props) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [maintenanceType, setMaintenanceType] = useState("Kilométrique");

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/maintenance-cycles/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.addmaintenance')}
      // mutationOptions={{ onSuccess }}
      {...props}
    >
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} validate={[required("Champ obligatoire")]} /*label="Date de maintenance"*/ />
          <ReferenceInput
            source={"vehicle"}
            reference="vehicles"
            perPage={1000}
            onChange={(e) => {
              console.log(e.target.value);
              // setMaintenanceType(e.target.value.maintenanceType)
            }}
          >
            <AutocompleteInput validate={[required("Champ obligatoire")]} optionText={VehicleInfo} /*label="Véhicule"*/ />
          </ReferenceInput>
          <SelectInput
            source={"type"}
            //label="Type de maintenance"
            choices={[
              { id: "V1", name: "V1" },
              { id: "V2", name: "V2" },
              { id: "V3", name: "V3" },
              { id: "V4", name: "V4" },
              { id: "V5", name: "V5" },
            ]}
          />
        </Box>
        <FileInput source={"attachments"}>
          <FileField source={"src"} title="title" />
        </FileInput>
        {maintenanceType === "mileage" && (
          <NumberInput source={"timeMeter"} /*label="Heures moteur"*/ />
        )}
        {maintenanceType === "time" && (
          <NumberInput source={"mileage"} /*label="Kilométrage"*/ />
        )}
        <NumberInput source={"timeMeter"} /*label="Heures moteur"*/ />
        <NumberInput source={"mileage"} /*label="Kilométrage"*/ />
      </SimpleForm>
    </Create>
  );
};

interface MaintenanceHistoryTitleProps {
  record: RaRecord;
}

const MaintenanceHistoryTitle: React.FC<MaintenanceHistoryTitleProps> = ({
  record,
}) => {
  return <span>Maintenance {record ? `"${record.title}"` : ""}</span>;
};

const MaintenanceTypePicker = () => {
  const record = useRecordContext();
  console.log("record", record);
  if (record.vehicle?.maintenanceType === "time") {
    return <NumberInput source={"timeMeter"} /*label="Heures moteur"*/ />;
  } else if (record.vehicle?.maintenanceType === "mileage") {
    return <NumberInput source={"mileage"} /*label="Kilométrage"*/ />;
  }
  return <></>;
};

export const MaintenanceHistoryEdit: React.FC<MaintenanceHistoryTitleProps> = (
  props
) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`L'élément a bien été modifié`);
    redirect("/maintenance-cycles/");
    refresh();
  };
  return (
    <Edit
      title={<MaintenanceHistoryTitle record={props.record} />}
      {...props}
      mutationOptions={{ onSuccess }}
      mutationMode="pessimistic"
    >
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} validate={[required("Champ obligatoire")]} /*label="Date de maintenance"*/ />
          <MaintenanceTypePicker />
          {/* <SelectInput
            source="type"
            label="Type de maintenance"
            choices={[
              { id: "V0", name: "V0" },
              { id: "V1", name: "V1" },
              { id: "V2", name: "V2" },
              { id: "V3", name: "V3" },
              { id: "V4", name: "V5" },
            ]}
          /> */}
        </Box>
        <FileInput source={"attachments"} multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};
