import frenchMessages from 'ra-language-french';

export const fr = {
    ...frenchMessages,
    resources: {
        vehicles: {
            name: 'Véhicule |||| Véhicules',
            fields: {
                query: 'Rechercher',
                fuel: 'Carburant',
                levelD: 'Norme antipollution',
                parkingNumber: 'N° Parc',
                axle: "Essieu",
                registrationNumber: 'Immatriculation',
                gear: 'Équipement',
                brand: 'Marque',
                model: 'Modèle',
                type: 'Type',
                createdAt: 'Date de création',
                chassisNumber: 'N° chassis',
                registrationNumberW: 'Matricule WW',
                brandParent: 'E-marque',
                tires: 'Pneus',
                location: 'Lieu',
                administration: {
                    owner: 'Propriétaire',
                    operation: 'Exploitation',
                    affectation: 'Affectation analytique',
                    contract: 'Contrat',
                    contractStatus: 'Type de contrat',
                    status: 'Statut',
                    date: 'Date de mise en service',
                },
                equipment: {
                    washingEquipment: 'Équipement de levage',
                    towingHitch: 'Crochet d\'atellage',
                },
                leasing: {
                    contractNumber: 'N° de contrat leasing',
                    organization: 'Organisme',
                    status: 'État du contrat',
                    taxFreeMonthlyPayment: 'Mensualité HT',
                    duration: 'Durée',
                    vr: 'Valeur résiduelle',
                    acquisitionPrice: 'Prix d\'acquisition',
                    contractAmount: 'Montant du contrat',
                    contractStartDate: 'Début de contrat',
                    contractEndDate: 'Fin de contrat',
                },
                insurance: {
                    usage: 'Usage',
                    totalPlacesAllowed: 'Nombre de places autorisées',
                    totalSeats: 'Nombre de sièges',
                    taxHorsePower: 'Puissance fiscale (CV)',
                    der: 'DER',
                    damageValue: 'Valeur dommage',
                    fireValue: 'Valeur incendie',
                    brokenWindowValue: 'Valeur brise glace',
                    rcBonus: 'Prime RC',
                    damageBonus: 'Prime dommage',
                    fireBonus: 'Prime incendie',
                    theftBonus: 'Prime vol',
                    brokenWindowBonus: 'Prime brise glace',
                    totalValue: 'Total (Net)',
                    date: 'Date',
                },
                maintenanceType: 'Type de maintenance',
                lastVisit: 'Dernière visite',
                lastMileage: 'Kilométrage de la dernière visite',
                mileageInterval: 'Intervale entre les visites (Km)',
                lastTimeMeter: 'Compteur horaire de la dernière visite',
                timeInterval: 'Intervale entre les visites (heures)',
                ptac: 'PTAC (Kg)',
                pv: 'PV (Kg)',
                tank: 'Réservoir carburant (L)',
                power: 'Puissance fiscale',
                capacity: 'Capacité (m³)',
                registrationDate: 'Date d\'immatriculation',
                purchaseDate: 'Date d\'achat',
                vehiculeAge: 'Age du véhicule (années)',
            },
        },
        'maintenance-cycles': {
            name: 'Maintenance |||| Maintenances',
            fields: {
                query: 'Rechercher',
                'maintenance.vehicle': 'Véhicule de maintenance',
                parkingNumber: 'N° de parc',
                position: 'Cycle',
                visits: 'Visites',
                type: 'Type',
                date: 'Date de maintenance',
                vehicleParkingNumber: 'N° de parc',
                vehicleBrand: 'Marque du véhicule',
                vehicleModel: 'Modèle du véhicule',
                vehicle: 'Véhicule',
                attachments: 'Pièces jointes',
                src: 'Source',
                timeMeter: 'Heures moteur',
                mileage: 'Kilométrage',
            },
        },
        'vehicle-incident-histories': {
            name: 'Accident |||| Accidents',
            fields: {
                query: 'Rechercher',
                vehicle: 'Véhicule',
                driver: 'Conducteur',
                date: "Date et heure de l'incident",
                'vehicle.parkingNumber': 'Numéro de parc',
                'driver.lastName': 'Nom conducteur',
                'driver.firstName': 'Prénom conducteur',
                location: 'Lieu de l\'incident',
                costOfWork: 'Coût des travaux',
                responsibility: 'Responsabilité',
                meansOfProof: 'Moyens de preuve',
                bodilyDamage: 'Dégâts corporels',
                materialDamage: 'Dégâts matériels',
                circumstance: 'Circonstance de l\'accident',
                insuranceDeclarationDate: 'Date déclaration assurance',
                compensation: 'Indemnisation',
                images: 'Images',
                'vehicle.brand': 'Marque',
                'vehicle.model': 'Modèle',
            },
        },
        'vehicle-control-histories': {
            name: 'Contrôle |||| Contrôles',
            fields: {
                query: 'Rechercher',
                status: 'Statut',
                date: 'Date échéance',
                'vehicle.parkingNumber': 'Numéro de parc',
                designation: 'Type de contrôle',
                attachments: 'Fichiers',
                'vehicle.brand': 'Marque',
                'vehicle.model': 'Modèle',
                vehicle: 'véhicule',
                'vehicle[@id]': 'véhicule',
            },
        },
        'vehicle-funding-histories': {
            name: 'Financement |||| Financements',
            fields: {
                query: 'Rechercher',
                date: 'Date',
                'vehicle.parkingNumber': 'N° de parking du véhicule',
                refundAmount: 'Montant remboursé',
                vehicle: 'Véhicule',
                attachments: 'Pièces jointes',
                'vehicle[@id]': 'Véhicule',
            },
        },
        'vehicle-insurance-histories': {
            name: 'Insurance |||| Insurances',
            fields: {
                query: 'Rechercher',
                status: 'Statut',
                date: 'Date',
                'vehicle.parkingNumber': 'N° de parking du véhicule',
                totalAmountNet: 'Montant total (net)',
                vehicle: 'Véhicule',
                totalPlacesAllowed: 'Places totales autorisées',
                totalSeats: 'Nombre total de sièges',
                usage: 'Usage',
                taxHorsePower: 'Puissance fiscale',
                der: 'DER',
                damageValue: 'Valeur des dommages',
                fireValue: 'Valeur incendie',
                brokenWindowValue: 'Valeur des vitres brisées',
                rcBonus: 'Bonus RC',
                damageBonus: 'Bonus dommages',
                fireBonus: 'Bonus incendie',
                theftBonus: 'Bonus vol',
                brokenWindowBonus: 'Bonus vitres brisées',
                attachments: 'Pièces jointes',
                'vehicle[@id]': 'Véhicule',
            },
        },
        'vehicle-tax-histories': {
            name: 'Taxe |||| Taxes',
            fields: {
                query: 'Rechercher',
                status: 'Statut',
                date: 'Date',
                'vehicle.parkingNumber': 'N° de parking du véhicule',
                type: 'Type',
                amount: 'Montant',
                vehicle: 'Véhicule',
                attachments: 'Pièces jointes',
                'vehicle[@id]': 'Véhicule',
            },
        },
        'vehicle-immobility-histories': {
            name: 'Immobilité |||| Immobilités',
            fields: {
                query: 'Rechercher',
                status: 'Statut',
                date: 'Date',
                'vehicle.parkingNumber': 'N° de parking du véhicule',
                cause: 'Cause',
                vehicle: 'Véhicule',
                immobility: 'Immobilisation',
                'vehicle[@id]': 'Véhicule',
            },
        },
        'vehicle-intervention-histories': {
            name: 'Intervention |||| Interventions',
            fields: {
                query: 'Rechercher',
                nature: 'Nature d\'intervention',
                sheetNumber: 'N° fiche d\'intervention',
                'vehicle.parkingNumber': 'N° de parc',
                'vehicle[@id]': 'Véhicule',
                'vehicle.brand': 'Marque du véhicule',
                'vehicle.model': 'Modèle du véhicule',
                maintenanceType: 'Type de maintenance',
                totalCost: 'Coût total d\'intervention (MAD)',
                stopDate: 'Date d\'arrêt',
                returnToServiceDate: 'Date de mise en service',
                claim: 'Réclamation',
                vehicle: 'Véhicule',
                mileageMeter: 'Compteur (Km)',
                timeMeter: 'Compteur horaire',
                family: 'Famille',
                startDate: 'Date début d\'intervention',
                applicant: 'Intervenant',
                defunctSystem: 'Système défectueux',
                organFamily: 'Famille d\'organes',
                review: 'Avis sur l\'intervention',
                remainingWork: 'Travaux restants',
                type: 'Type d\'intervention',
                exitTicket: 'Bon de sortie',
                supplier: 'Fournisseur',
                purchaseOrder: 'N° bon de commande',
                quote: 'N° devis',
                delivery: 'N° bon de livraison',
                deliveryDate: 'Date de livraison',
                cashVoucherNumber: 'N° bon de caisse',
                articles: 'Articles',
                designation: 'Désignation',
                reference: 'Référence',
                unitPrice: 'Prix unitaire (MAD)',
                quantity: 'Quantité',
                totalPrice: 'Prix total (MAD)',
                state: 'État',
                subfamily: 'Sous-famille',
                test: 'Coût total d\'intervention',
            },
        },
        'vehicle-tire-histories': {
            name: 'Pneu |||| Pneus',
            fields: {
                query: 'Rechercher',
                date: 'Date',
                vehicle: 'Véhicule',
                'vehicle.parkingNumber': 'N° de parc',
                interventionType: 'Type d\'intervention',
                'vehicle[@id]': 'Véhicule',
                'source=vehicle.brand': 'Marque',
                'vehicle.model': 'Modèle',
                mileage: 'Kilométrage',
                serialNumber: 'N° de série',
                biNumber: 'Numéro BI',
                dimension: 'Dimension',
                price: 'Prix',
                type: 'Type',
                tire: 'Pneu',
                images: 'Images',
            },
        },
        'vehicle-maintenance-histories': {
            name: 'Maintenance |||| Maintenances',
            fields: {
                query: 'Rechercher',
                'maintenance.vehicle': 'Véhicule de maintenance',
                parkingNumber: 'N° de parc',
                position: 'Position',
                visits: 'Visites',
                type: 'Type',
                date: 'Date de maintenance',
                vehicleParkingNumber: 'N° de parc',
                vehicleBrand: 'Marque du véhicule',
                vehicleModel: 'Modèle du véhicule',
                vehicle: 'Véhicule',
                attachments: 'Pièces jointes',
                src: 'Source',
                timeMeter: 'Heures moteur',
                mileage: 'Kilométrage',
            },
        },
        'stock-articles': {
            name: 'Stock |||| Stocks',
            fields: {
                query: 'Rechercher',
                subfamily: 'Sous-famille',
                designation: 'Désignation',
                code: 'Code',
                'supplier.code': 'Code fournisseur',
                'supplier.name': 'Nom fournisseur',
                blNumber: 'Numéro BL',
                date: 'Date',
                brand: 'Marque',
                images: 'Images',
                supplier: 'Fournisseur',
                type: 'Type',
                ref: 'Référence',
                family: 'Famille',
                state: 'État',
                quantity: 'Quantité',
                amount: 'Montant',
            },
        },
        'stock-movements': {
            name: 'Stock |||| Stocks',
            fields: {
                query: 'Rechercher',
                date: 'Date',
                movement: 'Mouvement',
                blbsNumber: 'Numéro BL',
                'article.designation': 'Article designation',
                bsNumber: 'Numéro BS',
                fiNumber: 'Numéro FI',
                article: 'Article',
            },
        },
        suppliers: {
            name: 'Fournisseur |||| Fournisseurs',
            fields: {
                query: 'Rechercher',
                code: 'Code',
                name: 'Nom',
                phone: 'Téléphone',
                email: 'Email',
                address: 'Adresse',
            },
        },
        employees: {
            name: 'Employé |||| Employés',
            fields: {
                query: 'Rechercher',
                lastName: 'Nom',
                firstName: 'Prénom',
                dueDate: 'Date d\'échéance',
                attachments: 'Pièces jointes',
                exploitation: 'Exploitation',
                registrationNumber: "Matricule",
                socialSecurityNumber: "Numéro CNSS",
                hiringDate: "Date d’embauche",
                birthDate: "Date de naissance",
                nationalId: "CIN",
                familySituation: "Situation familiale",
                childrenNumber: "Nombre d’enfant",
                phoneNumber: "Tel",
                address: "Adresse",
                status: "Statut",
                startTime: "Heure début",
                endTime: "Heure fin",
                service: "Service",
                function: "Fonction",
                contractType: "Type de Contrat",
                serviceProvided: "Prestation",
                dayOff: "Jour de repos",
                replacement: "Remplaçant",
                sector: "Secteur",
                supervisor: "Responsable hiérarchique",
                baseSalary: "Salaire de Base",
                seniorityBonus: "Prime d’ancienneté",
                attendanceBonus: "Indemnité de présentation",
                transportBonus: "Indemnité de transport",
                parenthoodBonus: "Prime de Lait",
                annualBonus: "Prime annuelle",
                schoolBonus: "Prime Scolarité",
                achouraBonus: "Prime Achoura",
                adhaBonus: "Prime Aïd el Kébir",
                extraBonus: "Prime de Salissure"
            },
        },
        containers: {
            name: 'Conteneur |||| Conteneurs',
            fields: {
                query: 'Rechercher',
                number: 'Numéro',
                volume: 'Volume',
                supplier: 'Fournisseur',
                status: 'Statut',
                type: 'Type',
                blNumber: 'N° bon de livraison',
                bsNumber: 'N° bon de sortie',
                fdNumber: 'N° fiche de dotation',
                startDate: 'Date de mise en place',
                allocation: 'Type de dotation',
                location: 'Adresse',
                latitude: 'Latitude',
                longitude: 'Longitude',
                sector: 'Arrondissement',
                stockEnterDate: 'Date entrée stock',
                stockExitDate: 'Date sortie stock',
                price: 'Prix (MAD)',
                images: 'Images',
            },
        },
        'container-reforms': {
            name: 'Conteneur |||| Conteneurs',
            fields: {
                query: 'Rechercher',
                'container.number': 'N° du bac',
                'container.type': 'Type du bac',
                cause: 'Cause',
                date: 'Date',
                lifecycle: 'Durée de vie',
                images: 'Images',
                'container[@id]': 'ID du bac',
                container: 'récipient',
            },
        },
        'container-maintenances': {
            name: 'Conteneur |||| Conteneurs',
            fields: {
                query: 'Rechercher',
                'container.number': 'N° du bac',
                'container.type': 'Type du bac',
                date: 'Date de maintenance',
                number: 'N° intervention',
                type: 'Type de maintenance',
                'container[@id]': 'Bac',
                container: 'récipient',
            },
        },
        'fuel-allocations': {
            name: 'Carburant |||| Carburants',
            fields: {
                query: 'Rechercher',
                date: 'Date de dotation',
                fuel: 'Type de fluide',
                blNumber: 'Numéro de BL',
                quantity: 'Quantité (L)',
                totalPrice: 'Prix total (MAD)',
                attachments: 'Fichiers',
                src: 'Source',
            },
        },
        'fuel-consumptions': {
            name: 'Carburant |||| Carburants',
            fields: {
                query: 'Rechercher',
                type: 'Type de fluide',
                date: 'Date de distribution',
                number: 'Réf de distribution',
                'vehicle.parkingNumber': 'N° de parc',
                'vehicle.gear': 'Équipement',
                'driver.lastName': 'Nom du conducteur',
                'driver.firstName': 'Prénom du conducteur',
                assignment: 'Affectation',
                quantity: 'Quantité (L)',
                diffMileage: 'Km parcouru',
                quantityPerDiffMileage: 'Qté/Km parcouru (%)',
                diffTimeMeter: 'Hrs service',
                quantityPerDiffTimeMeter: 'Qté/Hrs service',
                src: 'Fichiers',
                timeMeter: 'Heures moteur',
                mileage: 'Kilométrage',
                driver: 'Chauffeur',
                price: 'Prix',
            },
        },
        cards: {
            name: 'Carte |||| Cartes',
            fields: {
                query: 'Rechercher',
                date: 'Date',
                code: 'Code',
                supplier: 'Fournisseur',
                amount: 'Montant (MAD)',
                attachments: 'Fichiers',
                src: 'Source',
            },
        },
        'stock-articles': {
            name: 'Article |||| Articles',
            fields: {
                query: 'Rechercher',
                date: 'Date',
                code: 'Code',
                supplier: 'Fournisseur',
                brand: 'Marque',
                type: 'Modèle',
                family: 'Famille',
                subfamily: 'Sous-famille',
                ref: 'Référence',
                createdAt: 'Ajouté le',
                quantity: 'Quantité en stock',
            },
        },
        'stock-movements': {
            name: 'Entrée/Sortie |||| Entrées/Sorties',
            fields: {
                query: 'Rechercher',
                movement: 'Entrée/Sortie',
                blbsNumber: 'N° BL',
                quantity: "Nombre d'articles",
                unitPrice: 'Prix unitaire',
                state: 'État',
                bsNumber: 'N° BS',
                fiNumber: 'N° FI',
                applicant: 'Demandeur',
                vehicle: 'Véhicule',
            },
        },
        'presences': {
            name: "Absence |||| Absences",
            fields: {
                query: 'Rechercher',
                employee: "Employé",
                timeSheet: "Feuille de route"
            }
        },
        'overtimes': {
            name: "Heure supplémentaire |||| Heures supplémentaires",
            fields: {
                query: 'Rechercher',
                date: "Date de présence",
                employee: "Employé",
                reason: "Motif",
                startTime: "Heure début",
                endTime: "Heure fin",
                numberOfHours: "Nombre d'heures",
                premium: "Prime",
                responsable: "Résponsable",
            }
        },
        'trainings': {
            name: "Formation |||| Formations",
            fields: {
                query: 'Rechercher',
                employee: "Employé",
                trainingDate: "Date de formation",
                trainingTitle: "Formation",
                typeOfTraining: "Type",
            }
        },
        'medical-visits': {
            name: "Visite médicale |||| Visites médicales",
            fields: {
                query: 'Rechercher',
                agent: "Employé",
                typeDeVisit: "Type de visite",


            }
        },
        'traffic-code-violations': {
            name: "Infraction |||| Infractions",
            fields: {
                query: 'Rechercher',
                conducteur: "Chauffeur",
            }
        },
        'vacations': {
            name: "Congé |||| Congés",
            fields: {
                query: 'Rechercher',
                employee: "Salarié",
                requestDate: "Date de demande",
                departureDate: "Date de départ",
                returnDate: "Date de reprise",
                remainingDaysPreviousYear: "Jours restants de l'année précédente",
                remainingDaysCurrentYear: "Jours restants de l'année en cours",
                requestedLeaveDays: "Jours de congé demandés",
                newPaidLeaveBalance: "Nouveau solde de congés payés",
                authorizationDate: "Date d'autorisation",
                authorizationType: "Type d'autorisation",
                hrApprovalDate: "Date d'approbation RH"
            }
        },
        "work-accidents": {
            name: "Accident de travail |||| Accidents de travail",
            fields: {
                query: 'Rechercher',
                accidentDate: "Date d'accident",
                accidentType: "Type d'accident",
                victim: "Victime",
                numberOfDaysOff: "Nombre de jours d'arrêt",
                circumstance: "Circonstance",
                lesion: "Lésion",
                typology: "Typologie",
            },
        },
        neighborhoods:{
            name: "Quartier |||| Quartiers",
            fields: {
                query: 'Rechercher',
            },
        },
        streets:{
            name: "Rue |||| Rues",
            fields: {
                query: 'Rechercher',
            },
        },
        'sensitive-points':{
            name: "Point sensible |||| Points sensibles",
            fields: {
                query: 'Rechercher',
            },
        },
        streets:{
            name: "Rue |||| Rues",
            fields: {
                query: 'Rechercher',
            },
        },
        cleanings:{
            name: "Nettoiement |||| Nettoiements",
            fields: {
                query: 'Rechercher',
            },
        },
        collectings:{
            name: "Collecte |||| Collectes",
            fields: {
                query: 'Rechercher',
            },
        },
    },
    titles: {
        employees: "Total Personnel",
        absenserate: "Taux d'absence",
        totalnumberoftrafficcodeviolations: "Infractions du code de la route",
        totalnumberoftrainings: "Nombre de formations",
        daysofvacations: "Jours de congés",
        hoursofovertime: "Heures sup",
        demandevacation: "Demande de vacances",
        situationcongepayees: "Situation des congés payés",
        authorisationhr: "Autorisation RH",
        directionrh: "Direction RH",
        personnal: 'Personnel',
        caracteristiques: 'Caractéristiques techniques',
        situationAdministrative: 'Situation administrative',
        equipementEmbarque: 'Équipement embarqué',
        financement: 'Financement',
        assurance: 'Assurance',
        maintenanceVehicule: 'Maintenance du véhicule',
        tauxDisponibilite: 'Taux de disponibilité',
        typeInterventions: 'Type d\'interventions',
        immobilisationsJour: 'Immobilisations du jour',
        alertesCarburant: 'Alertes carburant',
        alertesControles: 'Alertes visites techn.',
        alertesAssurances: 'Alertes assurances',
        alertesTaxesVignettes: 'Alertes taxes/vignettes',
        consommationGasoil: 'Consommation gasoil',
        consommationEssence: 'Consommation essence',
        demandedintervention: 'Demande d\'intervention',
        exécutiondintervention: 'Exécution d\'intervention',
        restitution: 'Restitution',
        articles: 'Articles',
        Pneumatiques: 'Pneus',
        Calendriermaintenance: 'Calendrier des maintenances',
        Historiquemaintenance: 'Historique des maintenances',
        Téléchargercanevas: 'Télécharger canevas',
        Mouvementstock: 'Mouvements de stock',
        suppliers: 'Fournisseurs',
        bacs: 'Bacs',
        reforms: 'Réformes',
        maintenances: 'Maintenances',
        typereforms: 'Type de réformes',
        showbin: 'Afficher un bac',
        modifybin: 'Modifier un bac',
        ContainerReform: 'Réforme des bacs',
        AddReform: 'Ajouter une réforme',
        EditReform: 'Modifier la réforme',
        Réapprovisionnements: 'Réapprovisionnements',
        Réapprovisionnement: 'Réapprovisionnement',
        /*Addendowment: 'Ajouter une dotation',
        Adddistribution: 'Ajouter une distribution',
        presence: "Présence",
        overtime: "Heures supplémentaires",
        vacation: "Congés",
        workAccident: "Accidents de travail",
        medicalVisit: "Visit medical",
        demandevacation: "Demande de congé",
        situationcongépayées: "Paid vacation status",
        Authorisationhr: "Authorisation du spérieur hiérarchique",
        directionrh: "Direction RH", */
        informationspersonnelles: "Informations Personnelles",
        informationfonctionnelles: "Informations Fonctionnelle",
        rémunération: "Rémunération"

    },
    listItems: {
        tauxDisponibiliteMoisDernier: 'Taux de disponibilité mois M-1',
        tauxDisponibiliteMoisCourant: 'Taux de disponibilité mois M',
        tauxDisponibiliteJournalier: 'Taux de disponibilité journalier',
        enAttente: 'En attente',
        enUrgence: 'En urgence',
        enCours: 'En cours',
        totalApprovisionnementGasoil: 'Total approvisionnement Gasoil (Litres)',
        totalApprovisionnementEssence: 'Total approvisionnement Essence (Litres)',
        alerteConsommation: 'Alerte consommation (>100 Litres)',
        aVenir: 'À venir (< 15jours)',
        enRetard: 'En retard',

    },
    labels: {
        dashboardHr: "Tableau de bord RH",
        effectue: "Effectué",
        'non-effectue': "Non effectué",
        in: 'Entrée',
        out: 'Sortie',
        oui: 'Oui',
        non: 'Non',
        encours: 'En cours',
        PVpolice: 'PV police',
        PVgendarme: 'PV gendarme',
        Constatàlamiable: 'Constat à l\'amiable',
        Déclarationsécritesdestemoins: 'Déclarations écrites des temoins',
        Changementdefil: 'Changement de fil',
        Nonrespectdelapriorité: 'Non-respect de la priorité',
        Pertedecontrôle: 'Perte de contrôle',
        Excèsdevitesse: 'Excès de vitesse',
        Arrêtobligatoirenonrespecté: 'Arrêt obligatoire non respecté',
        Défautmécanique: 'Défaut mécanique',
        CU: 'CU (Kg)',
        dashboard: 'Tableau de bord',
        fleet: 'Flotte',
        vehicles: 'Véhicules',
        vehiclesList: "Liste véhicules",
        accidents: 'Accidents',
        controls: 'Visites techniques',
        funding: 'Financements',
        insurance: 'Assurances',
        taxes: 'Taxes/Vignettes',
        immobilizations: 'Immobilisations',
        fluids: 'Fluides',
        replenishment: 'Réapprovisionnement',
        consumptions: 'Consommations',
        fuelCards: 'Cartes carburant',
        fuelCard: 'Carte carburant',
        interventions: 'Interventions',
        tires: 'Pneus',
        tire: 'Pneu',
        maintenance: 'Maintenance',
        stock: 'Stock',
        articles: 'Articles',
        movements: 'Mouvements',
        suppliers: 'Fournisseurs',
        personnel: 'Personnel',
        bins: 'Bacs',
        binsList: 'Liste des bacs',
        binsReform: 'Réforme des bacs',
        binsMaintenance: 'Maintenance des bacs',
        new: 'Nouveau',
        corrective: 'Corréctive',
        preventive: 'Préventive',
        incident: 'Incident',
        dépannage: 'Dépannage',
        costinterventions: 'Coût des interventions (MAD)',
        percentageinterventions: 'Pourcentage des interventions (%)',
        availability30days: 'Disponibilité des 30 derniers jours (en %)',
        consumptionliters: 'Consommation en litres',
        consumptioncurrency: 'Consommation en devise',
        typereforms: 'Type de réformes',
        casse: 'Casse',
        vol: 'Vol',
        incendie: 'Incendie',
        autre: 'Autre',
        effectue: 'Effectué',
        avenir: 'À venir',
        enretard: 'En retard',
        noneffectue: 'Non effectué',
        visitetechnique: 'Visite technique',
        visitetachygraphe: 'Visite tachygraphe',
        extincteurs: 'Extincteurs',
        Taxe: 'Taxe',
        Vignette: 'Vignette',
        maintenancecorrective: 'Maintenance corrective',
        maintenancepréventive: 'Maintenance préventive',
        Boitedevitesse: 'Boite de vitesse',
        Cabine: 'Cabine',
        Circuitdegazoil: 'Circuit de gazoil',
        Circuitderefroidissement: 'Circuit de refroidissement',
        Circuitélectrique: 'Circuit électrique',
        Circuitpneumatique: 'Circuit pneumatique',
        CircuitAdBlue: 'Circuit AdBlue',
        Circuitdeau: 'Circuit d\'eau',
        Capteurs: 'Capteurs',
        Moteurcannexe: 'Moteur et c. annexe',
        Embrayage: 'Embrayage',
        EmbrayageTransmission: 'Embrayage/Transmission',
        Mécanismedefreinage: 'Mécanisme de freinage',
        Systèmedefreinage: 'Système de freinage',
        Vannelixiviat: 'Vanne lixiviat',
        Moteur: 'Moteur',
        Roue: 'Roue',
        Pneumatique: 'Pneus',
        Suspension: 'Suspension',
        Direction: 'Direction',
        Lamederessort: 'Lame de ressort',
        Transmission: 'Transmission',
        Accessoireshydraulique: 'Accessoires hydraulique',
        Accessoirespneumatique: 'Accessoires pneumatique',
        Balais: 'Balais',
        Chaudronnerie: 'Chaudronnerie',
        Distributeur: 'Distributeur',
        Flexible: 'Flexible',
        FlexibleRaccord: 'Flexible/Raccord',
        Huilehydraulique: 'Huile hydraulique',
        Peinture: 'Peinture',
        Pompe: 'Pompe',
        Prisedeforce: 'Prise de force',
        Vérin: 'Vérin',
        Vérins: 'Vérins',
        Réparation: 'Réparation',
        Changement: 'Changement',
        Original: 'Original',
        Rechapé: 'Rechapé',
        Chassis: 'Chassis',
        Equipement: 'Equipement',
        ChassisEquipement: 'Chassis/Equipement',
        Dressage: 'Dressage',
        Réparation: 'Réparation',
        Remplacement: 'Remplacement',
        Contrôle: 'Contrôle',
        Réglage: 'Réglage',
        Serrage: 'Serrage',
        Appoint: 'Appoint',
        Efficace: 'Efficace',
        Inefficace: 'Inefficace',
        Interne: 'Interne',
        Externe: 'Externe',
        Neuf: 'Neuf',
        Bonneoccasion: 'Bonne occasion',
        Réparé: 'Réparé',
        Confectionné: 'Confectionné',
        Conducteur: 'Conducteur',
        Intervenant: 'Intervenant',
        Contractuel: 'Contractuel',
        NonContractuel: 'Non contractuel',
        Interim: 'Interim',
        Bacmétallique: 'Bac métallique',
        BacPlastique: 'Bac Plastique',
        caissonampliROL: 'caisson ampli ROL',
        caissonmultibenne: 'caisson multi-benne',
        BAV: 'BAV',
        Corbeilledeville: 'Corbeille de ville',
        Corbeilledeplage: 'Corbeille de plage',
        Publique: 'Publique',
        Privée: 'Privée',
        containernumber: 'N° du bac',
        containertype: 'Type du bac',
        Wheelchange: 'Changement de roue',
        Lidchange: 'Changement de couvercle',
        Accessories: 'Accessoires',
        Essence: 'Essence',
        Gasoil: 'Gasoil',
        Electrique: 'Electrique',
        AdBlue: 'AdBlue',
        Huilemoteur: 'Huile moteur',
        CollecteOM: 'Collecte OM',
        CollecteDVetgrâvat: 'Collecte DV et grâvat',
        Nettoiementmécanisé: 'Nettoiement mécanisé',
        Utilitaire: 'Utilitaire',
        Citerne: 'Citerne',
        Carte: 'Carte',
        BOM: 'BOM',
        'BOM+Grue': 'BOM+Grue',
        'Benne Satellite': 'Benne Satellite',
        BTP: 'BTP',
        'BTP avec grappin': 'BTP avec grappin',
        AMPL: 'AMPL',
        'Mini AMPL': 'Mini AMPL',
        'Balayeuse CEKSAN': 'Balayeuse CEKSAN',
        'Citerne laveuse': 'Citerne laveuse',
        Balayeuse: 'Balayeuse',
        'Laveuse automatique': 'Laveuse automatique',
        'Laveuse avec grue': 'Laveuse avec grue',
        'Chargeur sur roue': 'Chargeur sur roue',
        JCB: 'JCB',
        Tracteur: 'Tracteur',
        Aspirateur: 'Aspirateur',
        'Tricycle électrique': 'Tricycle électrique',
        Motocycle: 'Motocycle',
        'Véhicule de service': 'Véhicule de service',
        'Véhicule utilitaire': 'Véhicule utilitaire',
        'Atelier mobile': 'Atelier mobile',
        'Mini-Laveuse': 'Mini-Laveuse',
        'AMPL+GRUE': 'AMPL+GRUE',
        Benne: 'Benne',
        Broyeur: 'Broyeur',
        Chargeuse: 'Chargeuse',
        Compacteur: 'Compacteur',
        Camion: 'Camion',
        Cribleuse: 'Cribleuse',
        Liaison: 'Liaison',
        Mobylette: 'Mobylette',
        Multibenne: 'Multibenne',
        Tractopelle: 'Tractopelle',
        Tricycle: 'Tricycle',
        'Bac plastique': 'Bac plastique',
        BAV: 'BAV',
        Plateforme: 'Plateforme',
        Corbeille: 'Corbeille',
        Laveuse: 'Laveuse',
        'Mini camion BOM de 3,5T': 'Mini BOM de 3,5T',
        'Mini camion BOM de 7,5T': 'Mini BOM de 7,5T',
        'Camion BOM de 26T': 'BOM de 26T',
        'Camion BOM de 19T': 'BOM de 19T',
        'Camion BOM de 12T': 'BOM de 12T',
        'Camion Ampliroll 19T grue & grappin': 'Ampliroll + Grue 19T',
        'Camion Ampliroll 26T grue & grappin': 'Ampliroll + Grue 26T',
        'Mini Camion 7,5T grue & grappin': 'Mini Camion + Grue 7,5 T',
        'Mini Camion 9,5T grue & grappin': 'Mini Camion + Grue 9,5 T',
        'Camion Plateau 3,5 T': 'Camion Plateau 3,5 T',
        'Chargement arr': 'Chargement arr',
        'Deux roues': 'Deux roues',
        'Quatre Roues': 'Quatre Roues',
        'Plateau': 'Plateau',
        'Mécanique': 'Mécanique',
        'Aspiratrice': 'Aspiratrice',
        'Sur pneus': 'Sur pneus',
        'Bras coulissante': 'Bras coulissante',
        'Citerne': 'Citerne',
        'Voiries': 'Voiries',
        'Basculante': 'Basculante',
        'sur berce AMPL': 'sur berce AMPL',
        'Champignon': 'Champignon',
        'Double Crochet': 'Double Crochet',
        'Hydraulique': 'Hydraulique',
        'VL': 'VL',
        Actif: 'Actif',
        Inactif: 'Inactif',
        Kilométrique: 'Kilométrique',
        Horaire: 'Horaire',
        'month1-24': "Janvier 24",
        'month2-24': "Février 24",
        'month3-24': "Mars 24",
        'month4-24': "Avril 24",
        'month5-24': "Mai 24",
        'month6-24': "Juin 24",
        'month7-24': "Juillet 24",
        'month8-24': "Août 24",
        'month9-24': "Septembre 24",
        'month10-24': "Octobre 24",
        'month11-24': "Novembre 24",
        'month12-24': "Décembre 24",
        month: 'Mois',
        employees: "Liste de personnel",
        presences: "Absences",
        overtimes: "Heures sup",
        vacations: "Congés",
        workAccidents: "Accidents travail",
        trafficCodeViolations: "Infractions",
        medicalVisits: "Visites Medicales",
        trainings: "Formations",
        approved: "Approuvé",
        denied: "Refusé",
        DirecteurExploitation: "Directeur d'Exploitation",
        ResponsableExploitation: "Responsable d'Exploitation",
        AssistanteDeDirection: "Assistante de Direction",
        ResponsableRH: "Responsable RH",
        ResponsableHSQE: "Responsable HSQE",
        RelaiRH: "Relai RH",
        Surveillant: "Surveillant",
        ChefEquipe: "Chef d'Équipe",
        ChauffeurFormateur: "Chauffeur Formateur",
        ChauffeurPL: "Chauffeur PL",
        ChauffeurVL: "Chauffeur VL",
        Ripeur: "Ripeur",
        Balayeuse: "Balayeuse",
        Laveur: "Laveur",
        AgentAdministratif: "Agent Administratif",
        ChefParc: "Chef de Parc",
        ChefAtelier: "Chef d'Atelier",
        Mecanicien: "Mécanicien",
        AideMecanicien: "Aide Mécanicien",
        Tolier: "Tôlier",
        Chaudronnier: "Chaudronnier",
        Hydraulicien: "Hydraulicien",
        Pneumaticien: "Pneumaticien",
        Magasinier: "Magasinier",
        AideMagasinier: "Aide Magasinier",
        Pompiste: "Pompiste",
        AgentPontBascule: "Agent Pont Bascule",
        Gardien: "Gardien"

    },
};
