import englishMessages from 'ra-language-english';

export const en = {
    ...englishMessages,
    resources: {
        vehicles: {
            name: 'Vehicle |||| Vehicles',
            fields: {
                search: 'Search',
                parkingNumber: 'Parking No',
                registrationNumber: 'Registration Number',
                gear: 'Gear',
                brand: 'Brand',
                model: 'Model',
                type: 'Type',
                createdAt: 'Creation Date',
                chassisNumber: 'Chassis No',
                registrationNumberW: 'WW Registration',
                brandParent: 'Parent Brand',
                tires: 'Tires',
                location: 'Location',
                administration: {
                    owner: 'Owner',
                    operation: 'Operation',
                    affectation: 'Analytical Allocation',
                    contract: 'Contract',
                    contractStatus: 'Contract Type',
                    status: 'Status',
                    date: 'Service Start Date',
                },
                equipment: {
                    washingEquipment: 'Lifting Equipment',
                    towingHitch: 'Towing Hitch',
                },
                leasing: {
                    contractNumber: 'Leasing Contract No',
                    organization: 'Organization',
                    status: 'Contract Status',
                    taxFreeMonthlyPayment: 'Tax-Free Monthly Payment',
                    duration: 'Duration',
                    vr: 'Residual Value',
                    acquisitionPrice: 'Acquisition Price',
                    contractAmount: 'Contract Amount',
                    contractStartDate: 'Contract Start Date',
                    contractEndDate: 'Contract End Date',
                },
                insurance: {
                    usage: 'Usage',
                    totalPlacesAllowed: 'Total Allowed Places',
                    totalSeats: 'Total Seats',
                    taxHorsePower: 'Tax Horse Power (CV)',
                    der: 'DER',
                    damageValue: 'Damage Value',
                    fireValue: 'Fire Value',
                    brokenWindowValue: 'Broken Window Value',
                    rcBonus: 'RC Bonus',
                    damageBonus: 'Damage Bonus',
                    fireBonus: 'Fire Bonus',
                    theftBonus: 'Theft Bonus',
                    brokenWindowBonus: 'Broken Window Bonus',
                    totalValue: 'Total (Net)',
                    date: 'Date',
                },
                maintenance: {
                    type: 'Maintenance Type',
                    lastVisit: 'Last Visit',
                    lastMileage: 'Last Visit Mileage',
                    mileageInterval: 'Mileage Interval (Km)',
                    lastTimeMeter: 'Last Visit Time Meter',
                    timeInterval: 'Time Interval (hours)',
                },
                ptac: 'GVWR (Kg)',
                pv: 'Curb Weight (Kg)',
                tank: 'Fuel Tank (L)',
                power: 'Tax Power',
                capacity: 'Capacity (m³)',
                registrationDate: 'Registration Date',
                purchaseDate: 'Purchase Date',
                vehiculeAge: 'Vehicle Age (years)',
            },
        },
        'maintenance-cycles': {
            name: 'Maintenance |||| Maintenances',
            fields: {
                search: 'Search',
                'maintenance.vehicle': 'Maintenance Vehicle',
                parkingNumber: 'Parking N°',
                position: 'Position',
                visits: 'Visits',
                type: 'Type',
                date: 'Maintenance Date',
                vehicleParkingNumber: 'Parking N°',
                vehicleBrand: 'Vehicle Brand',
                vehicleModel: 'Vehicle Model',
                vehicle: 'Vehicle',
                attachments: 'Attachments',
                src: 'Source',
                timeMeter: 'Engine Hours',
                mileage: 'Mileage',
            },
        },
        'vehicle-incident-histories': {
            name: 'Incident |||| Incidents',
            fields: {
                search: 'Search',
                driver: 'Driver',
                date: "Incident date and time",
                'vehicle.parkingNumber': 'Parking number',
                'driver.lastName': 'Driver last name',
                'driver.firstName': 'Driver first name',
                location: 'Incident location',
                costOfWork: 'Work cost',
                responsibility: 'Responsibility',
                meansOfProof: 'Means of proof',
                bodilyDamage: 'Bodily damage',
                materialDamage: 'Material damage',
                circumstance: 'Accident circumstance',
                insuranceDeclarationDate: 'Insurance declaration date',
                compensation: 'Compensation',
                images: 'Images',
                'vehicle.brand': 'Brand',
                'vehicle.model': 'Model',
            },
        },
        'vehicle-control-histories': {
            name: 'Control |||| Controls',
            fields: {
                search: 'Search',
                status: 'Status',
                date: 'Expiry Date',
                'vehicle.parkingNumber': 'Parking Number',
                designation: 'Type of Control',
                attachments: 'Files',
                'vehicle.brand': 'Brand',
                'vehicle.model': 'Model',
                vehicle: 'vehicle',
                'vehicle[@id]': 'vehicle',
            },
        },
        'vehicle-funding-histories': {
            name: 'Funding |||| Fundings',
            fields: {
                search: 'Search',
                date: 'Date',
                'vehicle.parkingNumber': 'Vehicle parking N°',
                refundAmount: 'Refund amount',
                vehicle: 'Vehicle',
                attachments: 'Attachments',
                'vehicle[@id]': 'Vehicle',
            },
        },
        'vehicle-insurance-histories': {
            name: 'Insurance |||| Insurances',
            fields: {
                search: 'Search',
                status: 'Status',
                date: 'Date',
                'vehicle.parkingNumber': 'Vehicle parking N°',
                totalAmountNet: 'Total amount (net)',
                vehicle: 'Vehicle',
                totalPlacesAllowed: 'Total places allowed',
                totalSeats: 'Total seats',
                usage: 'Usage',
                taxHorsePower: 'Tax horsepower',
                der: 'DER',
                damageValue: 'Damage value',
                fireValue: 'Fire value',
                brokenWindowValue: 'Broken window value',
                rcBonus: 'RC bonus',
                damageBonus: 'Damage bonus',
                fireBonus: 'Fire bonus',
                theftBonus: 'Theft bonus',
                brokenWindowBonus: 'Broken window bonus',
                attachments: 'Attachments',
                'vehicle[@id]': 'Vehicle',
            },
        },
        'vehicle-tax-histories': {
            name: 'Tax |||| Taxes',
            fields: {
                search: 'Search',
                status: 'Status',
                date: 'Date',
                'vehicle.parkingNumber': 'Vehicle parking N°',
                type: 'Type',
                amount: 'Amount',
                vehicle: 'Vehicle',
                attachments: 'Attachments',
                'vehicle[@id]': 'Vehicle',
            },
        },
        'vehicle-immobility-histories': {
            name: 'Immobility |||| Immobilities',
            fields: {
                search: 'Search',
                status: 'Status',
                date: 'Date',
                'vehicle.parkingNumber': 'Vehicle parking N°',
                cause: 'Cause',
                vehicle: 'Vehicle',
                immobility: 'Immobility',
                'vehicle[@id]': 'Vehicle',
            },
        },
        'vehicle-intervention-histories': {
            name: 'Intervention |||| Interventions',
            fields: {
                search: 'Search',
                nature: 'Nature of Intervention',
                sheetNumber: 'Intervention Sheet N°',
                'vehicle.parkingNumber': 'Parking N°',
                'vehicle[@id]': 'Vehicle',
                'vehicle.brand': 'Vehicle Brand',
                'vehicle.model': 'Vehicle Model',
                maintenanceType: 'Maintenance Type',
                totalCost: 'Total Cost of Intervention (MAD)',
                stopDate: 'Stop Date',
                returnToServiceDate: 'Return to Service Date',
                claim: 'Claim',
                vehicle: 'Vehicle',
                mileageMeter: 'Mileage Meter (Km)',
                timeMeter: 'Time Meter',
                family: 'Family',
                startDate: 'Start Date of Intervention',
                applicant: 'Applicant',
                defunctSystem: 'Defective System',
                organFamily: 'Organ Family',
                review: 'Review of the Intervention',
                remainingWork: 'Remaining Work',
                type: 'Type of Intervention',
                exitTicket: 'Exit Ticket',
                supplier: 'Supplier',
                purchaseOrder: 'Purchase Order Number',
                quote: 'Quote Number',
                delivery: 'Delivery Note Number',
                deliveryDate: 'Delivery Date',
                cashVoucherNumber: 'Cash Voucher Number',
                articles: 'Articles',
                designation: 'Designation',
                reference: 'Reference',
                unitPrice: 'Unit Price (MAD)',
                quantity: 'Quantity',
                totalPrice: 'Total Price (MAD)',
                state: 'State',
                subfamily: 'Subfamily',
                test: 'Total Cost of Intervention',
            },
        },
        'vehicle-tire-histories': {
            name: 'Tire |||| Tires',
            fields: {
                search: 'Search',
                date: 'Date',
                vehicle: 'Vehicle',
                'vehicle.parkingNumber': 'Parking N°',
                interventionType: 'Intervention Type',
                'vehicle[@id]': 'Vehicle',
                'source=vehicle.brand': 'Brand',
                'vehicle.model': 'Model',
                mileage: 'Mileage',
                serialNumber: 'Serial N°',
                biNumber: 'BI Number',
                dimension: 'Dimension',
                price: 'Price',
                type: 'Type',
                tire: 'Tire',
                images: 'Images',
            },
        },
        'vehicle-maintenance-histories': {
            name: 'Maintenance |||| Maintenances',
            fields: {
                search: 'Search',
                'maintenance.vehicle': 'Maintenance Vehicle',
                parkingNumber: 'Parking N°',
                position: 'Position',
                visits: 'Visits',
                type: 'Type',
                date: 'Maintenance Date',
                vehicleParkingNumber: 'Parking N°',
                vehicleBrand: 'Vehicle Brand',
                vehicleModel: 'Vehicle Model',
                vehicle: 'Vehicle',
                attachments: 'Attachments',
                src: 'Source',
                timeMeter: 'Engine Hours',
                mileage: 'Mileage',
            },
        },
        'stock-articles': {
            name: 'Stock |||| Stocks',
            fields: {
                search: 'Search',
                subfamily: 'Subfamily',
                designation: 'Designation',
                code: 'Code',
                'supplier.code': 'Supplier Code',
                'supplier.name': 'Supplier Name',
                blNumber: 'BL Number',
                date: 'Date',
                brand: 'Brand',
                images: 'Images',
                supplier: 'Supplier',
                type: 'Type',
                ref: 'Reference',
                family: 'Family',
                state: 'State',
                quantity: 'Quantity',
                amount: 'Amount',
            },
        },
        'stock-movements': {
            name: 'Stock |||| Stocks',
            fields: {
                search: 'Search',
                date: 'Date',
                movement: 'Movement',
                blbsNumber: 'BL Number',
                'article.designation': 'Article designation',
                bsNumber: 'BS Number',
                fiNumber: 'FI Number',
                article: 'Article',
            },
        },
        suppliers: {
            name: 'Supplier |||| Suppliers',
            fields: {
                search: 'Search',
                code: 'Code',
                name: 'Name',
                phone: 'Phone',
                email: 'Email',
                address: 'Address',
            },
        },
        employees: {
            name: 'Employee |||| Employees',
            fields: {
                search: 'Search',
                lastName: 'Last Name',
                firstName: 'First Name',
                function: 'Function',
                status: 'Status',
                registrationNumber: 'Registration N°',
                dueDate: 'Due Date',
                attachments: 'Attachments',
                exploitation: 'Exploitation',
            },
        },
        containers: {
            name: 'Container |||| Containers',
            fields: {
                search: 'Search',
                number: 'Number',
                volume: 'Volume',
                supplier: 'Supplier',
                status: 'Status',
                type: 'Type',
                blNumber: 'Bill of Lading N°',
                bsNumber: 'Exit Voucher N°',
                fdNumber: 'Allocation Form N°',
                startDate: 'Start Date',
                allocation: 'Allocation Type',
                location: 'Location',
                latitude: 'Latitude',
                longitude: 'Longitude',
                sector: 'Sector',
                stockEnterDate: 'Stock Entry Date',
                stockExitDate: 'Stock Exit Date',
                price: 'Price',
                images: 'Images',
            },
        },
        'container-reforms': {
            name: 'Container |||| Containers',
            fields: {
                search: 'Search',
                'container.number': 'Container N°',
                'container.type': 'Container Type',
                cause: 'Cause',
                date: 'Date',
                lifecycle: 'Lifecycle',
                images: 'Images',
                'container[@id]': 'Container ID',
                container: 'container',
            },
        },
        'container-maintenances': {
            name: 'Container |||| Containers',
            fields: {
                search: 'Search',
                'container.number': 'Container N°',
                'container.type': 'Container Type',
                date: 'Maintenance Date',
                number: 'Intervention N°',
                type: 'Maintenance Type',
                'container[@id]': 'Container',
                container: 'container',
            },
        },
        'fuel-allocations': {
            name: 'Fuel |||| Fuels',
            fields: {
                search: 'Search',
                date: 'Date',
                fuel: 'Fuel Type',
                blNumber: 'BL Number',
                quantity: 'Quantity (L)',
                totalPrice: 'Total Price (MAD)',
                attachments: 'Attachments',
                src: 'Source',
            },
        },
        'fuel-consumptions': {
            name: 'Fuel |||| Fuels',
            fields: {
                search: 'Search',
                type: 'Fluid Type',
                date: 'Distribution Date',
                number: 'Distribution Ref',
                'vehicle.parkingNumber': 'Vehicle Parking N°',
                'vehicle.gear': 'Vehicle Gear',
                'driver.lastName': 'Driver Last Name',
                'driver.firstName': 'Driver First Name',
                assignment: 'Assignment',
                quantity: 'Quantity (L)',
                diffMileage: 'Mileage',
                quantityPerDiffMileage: 'Quantity/Mileage (%)',
                diffTimeMeter: 'Time Meter',
                quantityPerDiffTimeMeter: 'Quantity/Time Meter',
                src: 'Attachments',
            },
        },
        cards: {
            name: 'Card |||| Cards',
            fields: {
                search: 'Search',
                date: 'Date',
                code: 'Code',
                supplier: 'Supplier',
                amount: 'Amount (MAD)',
                attachments: 'Attachments',
                src: 'Source',
            },
        },
    },
    titles: {
        addvehicle: 'Add Vehicle',
        addincident: 'Add Incident',
        addcontrol: 'Add Control',
        addfunding: 'Add Funding',
        addinsurance: 'Add Insurance',
        addtax: 'Add Tax/Sticker',
        addimmobilization: 'Add Immobilization',
        addintervention: 'Add Intervention',
        addtire: 'Add Tire',
        addmaintenance: 'Add Maintenance',
        addstockmovement: 'Add Stock movement',
        addsupplier: 'Add Supplier',
        addpersonnal: 'Add Staff',
        addpresence: "addpresence",
        addovertime:"Add Overtime",
        addvacation:"Add Vacation",
        addworkaccident:"Add Work Accident",
        addtrafficcodeviolation:"Add Traffic Code Violations",
        addmedicalvisit:"Add Medical Visit",
        addtraining:"Add Training",
        addbin: 'Add Bin',
        personnal: 'Staff',
        caracteristiques: 'Technical Specifications',
        situationAdministrative: 'Administrative Status',
        equipementEmbarque: 'On-board Equipment',
        financement: 'Financing',
        assurance: 'Insurance',
        maintenanceVehicule: 'Vehicle Maintenance',
        tauxDisponibilite: 'Availability Rate',
        typeInterventions: 'Type of Interventions',
        immobilisationsJour: 'Daily Immobilizations',
        alertesCarburant: 'Fuel Alerts',
        alertesControles: 'Inspection Alerts',
        alertesAssurances: 'Insurance Alerts',
        alertesTaxesVignettes: 'Tax/Sticker Alerts',
        consommationGasoil: 'Diesel Consumption',
        consommationEssence: 'Gasoline Consumption',
        demandedintervention: 'Intervention Request',
        exécutiondintervention: 'Intervention Execution',
        restitution: 'Restitution',
        articles: 'Articles',
        Pneumatiques: 'Tires',
        Calendriermaintenance: 'Maintenance schedule',
        Historiquemaintenance: 'Maintenance history',
        Téléchargercanevas: 'Download canvas',
        Mouvementstock: 'Stock movements',
        suppliers: 'Suppliers',
        bacs: 'Bins',
        reforms: 'Reforms',
        maintenances: 'Maintenances',
        typereforms: 'Type of reforms',
        showbin: 'Show a bin',
        modifybin: 'Modify a bin',
        ContainerReform: 'Container Reform',
        AddReform: 'Add a Reform',
        EditReform: 'Edit the Reform',
        Réapprovisionnements: 'Restocks',
        Réapprovisionnement: 'Restock',
        Addendowment: 'Add an endowment',
        Adddistribution: 'Add a distribution',
        presence: "Presence",
        overtime: "Overtime",
        vacation: "Vacation",
        workAccident: "Work Accident",
        medicalVisit: "Medical Visit",
        demandevacation: "Vacation request",
        situationcongépayées: "Situation congé payées",
        Authorisationhr: "Authorization from the hierarchical superior",
        directionrh: "HR Department",
        informationspersonnelles: "Personal Information",
        informationfonctionnelles: "Functional Informations",
        rémunération: "Remuneration"

    },
    listItems: {
        tauxDisponibiliteMoisDernier: 'Availability Rate Last Month',
        tauxDisponibiliteMoisCourant: 'Current Month Availability Rate',
        tauxDisponibiliteJournalier: 'Daily Availability Rate',
        enAttente: 'Pending',
        enUrgence: 'Urgent',
        enCours: 'In Progress',
        totalApprovisionnementGasoil: 'Total Diesel Supply (Liters)',
        totalApprovisionnementEssence: 'Total Gasoline Supply (Liters)',
        alerteConsommation: 'Consumption Alert (>100 Liters)',
        aVenir: 'Upcoming (< 15 days)',
        enRetard: 'Delayed',
        Entrée: 'Entrance',
        Sortie: 'Exit',
        
    },
    labels: {
        effectue: "Completed",
        'non-effectue': "Not completed", 
        oui: 'Yes',
        non: 'No',
        encours: 'In progress',
        PVpolice: 'Police Report',
        PVgendarme: 'Gendarme Report',
        Constatàlamiable: 'Amicable Report',
        Déclarationsécritesdestemoins: 'Written Witness Statements',
        Changementdefil: 'Lane Change',
        Nonrespectdelapriorité: 'Failure to Yield',
        Pertedecontrôle: 'Loss of Control',
        Excèsdevitesse: 'Speeding',
        Arrêtobligatoirenonrespecté: 'Failure to Stop',
        Défautmécanique: 'Mechanical fault',
        CU: 'Payload (Kg)',
        dashboard: 'Dashboard',
        fleet: 'Fleet',
        vehicles: 'Vehicles',
        vehiclesList: "Vehicles list",
        accidents: 'Accidents',
        controls: 'Controls',
        funding: 'Fundings',
        insurance: 'Insurances',
        taxes: 'Taxes/Stickers',
        immobilizations: 'Immobilizations',
        fluids: 'Fluids',
        replenishment: 'Replenishment',
        consumptions: 'Consumptions',
        fuelCards: 'Fuel Cards',
        fuelCard: 'Fuel Card',
        interventions: 'Interventions',
        tires: 'Tires',
        tire: 'Tire',
        maintenance: 'Maintenance',
        stock: 'Stock',
        articles: 'Articles',
        movements: 'Movements',
        suppliers: 'Suppliers',
        personnel: 'Employees',
        bins: 'Bins',
        binsList: 'Bins List',
        binsReform: 'Bins Reform',
        binsMaintenance: 'Bins Maintenance',
        new: 'New',
        corrective: 'Corrective',
        preventive: 'Preventive',
        incident: 'Incident',
        dépannage: 'Troubleshooting',
        costinterventions: 'Cost of interventions (MAD)',
        percentageinterventions: 'Percentage of interventions (%)',
        availability30days: 'Availability over the last 30 days (in %)',
        consumptionliters: 'Consumption in liters',
        consumptioncurrency: 'Consumption in currency',
        typereforms: 'Type of reforms',
        casse: 'Broken',
        vol: 'Flight',
        incendie: 'Fire',
        autre: 'Other',
        effectue: 'Completed',
        avenir: 'Upcoming',
        enretard: 'Overdue',
        noneffectue: 'Not completed',
        visitetechnique: 'Technical Inspection',
        visitetachygraphe: 'Tachograph Inspection',
        extincteurs: 'Fire Extinguishers',
        Taxe: 'Tax',
        Vignette: 'Sticker ',
        maintenancecorrective: 'Corrective maintenance',
        maintenancepréventive: 'Preventive maintenance',
        Boitedevitesse: 'Gearbox',
        Cabine: 'Cabin',
        Circuitdegazoil: 'Diesel circuit',
        Circuitderefroidissement: 'Cooling system',
        Circuitélectrique: 'Electrical circuit',
        Circuitpneumatique: 'Pneumatic circuit',
        CircuitAdBlue: 'AdBlue system',
        Circuitdeau: 'Coolant system',
        Capteurs: 'Sensors',
        Moteurcannexe: 'Engine & auxiliaries',
        Embrayage: 'Clutch',
        EmbrayageTransmission: 'Clutch/Transmission',
        Mécanismedefreinage: 'Braking mechanism',
        Systèmedefreinage: 'Braking system',
        Vannelixiviat: 'Leachate valve',
        Moteur: 'Engine',
        Roue: 'Wheel',
        Pneumatique: 'Pneumatic',
        Suspension: 'Suspension',
        Direction: 'Steering',
        Lamederessort: 'Leaf spring',
        Transmission: 'Transmission',
        Accessoireshydraulique: 'Hydraulic accessories',
        Accessoirespneumatique: 'Pneumatic accessories',
        Balais: 'Brushes',
        Chaudronnerie: 'Boiler making',
        Distributeur: 'Distributor',
        Flexible: 'Flexible',
        FlexibleRaccord: 'Flexible/Connector',
        Huilehydraulique: 'Hydraulic oil',
        Peinture: 'Paint',
        Pompe: 'Pump',
        Prisedeforce: 'Power take-off',
        Vérin: 'Actuator',
        Vérins: 'Actuators',
        Réparation: 'Repair',
        Changement: 'Replacement',
        Original: 'Original',
        Rechapé: 'Retreaded',
        Chassis: 'Chassis',
        Equipement: 'Equipment',
        ChassisEquipement: 'Chassis/Equipment',
        Dressage: 'Dressage',
        Réparation: 'Repair',
        Remplacement: 'Replacement',
        Contrôle: 'Inspection',
        Réglage: 'Adjustment',
        Serrage: 'Tightening',
        Appoint: 'Topping up',
        Efficace: 'Effective',
        Inefficace: 'Ineffective',
        Interne: 'Internal',
        Externe: 'External',
        Neuf: 'New',
        Bonneoccasion: 'Good used',
        Réparé: 'Repaired',
        Confectionné: 'Manufactured',
        Conducteur: 'Driver',
        Intervenant: 'Speaker',
        Contractuel: 'Contractual',
        NonContractuel: 'No contractual',
        Interim: 'Interim',
        Bacmétallique: 'Metal bin',
        BacPlastique: 'Plastic bin',
        caissonampliROL: 'ROL amplifier box',
        caissonmultibenne: 'Multi-bin container',
        BAV: 'BAV',
        Corbeilledeville: 'City bin',
        Corbeilledeplage: 'Beach bin',
        Publique: 'Public',
        Privée: 'Private',
        containernumber: 'Container N°',
        containertype: 'Container Type',
        Wheelchange: 'Wheel change',
        Lidchange: 'Lid change',
        Accessories: 'Accessories',
        Essence: 'Gasoline',
        Gasoil: 'Diesel',
        Electrique: 'Electric',
        AdBlue: 'AdBlue',
        Huilemoteur: 'Engine oil',
        CollecteOM: 'Household waste collection',
        CollecteDVetgrâvat: 'Bulky waste and rubble collection',
        Nettoiementmécanisé: 'Mechanized cleaning',
        Utilitaire: 'Utility',
        Citerne: 'Tank',
        Carte: 'Card',
        BOM: 'BOM',
        'BOM+Grue': 'BOM+Crane',
        'Benne Satellite': 'Satellite Bucket',
        BTP: 'Public Works',
        'BTP avec grappin': 'Public Works with Grapple',
        AMPL: 'AMPL',
        'Mini AMPL': 'Mini AMPL',
        'Balayeuse CEKSAN': 'CEKSAN Sweeper',
        'Citerne laveuse': 'Washing Tanker',
        Balayeuse: 'Sweeper',
        'Laveuse automatique': 'Automatic Washer',
        'Laveuse avec grue': 'Washer with Crane',
        'Chargeur sur roue': 'Wheel Loader',
        JCB: 'JCB',
        Tracteur: 'Tractor',
        Aspirateur: 'Vacuum Cleaner',
        'Tricycle électrique': 'Electric Tricycle',
        Motocycle: 'Motorcycle',
        'Véhicule de service': 'Service Vehicle',
        'Véhicule utilitaire': 'Utility Vehicle',
        'Atelier mobile': 'Mobile Workshop',
        'Mini-Laveuse': 'Mini-Washer',
        'AMPL+GRUE': 'AMPL+Crane',
        Benne: 'Dump Truck',
        Broyeur: 'Shredder',
        Chargeuse: 'Loader',
        Compacteur: 'Compactor',
        Camion: 'Truck',
        Cribleuse: 'Screener',
        Liaison: 'Linkage',
        Mobylette: 'Moped',
        Multibenne: 'Multibucket',
        Tractopelle: 'Backhoe Loader',
        Tricycle: 'Tricycle',
        'Bac plastique': 'Plastic Bin',
        BAV: 'BAV',
        Plateforme: 'Platform',
        Corbeille: 'Basket',
        Laveuse: 'Washer',
        'Mini camion BOM de 7,5T': 'Mini BOM Truck 7.5T',
        'Camion BOM de 26T': 'BOM Truck 26T',
        'Camion BOM de 19T': 'BOM Truck 19T',
        'Camion BOM de 12T': 'BOM Truck 12T',
        'Camion Ampliroll 19T grue & grappin': 'Ampliroll Truck 19T with Crane & Grapple',
        'Mini Camion 7,5T grue & grappin': 'Mini Truck 7.5T with Crane & Grapple',
        'Chargement arr': 'Loading arr',
        'Deux roues': 'Two-wheeler',
        'Quatre Roues': 'Four-wheeler',
        'Plateau': 'Platform',
        'Mécanique': 'Mechanical',
        'Aspiratrice': 'Vacuum cleaner',
        'Sur pneus': 'On tires',
        'Bras coulissante': 'Sliding arm',
        'Citerne': 'Tanker',
        'Voiries': 'Roads',
        'Basculante': 'Tipping',
        'sur berce AMPL': 'on AMPL cradle',
        'Champignon': 'Mushroom',
        'Double Crochet': 'Double hook',
        'Hydraulique': 'Hydraulic',
        'VL': 'VL',
        Actif: 'Active',
        Inactif: 'Inactive',
        Kilométrique: 'Kilometer',
        Horaire: 'Hourly',
        'month1-24': "January 24",
        'month2-24': "February 24",
        'month3-24': "March 24",
        'month4-24': "April 24",
        'month5-24': "May 24",
        'month6-24': "June 24",
        'month7-24': "July 24",
        'month8-24': "August 24",
        'month9-24': "September 24",
        'month10-24': "October 24",
        'month11-24': "November 24",
        'month12-24': "December 24",
        month: 'Month',
        employees: "Employees",
        presences: "presences",
        overtimes: "Overtimes",
        vacations: "Vacations",
        workAccidents: "Work Accidents",
        trafficCodeViolations: "Traffic Code Violation",
        medicalVisits: "Medical Visits",
        trainings: "trainings",
        approved: "Approved",
        denied: "Denied"
    },
};
