import polyglotI18nProvider from 'ra-i18n-polyglot';
import { fr } from './i18n/fr';
import { en } from './i18n/en';

const translations = { fr, en };

export const i18nProvider = polyglotI18nProvider(
    locale => translations[locale],
    'fr', // default locale
    [
        { locale: 'fr', name: 'Français' },
        { locale: 'en', name: 'English' },
    ]
);
