import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  Labeled,
  List,
  NumberInput,
  RaRecord,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TimeInput,
  TopToolbar,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';
import { Typography } from '@mui/material';


const exporter = (employees: any) => {
  const employeesForExport = employees.map((employee: any) => {
    console.log("employee", employee);
    if (employee.dueDate) {
      employee.dueDate = employee.dueDate.slice(0, 10);
    };
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      originId,
      site,
      updatedAt,
      totalPlacesAllowed,
      totalSeats,
      usage,
      taxHorsePower,
      damageValue,
      fireValue,
      brokenWindowValue,
      rcBonus,
      damageBonus,
      fireBonus,
      theftBonus,
      brokenWindowBonus,
      der,
      createdAt,
      ...employeeForExport
    } = employee;
    return employeeForExport;
  });
  jsonexport(
    employeesForExport,
    {
      headers: [
        "firstName",
        "lastName",
        "function",
        "status",
        "registrationNumber",
        "dueDate",
      ],
      rename: [
        "Prenom",
        "Nom",
        "Fonction",
        "Statut",
        "Matricule",
        "Date echeance",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Personnel"); // download as 'posts.csv` file
    }
  );
};
const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const EmployeeListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const EmployeeFilters = [
  <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const EmployeeList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<EmployeeListActions />}
      filters={EmployeeFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">

          <TextField>Informations personnelles</TextField>
          <TextField source={"registrationNumber"} /*label="Matricule"*/ />
          <TextField source={"lastName"} /*label="Nom"*/ />
          <TextField source={"firstName"} /*label="Prénom"*/ />
          <SelectField
            source={"status"}
            //label="Statut"
            choices={[
              { id: "Contractuel", name: translate('labels.Contractuel') },
              { id: "Interim", name: translate('labels.Interim') },
            ]}
          />
            <SelectField
                source={"function"}
                // label="Function"
                choices={[
                  { id: "DirecteurExploitation", name: translate('labels.DirecteurExploitation') },
                  { id: "ResponsableExploitation", name: translate('labels.ResponsableExploitation') },
                  { id: "AssistanteDeDirection", name: translate('labels.AssistanteDeDirection') },
                  { id: "ResponsableRH", name: translate('labels.ResponsableRH') },
                  { id: "ResponsableHSQE", name: translate('labels.ResponsableHSQE') },
                  { id: "RelaiRH", name: translate('labels.RelaiRH') },
                  { id: "Surveillant", name: translate('labels.Surveillant') },
                  { id: "ChefEquipe", name: translate('labels.ChefEquipe') },
                  { id: "ChauffeurFormateur", name: translate('labels.ChauffeurFormateur') },
                  { id: "ChauffeurPL", name: translate('labels.ChauffeurPL') },
                  { id: "ChauffeurVL", name: translate('labels.ChauffeurVL') },
                  { id: "Ripeur", name: translate('labels.Ripeur') },
                  { id: "Balayeuse", name: translate('labels.Balayeuse') },
                  { id: "Laveur", name: translate('labels.Laveur') },
                  { id: "AgentAdministratif", name: translate('labels.AgentAdministratif') },
                  { id: "ChefParc", name: translate('labels.ChefParc') },
                  { id: "ChefAtelier", name: translate('labels.ChefAtelier') },
                  { id: "Mecanicien", name: translate('labels.Mecanicien') },
                  { id: "AideMecanicien", name: translate('labels.AideMecanicien') },
                  { id: "Tolier", name: translate('labels.Tolier') },
                  { id: "Chaudronnier", name: translate('labels.Chaudronnier') },
                  { id: "Hydraulicien", name: translate('labels.Hydraulicien') },
                  { id: "Pneumaticien", name: translate('labels.Pneumaticien') },
                  { id: "Magasinier", name: translate('labels.Magasinier') },
                  { id: "AideMagasinier", name: translate('labels.AideMagasinier') },
                  { id: "Pompiste", name: translate('labels.Pompiste') },
                  { id: "AgentPontBascule", name: translate('labels.AgentPontBascule') },
                  { id: "Gardien", name: translate('labels.Gardien') },
                ]}
              />          

        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const EmployeeCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/employees/");
    refresh();
  };
  return (
    <Create
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center">
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.informationspersonnelles')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"15px"}
          
          >
          <TextInput source={"registrationNumber"} /*label="Matricule"*/ />
          <NumberInput source={"socialSecurityNumber"} /*label="Numéro CNSS"*/ />
          <DateInput source={"hiringDate"} /*label="Date échéance visite médicale"*/ />
          <TextInput source={"lastName"} /*label="Nom"*/ />
          <TextInput source={"firstName"} /*label="Prénom"*/ />
          <DateInput source={"birthDate"} /*label="birthDate"*/ />
          <TextInput source={"nationalId"} /*label="nationalId"*/ />
          <SelectInput
            source={"familySituation "}
            //label="familySituation "
            choices={[
              { id: "Célibataire", name: translate('labels.Célibataire') },
              { id: "marié", name: translate('labels.marié') },
              { id: "veuf", name: translate('labels.veuf') }
            ]}
          />
          <NumberInput source={"childrenNumber"} />
          <TextInput source={"phoneNumber "} /*label="Tel"*/ />
          <TextInput source={"address"} /*label="Address"*/ />
          <SelectInput
            source={"status"}
            //label="Statut"
            choices={[
              { id: "Contractuel", name: translate('labels.Contractuel') },
              { id: "Interim", name: translate('labels.Interim') },
            ]}
          />

  
        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.informationfonctionnelles')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"15px"}
          
          >
            <TimeInput source={"startTime "} />
            <TimeInput source={"endTime "} />
            <SelectInput
              source={"service "}
              //label="Service "
              choices={[
                { id: "Exploitation", name: translate('labels.Exploitation') },
                { id: "Atelier ", name: translate('labels.Atelier') },
                { id: "Administration", name: translate('labels.Administration') },

              ]}
            />
            <SelectInput
                source={"function"}
                // label="Function"
                choices={[
                  { id: "DirecteurExploitation", name: translate('labels.DirecteurExploitation') },
                  { id: "ResponsableExploitation", name: translate('labels.ResponsableExploitation') },
                  { id: "AssistanteDeDirection", name: translate('labels.AssistanteDeDirection') },
                  { id: "ResponsableRH", name: translate('labels.ResponsableRH') },
                  { id: "ResponsableHSQE", name: translate('labels.ResponsableHSQE') },
                  { id: "RelaiRH", name: translate('labels.RelaiRH') },
                  { id: "Surveillant", name: translate('labels.Surveillant') },
                  { id: "ChefEquipe", name: translate('labels.ChefEquipe') },
                  { id: "ChauffeurFormateur", name: translate('labels.ChauffeurFormateur') },
                  { id: "ChauffeurPL", name: translate('labels.ChauffeurPL') },
                  { id: "ChauffeurVL", name: translate('labels.ChauffeurVL') },
                  { id: "Ripeur", name: translate('labels.Ripeur') },
                  { id: "Balayeuse", name: translate('labels.Balayeuse') },
                  { id: "Laveur", name: translate('labels.Laveur') },
                  { id: "AgentAdministratif", name: translate('labels.AgentAdministratif') },
                  { id: "ChefParc", name: translate('labels.ChefParc') },
                  { id: "ChefAtelier", name: translate('labels.ChefAtelier') },
                  { id: "Mecanicien", name: translate('labels.Mecanicien') },
                  { id: "AideMecanicien", name: translate('labels.AideMecanicien') },
                  { id: "Tolier", name: translate('labels.Tolier') },
                  { id: "Chaudronnier", name: translate('labels.Chaudronnier') },
                  { id: "Hydraulicien", name: translate('labels.Hydraulicien') },
                  { id: "Pneumaticien", name: translate('labels.Pneumaticien') },
                  { id: "Magasinier", name: translate('labels.Magasinier') },
                  { id: "AideMagasinier", name: translate('labels.AideMagasinier') },
                  { id: "Pompiste", name: translate('labels.Pompiste') },
                  { id: "AgentPontBascule", name: translate('labels.AgentPontBascule') },
                  { id: "Gardien", name: translate('labels.Gardien') },
                ]}
              />
            <SelectInput
                  source={"contractType "}
                  // label="Status"
                  choices={[
                    { id: "CDD", name: translate('labels.CDD') },
                    { id: "CDI", name: translate('labels.CDI') },
                    { id: "Saisonnier", name: translate('labels.Saisonnier') },
                    { id: "Communal", name: translate('labels.Communal') },
                  ]}
              />
            <SelectInput
                  source={"serviceProvided "}
                  choices={[
                      { id: 'collecte_om', name: 'Collecte OM' },
                      { id: 'collecte_dv', name: 'Collecte DV' },
                      { id: 'collecte_gravats', name: 'Collecte Gravats' },
                      { id: 'lavage_des_conteneurs', name: 'Lavage des conteneurs' },
                      { id: 'balayage_manuel', name: 'Balayage manuel' },
                      { id: 'balayage_mecanique', name: 'Balayage mécanique' },
                      { id: 'lavage_mecanique', name: 'Lavage mécanique' },
                      { id: 'nettoyage_des_plages', name: 'Nettoyage des plages' },
                      { id: 'maintenance', name: 'Maintenance' },
                      { id: 'administration', name: 'Administration' }
                  ]}
             
              />
              <SelectInput
                  source={"dayOff"}
                  choices={[
                    { id: 'monday', name: 'Monday' },
                    { id: 'tuesday', name: 'Tuesday' },
                    { id: 'wednesday', name: 'Wednesday' },
                    { id: 'thursday', name: 'Thursday' },
                    { id: 'friday', name: 'Friday' },
                    { id: 'saturday', name: 'Saturday' },
                    { id: 'sunday', name: 'Sunday' }
                  ]}
             
              />
              <SelectInput
                  source={"replacement"}
                  choices={[
                      
                      
                  ]}
             
              />
              <SelectInput
                  source={"sector"}
                  choices={[
                      
                      
                  ]}
             
              />
              <SelectInput
                  source={"supervisor"}
                  choices={[
                      
                      
                  ]}
             
              />



            </Box>
            <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
              {translate('titles.rémunération')}
            </Typography>
              <Box
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  lg: "repeat(4, 1fr)",
                  xl: "repeat(5, 1fr)",
                }}
                gap={2}
                width="100%"
                mb={"15px"}
                
              >
                <NumberInput source={"baseSalary"} /*label="Salaire de Base"*/ />
                <NumberInput source={"seniorityBonus"} /*label="Prime d’enceinté"*/ />
                <NumberInput source={"attendanceBonus"} /*label="Indemnité de présentation"*/ />
                <NumberInput source={"transportBonus"} /*label="Indemnité de transport"*/ />
                <NumberInput source={"parenthoodBonus"} /*label="Prime de Lait"*/ />
                <NumberInput source={"annualBonus"} /*label="Prime annue"*/ />
                <NumberInput source={"schoolBonus"} /*label="Prime Scolarité"*/ />
                <NumberInput source={"achouraBonus"} /*label="Prime Achoura"*/ />
                <NumberInput source={"adhaBonus"} /*label="Prime Aïd el Kébir"*/ />
                <NumberInput source={"extraBonus"} /*label="Prime de Salissure"*/ />


          </Box>

        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

interface EmployeeTitleProps {
  record: RaRecord;
}

const EmployeeTitle: React.FC<EmployeeTitleProps> = ({ record }) => {
  return <span>Contrôle {record ? `"${record.title}"` : ""}</span>;
};

export const EmployeeEdit: React.FC<EmployeeTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/employees/");
    refresh();
  };
  return (
    <Edit
      title={<EmployeeTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm>
      <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.informationspersonnelles')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"15px"}
          
          >
          <TextInput source={"registrationNumber"} /*label="Matricule"*/ />
          <NumberInput source={"socialSecurityNumber"} /*label="Numéro CNSS"*/ />
          <DateInput source={"hiringDate"} /*label="Date échéance visite médicale"*/ />
          <TextInput source={"lastName"} /*label="Nom"*/ />
          <TextInput source={"firstName"} /*label="Prénom"*/ />
          <DateInput source={"birthDate"} /*label="birthDate"*/ />
          <TextInput source={"nationalId"} /*label="nationalId"*/ />
          <SelectInput
            source={"familySituation "}
            //label="familySituation "
            choices={[
              { id: "Célibataire", name: translate('labels.Célibataire') },
              { id: "marié", name: translate('labels.marié') },
              { id: "veuf", name: translate('labels.veuf') }
            ]}
          />
          <NumberInput source={"childrenNumber"} />
          <TextInput source={"phoneNumber "} /*label="Tel"*/ />
          <TextInput source={"address"} /*label="Address"*/ />
          <SelectInput
            source={"status"}
            //label="Statut"
            choices={[
              { id: "Contractuel", name: translate('labels.Contractuel') },
              { id: "Interim", name: translate('labels.Interim') },
            ]}
          />

  
        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.informationfonctionnelles')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"15px"}
          
          >
            <TimeInput source={"startTime "} />
            <TimeInput source={"endTime "} />
            <SelectInput
              source={"service "}
              //label="Service "
              choices={[
                { id: "Exploitation", name: translate('labels.Exploitation') },
                { id: "Atelier ", name: translate('labels.Atelier') },
                { id: "Administration", name: translate('labels.Administration') },

              ]}
            />
            <SelectInput
                source={"function"}
                // label="Function"
                choices={[
                  { id: "DirecteurExploitation", name: translate('labels.DirecteurExploitation') },
                  { id: "ResponsableExploitation", name: translate('labels.ResponsableExploitation') },
                  { id: "AssistanteDeDirection", name: translate('labels.AssistanteDeDirection') },
                  { id: "ResponsableRH", name: translate('labels.ResponsableRH') },
                  { id: "ResponsableHSQE", name: translate('labels.ResponsableHSQE') },
                  { id: "RelaiRH", name: translate('labels.RelaiRH') },
                  { id: "Surveillant", name: translate('labels.Surveillant') },
                  { id: "ChefEquipe", name: translate('labels.ChefEquipe') },
                  { id: "ChauffeurFormateur", name: translate('labels.ChauffeurFormateur') },
                  { id: "ChauffeurPL", name: translate('labels.ChauffeurPL') },
                  { id: "ChauffeurVL", name: translate('labels.ChauffeurVL') },
                  { id: "Ripeur", name: translate('labels.Ripeur') },
                  { id: "Balayeuse", name: translate('labels.Balayeuse') },
                  { id: "Laveur", name: translate('labels.Laveur') },
                  { id: "AgentAdministratif", name: translate('labels.AgentAdministratif') },
                  { id: "ChefParc", name: translate('labels.ChefParc') },
                  { id: "ChefAtelier", name: translate('labels.ChefAtelier') },
                  { id: "Mecanicien", name: translate('labels.Mecanicien') },
                  { id: "AideMecanicien", name: translate('labels.AideMecanicien') },
                  { id: "Tolier", name: translate('labels.Tolier') },
                  { id: "Chaudronnier", name: translate('labels.Chaudronnier') },
                  { id: "Hydraulicien", name: translate('labels.Hydraulicien') },
                  { id: "Pneumaticien", name: translate('labels.Pneumaticien') },
                  { id: "Magasinier", name: translate('labels.Magasinier') },
                  { id: "AideMagasinier", name: translate('labels.AideMagasinier') },
                  { id: "Pompiste", name: translate('labels.Pompiste') },
                  { id: "AgentPontBascule", name: translate('labels.AgentPontBascule') },
                  { id: "Gardien", name: translate('labels.Gardien') },
                ]}
              />
            <SelectInput
                  source={"contractType "}
                  // label="Status"
                  choices={[
                    { id: "CDD", name: translate('labels.CDD') },
                    { id: "CDI", name: translate('labels.CDI') },
                    { id: "Saisonnier", name: translate('labels.Saisonnier') },
                    { id: "Communal", name: translate('labels.Communal') },
                  ]}
              />
            <SelectInput
                  source={"serviceProvided "}
                  choices={[
                      { id: 'collecte_om', name: 'Collecte OM' },
                      { id: 'collecte_dv', name: 'Collecte DV' },
                      { id: 'collecte_gravats', name: 'Collecte Gravats' },
                      { id: 'lavage_des_conteneurs', name: 'Lavage des conteneurs' },
                      { id: 'balayage_manuel', name: 'Balayage manuel' },
                      { id: 'balayage_mecanique', name: 'Balayage mécanique' },
                      { id: 'lavage_mecanique', name: 'Lavage mécanique' },
                      { id: 'nettoyage_des_plages', name: 'Nettoyage des plages' },
                      { id: 'maintenance', name: 'Maintenance' },
                      { id: 'administration', name: 'Administration' }
                  ]}
             
              />
              <SelectInput
                  source={"dayOff"}
                  choices={[
                    { id: 'monday', name: 'Monday' },
                    { id: 'tuesday', name: 'Tuesday' },
                    { id: 'wednesday', name: 'Wednesday' },
                    { id: 'thursday', name: 'Thursday' },
                    { id: 'friday', name: 'Friday' },
                    { id: 'saturday', name: 'Saturday' },
                    { id: 'sunday', name: 'Sunday' }
                  ]}
             
              />
              <SelectInput
                  source={"replacement"}
                  choices={[
                      
                      
                  ]}
             
              />
              <SelectInput
                  source={"sector"}
                  choices={[
                      
                      
                  ]}
             
              />
              <SelectInput
                  source={"supervisor"}
                  choices={[
                      
                      
                  ]}
             
              />



            </Box>
            <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
              {translate('titles.rémunération')}
            </Typography>
              <Box
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  lg: "repeat(4, 1fr)",
                  xl: "repeat(5, 1fr)",
                }}
                gap={2}
                width="100%"
                mb={"50px"}
                
              >
                <NumberInput source={"baseSalary"} /*label="Salaire de Base"*/ />
                <NumberInput source={"seniorityBonus"} /*label="Prime d’enceinté"*/ />
                <NumberInput source={"attendanceBonus"} /*label="Indemnité de présentation"*/ />
                <NumberInput source={"transportBonus"} /*label="Indemnité de transport"*/ />
                <NumberInput source={"parenthoodBonus"} /*label="Prime de Lait"*/ />
                <NumberInput source={"annualBonus"} /*label="Prime annue"*/ />
                <NumberInput source={"schoolBonus"} /*label="Prime Scolarité"*/ />
                <NumberInput source={"achouraBonus"} /*label="Prime Achoura"*/ />
                <NumberInput source={"adhaBonus"} /*label="Prime Aïd el Kébir"*/ />
                <NumberInput source={"extraBonus"} /*label="Prime de Salissure"*/ />


          </Box>

        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export const EmployeeShow: React.FC<EmployeeTitleProps> = (props) => {

  const translate = useTranslate();

  return(
    <Show
      actions={<EditAction />}
      title={<EmployeeTitle record={props.record} />}
      sx={{ fontSize: "20px" }}
      {...props}>
      <SimpleShowLayout>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
            {translate('titles.informationspersonnelles')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"30px"}

          >        
          <Labeled>
            <TextField source={"registrationNumber"} /*label="Matricule"*/ fontWeight="bold" />
          </Labeled>
          <Labeled >
            <TextField source={"socialSecurityNumber"} /*label="Numéro CNSS"*/ fontWeight="bold" />
          </Labeled>
          <Labeled >
            <DateField source={"hiringDate"} /*label="Date d’embauche"*/ fontWeight="bold" />
          </Labeled>
          <Labeled >
            <TextField source={"lastName"} /*label="Nom"*/ fontWeight="bold" />
          </Labeled>
          <Labeled >
            <TextField source={"firstName"} /*label="Prénom"*/ fontWeight="bold" />
          </Labeled>
          <Labeled >
            <DateField source={"hiringDate"} /*label="Date de naissance"*/ fontWeight="bold" />
          </Labeled>
          <Labeled >
            <TextField source={"nationalId "} /*label="CIN"*/ fontWeight="bold" />
          </Labeled>
          <Labeled >
            <TextField source={"familySituation"} /*label="Situation familiale"*/ fontWeight="bold" />
          </Labeled>
          <Labeled >
            <TextField source={"childrenNumber"} /*label="Nombre d’enfant"*/ fontWeight="bold" />
          </Labeled>
          <Labeled >
            <TextField source={"phoneNumber"} /*label="Tel"*/ fontWeight="bold" />
          </Labeled>
          <Labeled >
            <TextField source={"address"} /*label="Tel"*/ fontWeight="Adresse " />
          </Labeled>
          <Labeled >
            <TextField source={"status"} /*label="Statut"*/ fontWeight="bold" />
          </Labeled>


        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
            {translate('titles.informationfonctionnelles')}
          </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"30px"}
          
          >        
          <Labeled>
            <DateField source={"startTime"} /*label="Heure début"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <DateField source={"endTime"} /*label="Heure fin"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"service"} /*label="Service "*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"function"} /*label="Fonction"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"contractType"} /*label="Type de Contrat"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"serviceProvided "} /*label="Prestation"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"dayOff"} /*label="Jour de repos"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"replacement"} /*label="Remplaçant"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"sector"} /*label="Secteur"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"supervisor"} /*label="Responsable hiérarchique"*/ fontWeight="bold" />
          </Labeled>


          </Box>
          <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
            {translate('titles.rémunération')}
          </Typography>
          <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"30px"}
          >
          <Labeled>
            <TextField source={"baseSalary"} /*label="Salaire de Base"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"seniorityBonus"} /*label="Prime d’enceinté"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"attendanceBonus+"} /*label="Indemnité de présentation"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"transportBonus"} /*label="Indemnité de transport"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"parenthoodBonus"} /*label="Prime de Lait"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"annualBonus"} /*label="Prime annuel"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"schoolBonus"} /*label="Prime Scolarité"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"achouraBonus"} /*label="Prime Achoura"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"adhaBonus"} /*label="Prime Aïd el Kébir"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"extraBonus"} /*label="Prime de Salissure"*/ fontWeight="bold" />
          </Labeled>


          </Box>

        <Labeled label="Fichiers" marginTop="16px">
          <FileField
            source={"attachments"}
            src="contentUrl"
            title="title"
            target="_blank"
          />
        </Labeled>
      </SimpleShowLayout>
    </Show>
)};
