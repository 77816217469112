import { Box, Chip, Stack } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  ArrayField,
  AutocompleteInput,
  ChipField,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  FunctionField,
  ImageField,
  ImageInput,
  Labeled,
  List,
  RaRecord,
  ReferenceInput,
  SelectArrayInput,
  SelectColumnsButton,
  SelectField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const TagsField = () => {
  const record = useRecordContext();
  return (
    <ul>
      {record.tags.map((item: any) => (
        <li key={item.name}>{item.name}</li>
      ))}
    </ul>
  );
};
const containerInfo = (choice: { number: string; type: string }) =>
  `${choice.number} • ${choice.type}`;

const exporter = (ContainerMaintenances: any) => {
  const ContainerMaintenancesForExport = ContainerMaintenances.map(
    (ContainerMaintenance: any) => {
      console.log("ContainerMaintenance", ContainerMaintenance);
      const {
        "@id": arobasId,
        "@type": arobasType,
        attachments,
        id,
        originId,
        operator,
        site,
        updatedAt,
        ...ContainerMaintenanceForExport
      } = ContainerMaintenance; // omit backlinks and author
      return ContainerMaintenanceForExport;
    }
  );
  jsonexport(
    ContainerMaintenancesForExport,
    {
      headers: [
        "date",
        "fuel",
        "blNumber",
        "quantity",
        "totalPrice",
        "createdAt",
      ],
      rename: [
        "Date de dotation",
        "Type de fluide",
        "N° bon de livraison",
        "Quantité",
        "Prix total (MAD)",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Bacs"); // download as 'posts.csv` file
    }
  );
};

const SuccessChip = () => (
  <Stack direction="row" alignItems="center" spacing={0}>
    <Chip color="success" size="small" /* label="Actif" */ />
  </Stack>
);
const ErrorChip = () => (
  <Stack direction="row" alignItems="center" spacing={0}>
    <Chip color="error" size="small" /* label="Inactif" */ />
  </Stack>
);

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const ContainerMaintenanceListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      {/* <ExportButton /> */}
    </TopToolbar>
  );
};
const ContainerMaintenanceFilters = [
  <TextInput /* label="Recherche" */ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const ContainerMaintenanceList: React.FC = (props) => {
  const permissions = localStorage.getItem("permissions");
  const translate = useTranslate();
  return (
    <List
      actions={<ContainerMaintenanceListActions />}
      title={translate('labels.binsMaintenance')}
      filters={ContainerMaintenanceFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <WrapperField label={translate('labels.containernumber')}>
          <TextField source={"container.number"} />
        </WrapperField>
        <WrapperField label={translate('labels.containertype')}>
          <TextField source={"container.type"} />
        </WrapperField>
        <DateField
          locales="fr-FR"
          source={"date"}
        //label={"Date de maintenance"}
        />
        <TextField source={"number"} /* label={"N° intervention"} */ />
        <ArrayField source={"type"} /*label="Nature d'intervention"*/>
          <SingleFieldList>
            <FunctionField
              render={(record: any) => (
                <>
                  <SelectField record={{ name: record }} source={"name"} choices={[
                    { id: "Changement de roue", name: translate('labels.Wheelchange') },
                    {
                      id: "Changement de couvercle",
                      name: translate('labels.Lidchange'),
                    },
                    { id: "Dressage", name: translate('labels.Dressage') },
                    { id: "Accessoires", name: translate('labels.Accessories') },
                    { id: "Autre", name: translate('labels.autre') },
                  ]} /> &nbsp;
                </>

              )}
            />
          </SingleFieldList>
        </ArrayField>

        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /* label="Modifier" */ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const ContainerMaintenanceCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/container-maintenances/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.AddReform')}
      mutationOptions={{ onSuccess }}
      {...props}
    >
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} /* label="Date de maintenance" */ />
          <ReferenceInput
            source={"container[@id]"}
            reference="containers"
            perPage={1000}
          >
            <AutocompleteInput /* label="Bac" */ optionText={containerInfo} />
          </ReferenceInput>
          <SelectArrayInput
            source={"type"}
            //label="Type de maintenance"
            choices={[
              { id: "Changement de roue", name: translate('labels.Wheelchange') },
              {
                id: "Changement de couvercle",
                name: translate('labels.Lidchange'),
              },
              { id: "Dressage", name: translate('labels.Dressage') },
              { id: "Accessoires", name: translate('labels.Accessories') },
              { id: "Autre", name: translate('labels.autre') },
            ]}
          />
          <TextInput source={"number"} /* label="N° intervention" */ />
        </Box>
        <ImageInput source={"images"} /* label="Images" */ accept="image/*" multiple>
          <ImageField source={"src"} />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

interface ContainerMaintenanceTitleProps {
  record: RaRecord;
}

const ContainerMaintenanceTitle: React.FC<ContainerMaintenanceTitleProps> = ({
  record,
}) => {
  return <span>Réapprovisionnement {record ? `"${record.title}"` : ""}</span>;
};

export const ContainerMaintenanceEdit: React.FC<
  ContainerMaintenanceTitleProps
> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/container-maintenances/");
    refresh();
  };
  return (
    <Edit title={translate('titles.EditReform')} mutationMode="pessimistic" {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} /* label="Date de maintenance" */ />
          <ReferenceInput
            source={"container"}
            reference="containers"
            perPage={1000}
          >
            <AutocompleteInput /* label="Bac" */ optionText={containerInfo} />
          </ReferenceInput>
          <SelectArrayInput
            source={"type"}
            //label="Type de maintenance"
            choices={[
              { id: "Changement de roue", name: translate('labels.Wheelchange') },
              {
                id: "Changement de couvercle",
                name: translate('labels.Lidchange'),
              },
              { id: "Dressage", name: translate('labels.Dressage') },
              { id: "Accessoires", name: translate('labels.Accessories') },
              { id: "Autre", name: translate('labels.autre') },
            ]}
          />
          <TextInput source={"number"} /* label="N° intervention" */ />
        </Box>
        <ImageInput source={"images"} /* label="Images" */ accept="image/*" multiple>
          <ImageField source={"src"} />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const ContainerMaintenanceShow: React.FC<
  ContainerMaintenanceTitleProps
> = (props) => {
  const translate = useTranslate();
  return (
    <Show actions={<EditAction />} title={translate('titles.showbin')} {...props}>
      <SimpleShowLayout>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <Labeled>
            <TextField source={"container.number"} fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"container.type"} fontWeight="bold" />
          </Labeled>
          <Labeled>
            <DateField locales="fr-FR" source={"date"} fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"number"} fontWeight="bold" />
          </Labeled>
        </Box>
        <Box>
          <Labeled>
            <ArrayField source={"type"}>
              <SingleFieldList>
                <FunctionField
                  render={(record: any) => (
                    <ChipField record={{ name: record }} source={"name"} />
                  )}
                />
              </SingleFieldList>
            </ArrayField>
          </Labeled>
        </Box>
        <Labeled /* label="Image" */ marginTop="16px" fontSize="16px">
          <ImageField
            source={"images"}
            src="contentUrl"
            //label="Image"
            sx={{
              padding: "0",
            }}
          />
        </Labeled>
      </SimpleShowLayout>
    </Show>
  );
};
