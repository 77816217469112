import List from "@mui/material/List";
import * as React from "react";
import BuildIcon from "@mui/icons-material/Build";
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import { DashboardMenuItem, Menu, MenuItemLink, useTranslate } from "react-admin";
import { ReactComponent as BoardIcon } from '../images/pie-chart-03.svg';
import { ReactComponent as VehicleIcon } from '../images/bus.svg';
import { ReactComponent as DropletsIcon } from '../images/droplets-01.svg';
import { ReactComponent as MagasinIcon } from '../images/shopping-cart-02.svg';
import { ReactComponent as TrashIcon } from '../images/trash-04.svg';
import { ReactComponent as UserIcon } from '../images/user-01.svg';
import { ReactComponent as RouteIcon } from '../images/route.svg';

export default function MyMenu() {
  const translate = useTranslate();
  const [openFlotte, setOpenFlotte] = React.useState(false);
  const [openFluides, setOpenFluides] = React.useState(false);
  const [openStock, setOpenStock] = React.useState(false);
  const [openContainers, setOpenContainers] = React.useState(false);
  const [openRh, setOpenRh] = React.useState(false);
  const [openExploitation, setOpenExploitation] = React.useState(false); // Added state for Exploitation

  const siteId = localStorage.getItem("siteId");

  const handleOpenFlotte = () => setOpenFlotte(!openFlotte);
  const handleOpenFluides = () => setOpenFluides(!openFluides);
  const handleOpenStock = () => setOpenStock(!openStock);
  const handleOpenContainers = () => setOpenContainers(!openContainers);
  const handleOpenRh = () => setOpenRh(!openRh);
  const handleOpenExploitation = () => setOpenExploitation(!openExploitation); // Added handler for Exploitation

  return (
    <Menu
      sx={{
        bgcolor: "white",
        height: "100%",
        boxShadow: 1,
        borderRight: "1px solid #E4E7EC",
        paddingTop: "16px",
      }}
    >
      <DashboardMenuItem primaryText={translate('labels.dashboard')} leftIcon={<BoardIcon />} />

      {/* Flotte Section */}
      <Box
        onClick={() => {
          handleOpenFlotte();
          setOpenFluides(false);
          setOpenContainers(false);
          setOpenStock(false);
          setOpenRh(false);
          setOpenExploitation(false); // Ensure other sections are closed
        }}
        sx={{
          display: "flex",
          px: "16px",
          py: "8px",
          gap: "16px",
          justifyContent: "flex-start",
          alignItems: "center",
          margin: "4px 8px",
          borderRadius: "6px",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "#F0F1F2",
          },
        }}
      >
        <VehicleIcon />
        <ListItemText primary={translate('labels.vehicles')} sx={{ color: "#344054" }} />
        {openFlotte ? <ExpandLess sx={{ color: "#667085" }} /> : <ExpandMore sx={{ color: "#667085" }} />}
      </Box>
      <Collapse in={openFlotte} timeout="auto" unmountOnExit sx={{ bgcolor: "#F5F6F7", borderTop: "1px solid #D0D5DD", borderBottom: "1px solid #D0D5DD" }}>
        <List component="div" disablePadding>
          <MenuItemLink to="/vehicles" primaryText={translate('labels.vehiclesList')} sx={{ px: "56px" }} />
          <MenuItemLink to="/vehicle-drain-histories" primaryText="Vidanges" sx={{ pl: "56px" }} />
          <MenuItemLink to="/vehicle-control-histories" primaryText={translate('labels.controls')} sx={{ px: "56px" }} />
          <MenuItemLink to="/vehicle-insurance-histories" primaryText={translate('labels.insurance')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/vehicle-incident-histories" primaryText={translate('labels.accidents')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/vehicle-tax-histories" primaryText={translate('labels.taxes')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/vehicle-funding-histories" primaryText={translate('labels.funding')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/vehicle-immobility-histories" primaryText={translate('labels.immobilizations')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/vehicle-intervention-histories" primaryText={translate('labels.interventions')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/vehicle-tire-histories" primaryText={translate('labels.tires')} sx={{ pl: "56px" }} />
        </List>
      </Collapse>

      {/* Fluides Section */}
      <Box
        onClick={() => {
          handleOpenFluides();
          setOpenFlotte(false);
          setOpenContainers(false);
          setOpenStock(false);
          setOpenRh(false);
          setOpenExploitation(false); // Ensure other sections are closed
        }}
        sx={{
          display: "flex",
          px: "16px",
          py: "8px",
          gap: "16px",
          justifyContent: "flex-start",
          alignItems: "center",
          margin: "4px 8px",
          borderRadius: "6px",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "#F0F1F2",
          },
        }}
      >
        <DropletsIcon />
        <ListItemText primary="Carburant/fluides" sx={{ color: "#344054" }} />
        {openFluides ? <ExpandLess sx={{ color: "#667085" }} /> : <ExpandMore sx={{ color: "#667085" }} />}
      </Box>
      <Collapse in={openFluides} timeout="auto" unmountOnExit sx={{ bgcolor: "#F7F9F9", borderTop: "1px solid #D0D5DD", borderBottom: "1px solid #D0D5DD" }}>
        <List component="div" disablePadding>
          <MenuItemLink to="/fuel-consumptions" primaryText={translate('labels.consumptions')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/fuel-allocations" primaryText={translate('labels.replenishment')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/cards" primaryText={translate('labels.fuelCards')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/fuel-summary" primaryText="Bilan mensuel" sx={{ pl: "56px" }} />
        </List>
      </Collapse>

      {/* Stock Section */}
      {["28", "23", "30", "undefined"].includes(siteId!) ? (
        <>
          <Box
            onClick={() => {
              handleOpenStock();
              setOpenFlotte(false);
              setOpenContainers(false);
              setOpenFluides(false);
              setOpenRh(false);
              setOpenExploitation(false); // Ensure other sections are closed
            }}
            sx={{
              display: "flex",
              px: "16px",
              py: "8px",
              gap: "16px",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: "4px 8px",
              borderRadius: "6px",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "#F0F1F2",
              },
            }}
          >
            <MagasinIcon />
            <ListItemText primary="Stock" sx={{ color: "#344054" }} />
            {openStock ? <ExpandLess sx={{ color: "#667085" }} /> : <ExpandMore sx={{ color: "#667085" }} />}
          </Box>
          <Collapse in={openStock} timeout="auto" unmountOnExit sx={{ bgcolor: "#F5F6F7", borderTop: "1px solid #D0D5DD", borderBottom: "1px solid #D0D5DD" }}>
            <List component="div" disablePadding>
              <MenuItemLink to="/suppliers" primaryText={translate('labels.suppliers')} sx={{ pl: "56px" }} />
              <MenuItemLink to="/stock-articles" primaryText={translate('labels.articles')} sx={{ pl: "56px" }} />
              <MenuItemLink to="/stock-movements" primaryText="Entrées/Sorties" sx={{ pl: "56px" }} />
            </List>
          </Collapse>
        </>
      ) : null}

      {/* Containers Section */}
      {["28", "23", "30", "undefined"].includes(siteId!) ? (
        <>
          <Box
            onClick={() => {
              handleOpenContainers();
              setOpenFlotte(false);
              setOpenFluides(false);
              setOpenStock(false);
              setOpenRh(false);
              setOpenExploitation(false); // Ensure other sections are closed
            }}
            sx={{
              display: "flex",
              px: "16px",
              py: "8px",
              gap: "16px",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: "4px 8px",
              borderRadius: "6px",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "#F0F1F2",
              },
            }}
          >
            <TrashIcon />
            <ListItemText primary={translate('labels.bins')} sx={{ color: "#344054" }} />
            {openContainers ? <ExpandLess sx={{ color: "#667085" }} /> : <ExpandMore sx={{ color: "#667085" }} />}
          </Box>
          <Collapse in={openContainers} timeout="auto" unmountOnExit sx={{ bgcolor: "#F5F6F7", borderTop: "1px solid #D0D5DD", borderBottom: "1px solid #D0D5DD" }}>
            <List component="div" disablePadding>
              <MenuItemLink to="/dashboard-containers" primaryText={translate('labels.dashboard')} sx={{ pl: "56px" }} />
              <MenuItemLink to="/containers" primaryText={translate('labels.binsList')} sx={{ pl: "56px" }} />
              <MenuItemLink to="/container-reforms" primaryText={translate('labels.binsReform')} sx={{ pl: "56px" }} />
              <MenuItemLink to="/container-maintenances" primaryText={translate('labels.binsMaintenance')} sx={{ pl: "56px" }} />
            </List>
          </Collapse>
        </>
      ) : null}

      {/* Gestion RH Section */}
      <Box
        onClick={() => {
          handleOpenRh();
          setOpenFlotte(false);
          setOpenFluides(false);
          setOpenContainers(false);
          setOpenStock(false);
          setOpenExploitation(false); // Ensure other sections are closed
        }}
        sx={{
          display: "flex",
          px: "16px",
          py: "8px",
          gap: "16px",
          justifyContent: "flex-start",
          alignItems: "center",
          margin: "4px 8px",
          borderRadius: "6px",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "#F0F1F2",
          },
        }}
      >
        <UserIcon />
        <ListItemText primary="Gestion RH" sx={{ color: "#344054" }} />
        {openRh ? <ExpandLess sx={{ color: "#667085" }} /> : <ExpandMore sx={{ color: "#667085" }} />}
      </Box>
      <Collapse in={openRh} timeout="auto" unmountOnExit sx={{ bgcolor: "#F5F6F7", borderTop: "1px solid #D0D5DD", borderBottom: "1px solid #D0D5DD" }}>
        <List component="div" disablePadding>
                <MenuItemLink to="/dashboard-hr" primaryText={translate('labels.dashboardHr')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/employees" primaryText={translate('labels.employees')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/presences" primaryText={translate('labels.presences')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/overtimes" primaryText={translate('labels.overtimes')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/vacations" primaryText={translate('labels.vacations')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/work-accidents" primaryText={translate('labels.workAccidents')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/traffic-code-violations" primaryText={translate('labels.trafficCodeViolations')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/medical-visits" primaryText={translate('labels.medicalVisits')} sx={{ pl: "56px" }} />
          <MenuItemLink to="/trainings" primaryText={translate('labels.trainings')} sx={{ pl: "56px" }} />
        </List>
      </Collapse>
      {/* Exploitation Section */}
      <Box
        onClick={() => {
          handleOpenExploitation();
          setOpenFlotte(false);
          setOpenFluides(false);
          setOpenContainers(false);
          setOpenStock(false);
          setOpenRh(false);
        }}
        sx={{
          display: "flex",
          px: "16px",
          py: "8px",
          gap: "16px",
          justifyContent: "flex-start",
          alignItems: "center",
          margin: "4px 8px",
          borderRadius: "6px",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "#F0F1F2",
          },
        }}
      >
        <RouteIcon />
        <ListItemText primary="Exploitation" sx={{ color: "#344054" }} />
        {openExploitation ? <ExpandLess sx={{ color: "#667085" }} /> : <ExpandMore sx={{ color: "#667085" }} />}
      </Box>
      <Collapse in={openExploitation} timeout="auto" unmountOnExit sx={{ bgcolor: "#F5F6F7", borderTop: "1px solid #D0D5DD", borderBottom: "1px solid #D0D5DD" }}>
        <List component="div" disablePadding>
          <MenuItemLink to="/neighborhoods" primaryText="Quartiers" sx={{ pl: "56px" }} />
          <MenuItemLink to="/streets" primaryText="Rues" sx={{ pl: "56px" }} />
          <MenuItemLink to="/sensitive-points" primaryText="Points sensibles" sx={{ pl: "56px" }} />
          <MenuItemLink to="/cleanings" primaryText="Nettoiement" sx={{ pl: "56px" }} />
          <MenuItemLink to="/collectings" primaryText="Collecte" sx={{ pl: "56px" }} />
        </List>
      </Collapse>
    </Menu>
  );
}
