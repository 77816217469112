import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  FileField,
  ImageField,
  ImageInput,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  ReferenceField,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  required,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { formatDate } from "../utils";
import { useTranslate } from 'react-admin';

const exporter = (stockArticles: any) => {
  const stockArticlesForExport = stockArticles.map(
    (stockArticle: any) => {
      const {
        "@id": arobasId,
        "@type": arobasType,
        id,
        place,
        originId,
        endOfDayMeter,
        distribution,
        createdAt: stockArticleCreatedAt,
        updatedAt: stockArticleUpdatedAt,
        number,
        ...stockArticleForExport
      } = stockArticle;
      stockArticleForExport.date = formatDate(stockArticleForExport.date);
      // stockArticleForExport.date // omit backlinks and author
      return stockArticleForExport;
    }
  );
  jsonexport(
    stockArticlesForExport,
    {
      headers: [
        "date",
        "vehicle.parkingNumber",
        "vehicle.gear",
        "driver.firstName",
        "driver.lastName",
        "assignment",
        "type",
        "quantity",
        "mileage",
        "timeMeter",
        "diffMileage",
        "quantityPerDiffMileage",
        "diffTimeMeter",
        "quantityPerDiffTimeMeter",
      ],
      rename: [
        "Date de distribution",
        "N° de parc",
        "Équipement",
        "Prénom conducteur",
        "Nom conducteur",
        "Affectation",
        "Type de fluide",
        "Quantité (L)",
        "Kilométrage",
        "Heures Moteur",
        "KM parcouru",
        "Qté/Km parcouru(%)",
        "Hrs service",
        "Qté/Hrs service",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Articles"); // download as 'posts.csv` file
    }
  );
};

const choices = (translate: any) => {
  return [
    { id: "Boite de vitesse", name: translate('labels.Boitedevitesse') },
    { id: "Cabine", name: translate('labels.Cabine') },
    { id: "Circuit de gazoil", name: translate('labels.Circuitdegazoil') },
    { id: "Circuit de refroidissement", name: translate('labels.Circuitderefroidissement') },
    { id: "Circuit électrique", name: translate('labels.Circuitélectrique') },
    { id: "Circuit pneumatique", name: translate('labels.Circuitpneumatique') },
    { id: "Embrayage", name: translate('labels.Embrayage') },
    { id: "Mécanisme de freinage", name: translate('labels.Mécanismedefreinage') },
    { id: "Moteur", name: translate('labels.Moteur') },
    { id: "Pneumatique", name: translate('labels.Pneumatique') },
    { id: "Suspension", name: translate('labels.Suspension') },
    { id: "Lame de ressort", name: translate('labels.Lamederessort') },
    { id: "Transmission", name: translate('labels.Transmission') },
    { id: "Autre", name: translate('labels.autre') },
    { id: "Accessoires hydraulique", name: translate('labels.Accessoireshydraulique') },
    { id: "Accessoires pneumatique", name: translate('labels.Accessoirespneumatique') },
    { id: "Balais", name: translate('labels.Balais') },
    { id: "Chaudronnerie", name: translate('labels.Chaudronnerie') },
    { id: "Distributeur", name: translate('labels.Distributeur') },
    { id: "Flexible", name: translate('labels.Flexible') },
    { id: "Huile hydraulique", name: translate('labels.Huilehydraulique') },
    { id: "Peinture", name: translate('labels.Peinture') },
    { id: "Pompe", name: translate('labels.Pompe') },
    { id: "Prise de force", name: translate('labels.Prisedeforce') },
    { id: "Vérin", name: translate('labels.Vérin') },
    { id: "Autre", name: translate('labels.autre') },
  ];
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;
const SupplierInfo = (choice: { code: string, name: string }) =>
  `${choice.code} - ${choice.name}`;
const filterToQuery = (searchText: any) => ({ query: `${searchText}` });

const ArticleListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
    </TopToolbar>
  );
};
const stockArticlesFilters = (translate: any) => {
  return [
    <TextInput /*label="Recherche"*/ source={"query"} alwaysOn resettable />,
    <AutocompleteInput
      source={"subfamily"}
      //label="Sous-famille"
      alwaysOn
      choices={choices(translate)}
    />,
  ];
};

export const ArticleList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<ArticleListActions />}
      title={translate('titles.articles')}
      empty={<Empty create />}
      {...props}
      filters={stockArticlesFilters(translate)}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <TextField source={"designation"} />
        <TextField source={"code"} />
        <WrapperField source="supplier" sortBy="supplier.code">
          <TextField source={"supplier.code"} />
          &nbsp;-&nbsp;
          <TextField source={"supplier.name"} />
        </WrapperField>
        <TextField source={"brand"} />
        <DateField source={"createdAt"} />
        <NumberField source="quantity" />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const ArticleCreate: React.FC = (props) => {
  const postDefaultValue = () => ({ code: 'ART-' + Math.floor(Math.random() * 100000) });
  // const postDefaultValue = () => ({ code: "ART" + Math.floor(Math.random() * 100000)});
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/stock-articles/");
    refresh();
  };
  return (
    <Create
      title="Ajouter un article"
      mutationOptions={{ onSuccess }}
      {...props}
    >
      <SimpleForm justifyContent="center" defaultValues={postDefaultValue}>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <TextInput source={"code"} InputProps={{ disabled: true }} />
          <ReferenceInput
            source={"supplier"}
            reference="suppliers"
            perPage={1000}
          >
            <AutocompleteInput
              validate={[required("Champ obligatoire")]}
              optionText={SupplierInfo}
              filterToQuery={filterToQuery}
            />
          </ReferenceInput>
          <TextInput source={"designation"} />
          <TextInput source={"brand"} />
          <TextInput source={"type"} />
          <SelectInput
            source={"family"}
            choices={[
              { id: "Chassis", name: translate('labels.Chassis') },
              { id: "Equipement", name: translate('labels.Equipement') },
              { id: "Chassis/Equipement", name: translate('labels.ChassisEquipement') },
            ]}
          />
          <AutocompleteInput
            source={"subfamily"}
            choices={choices(translate)}
          />
          <TextInput source={"ref"} />
        </Box>
        <ImageInput source={"images"} /*label="Images"*/ accept="image/*" multiple>
          <ImageField source={"src"} />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

interface ArticleTitleProps {
  record: RaRecord;
}

const ArticleTitle: React.FC<ArticleTitleProps> = ({ record }) => {
  return <span>Article {record ? `"${record.code}"` : ""}</span>;
};

export const ArticleEdit: React.FC<ArticleTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été modifié`);
    redirect("/stock-articles/");
    refresh();
  };
  return (
    <Edit
      title={<ArticleTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}
    >
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <TextInput source={"code"} /*label="Code"*/ InputProps={{ disabled: true }} />
          <ReferenceInput
            source={"supplier[@id]"}
            reference="suppliers"
            perPage={1000}
            validate={[required("Champ obligatoire")]}
          >
            <AutocompleteInput
              validate={[required("Champ obligatoire")]}
              optionText={SupplierInfo}
              filterToQuery={filterToQuery}
            //label="Fournisseur"
            />
          </ReferenceInput>
          <TextInput source={"designation"} /*label="Désignation"*/ />
          <TextInput source={"brand"} /*label="Marque"*/ />
          <TextInput source={"type"} />
          <SelectInput
            source={"family"}
            //label="Famille"
            choices={[
              { id: "Chassis", name: translate('labels.Chassis') },
              { id: "Equipement", name: translate('labels.Equipement') },
              { id: "Chassis/Equipement", name: translate('labels.ChassisEquipement') },
            ]}
          />
          <AutocompleteInput
            source={"subfamily"}
            //label="Sous-famille"
            choices={choices(translate)}
          />
          <TextInput source={"ref"} /*label="Référence"*/ />
          {/* <SelectInput
            source={"state"}
            //label="État"
            choices={[
              { id: "Neuf", name: translate('labels.Neuf') },
              { id: "Bonne occasion", name: translate('labels.Bonneoccasion') },
              { id: "Réparé", name: translate('labels.Réparé') },
              { id: "Confectionné", name: translate('labels.Confectionné') },
            ]}
          /> */}
        </Box>
        <ImageInput source={"images"} /*label="Images"*/ accept="image/*" multiple>
          <ImageField source={"src"} />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const ArticleShow: React.FC<ArticleTitleProps> = (props) => (
  <Show
    actions={<EditAction />}
    title={<ArticleTitle record={props.record} />}
    {...props}
    sx={{ fontSize: "20px" }}
  >
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%"
      >
        <Labeled>
          <TextField source={"code"} />
        </Labeled>
        <Labeled>
          <ReferenceField
            //label="Véhicule"
            source={"supplier[@id]"}
            reference="suppliers"
            link={false}
          >
            <TextField source={"code"} />
          </ReferenceField>
        </Labeled>
        <Labeled>
          <TextField source={"brand"} /*label="Marque"*/ />
        </Labeled>
        <Labeled>
          <TextField source={"type"} label="Model" />
        </Labeled>
        <Labeled>
          <TextField source={"designation"} /*label="Désignation"*/ />
        </Labeled>
        <Labeled>
          <TextField source={"family"} />
        </Labeled>
        <Labeled>
          <TextField source={"subfamily"} />
        </Labeled>
        <Labeled>
          <TextField source={"ref"} /*label="Référence"*/ />
        </Labeled>
      </Box>
      <ImageField
        source={"images"}
        src="contentUrl"
        //label="Image"
        sx={{
          padding: "0",
        }}
      />
    </SimpleShowLayout>
  </Show>
);
