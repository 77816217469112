import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  SelectColumnsButton,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const exporter = (cards: any) => {
  const cardsForExport = cards.map(
    (card: any) => {
      console.log("card", card);
      const {
        "@id": arobasId,
        "@type": arobasType,
        attachments,
        id,
        originId,
        operator,
        site,
        updatedAt,
        ...cardForExport
      } = card; // omit backlinks and author
      return cardForExport;
    }
  );
  jsonexport(
    cardsForExport,
    {
      headers: [
        "date",
        "code",
        "supplier",
        "amount",
        "createdAt",
      ],
      rename: [
        "Date",
        "Code",
        "Fournisseur",
        "Montant (MAD)",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Cartes carburant"); // download as 'posts.csv` file
    }
  );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const CardListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const CardFilters = [
  <TextInput /* label="Recherche" */ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const CardList: React.FC = (props) => {
  const permissions = localStorage.getItem("permissions");
  const translate = useTranslate();
  return (
    <List
      actions={<CardListActions />}
      title={translate('labels.fuelCards')}
      filters={CardFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField locales="fr-FR" source={"date"} /* label={"Date"} */ />
        <TextField source={"code"} /* label={"Code"} */ />
        <TextField source={"supplier"} /* label={"Fournisseur"} */ />
        <NumberField source={"amount"} /* label={"Montant (MAD)"} */ />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /* label="Modifier" */ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const CardCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/cards/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.Addendowment')}
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            // xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateInput source={"date"} /* label="Date" */ />
          <TextInput source={"code"} /* label="Code" */ />
          <TextInput source={"supplier"} /* label="Fournisseur" */ />
          <NumberInput source={"amount"} /* label="Montant (MAD)" */ />
          {/* <NumberInput source="totalPrice" label="Prix total" /> */}
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

interface CardTitleProps {
  record: RaRecord;
}

const CardTitle: React.FC<CardTitleProps> = ({
  record,
}) => {
  const translate = useTranslate();
  return <span>{translate('labels.fuelCard')} {record ? `"${record.title}"` : ""}</span>;
};

export const CardEdit: React.FC<CardTitleProps> = (
  props
) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/cards/");
    refresh();
  };
  return (
    <Edit
      title={<CardTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateInput source={"date"} /* label="Date" */ />
          <TextInput source={"code"} /* label="Code" */ />
          <TextInput source={"supplier"} /* label="Fournisseur" */ />
          <NumberInput source={"amount"} /* label="Montant (MAD)" */ />
          {/* <NumberInput source="totalPrice" label="Prix total" /> */}
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export const CardShow: React.FC<CardTitleProps> = (
  props
) => (
  <Show
    actions={<EditAction />}
    title={<CardTitle record={props.record} />}
    {...props}
    sx={{ fontSize: "20px" }}>
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          // xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%">
        <Labeled label="Date">
          <DateField
            showTime
            source={"date"}
            //label="Date"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Code">
          <TextField source={"code"} /* label="Code" */ fontWeight="bold" />
        </Labeled>
        <Labeled label="Fournisseur">
          <TextField source={"supplier"} /* label="Fournisseur" */ fontWeight="bold" />
        </Labeled>
        <Labeled label="Montant (MAD)">
          <TextField source={"amount"} /* label="Montant (MAD)" */ fontWeight="bold" />
        </Labeled>
        {/* <Labeled label="Prix total">
          <TextField source="totalPrice" label="Prix total" fontWeight="bold" />
        </Labeled> */}
      </Box>
      <Labeled /* label="Fichiers" */ marginTop="16px">
        <FileField
          source={"attachments"}
          src="contentUrl"
          title="title"
          target="_blank"
        />
      </Labeled>
    </SimpleShowLayout>
  </Show>
);
