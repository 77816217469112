import { Box, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {
  AppBar,
  RefreshButton,
  TitlePortal,
  UserMenu
} from "react-admin";
import sc_logo from "../images/scLogo.png";

const MyCustomIcon = () => {
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const siteLogo = localStorage.getItem("siteLogo");
  return (
    <>
      <Avatar
        sx={{
          height: 36,
          width: 36,
        }}
        src={siteLogo === "undefined" ? sc_logo : siteLogo!}
      />
      <Typography
        variant="body2"
        color="inherit"
        sx={{
          ml: 1,
          display: { xs: "none", sm: "block" },
        }}
      >{`${firstName} ${lastName}`}</Typography>
    </>
  );
};

export function MyAppBar() {
  return (
    <AppBar
      sx={{ justifyContent: "center" }}
      userMenu={<UserMenu icon={<MyCustomIcon />} />}
      alwaysOn
    >
      <Box
        component="img"
        sx={{
          height: 20,
          display: { xs: "none", md: "block" },
        }}
        src={require("../images/logo.png")}
      />
      <Box flex="1" />
      <TitlePortal />
      <RefreshButton sx={{ display: "none" }} />
      <Box flex="1" />
    </AppBar>
  );
}
