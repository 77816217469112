import { Box, Chip, Stack, Typography } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  FunctionField,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  required,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';
import { useState } from "react";
import { useWatch } from "react-hook-form";

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });
const ArticleInfo = (choice: { designation: string, code: string }) =>
  `${choice.code} - ${choice.designation}`;
const SuccessChip = () => {
  const translate = useTranslate();
  return (
    <Stack direction="row" alignItems="center" spacing={0}>
      <Chip color="success" size="small" label={translate('labels.in')} />
    </Stack>
  );
};
const ErrorChip = () => {
  const translate = useTranslate();
  return (
    <Stack direction="row" alignItems="center" spacing={0}>
      <Chip color="error" size="small" label={translate('labels.out')} />
    </Stack>
  );
};

const exporter = (tires: any) => {
  const tiresForExport = tires.map((tire: any) => {
    console.log("tire", tire);
    const {
      "@id": arobasVehicleId,
      "@type": arobasVehicleType,
      createdAt,
      site: vehicleSite,
      operator: vehicleOperator,
      updatedAt: vehicleUpdatedAt,
      ...vehicleForExport
    } = tire.vehicle;
    tire.vehicle = vehicleForExport;
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      originId,
      site,
      updatedAt,
      biNumber,
      dimension,
      images,
      mileage,
      price,
      serialNumber,
      type,
      tire: tireStockMovement,
      ...tireForExport
    } = tire; // omit backlinks and author
    return tireForExport;
  });
  jsonexport(
    tiresForExport,
    {
      headers: [
        "date",
        "vehicle.parkingNumber",
        "vehicle.brand",
        "vehicle.model",
        "interventionType",
        "createdAt",
      ],
      rename: [
        "Date du contrôle",
        "Numéro de parc",
        "Marque",
        "Modèle",
        "Type de contrôle",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Pneumatiques"); // download as 'posts.csv` file
    }
  );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const StockMovementListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
    </TopToolbar>
  );
};
const StockMovementFilters = [
  <TextInput source={"query"} alwaysOn />,
];

export const StockMovementList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<StockMovementListActions />}
      title={translate('titles.Mouvementstock')}
      empty={<Empty create />}
      {...props}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField locales="fr-FR" source={"date"} /*label={"Date"}*/ />
        <FunctionField
          source={"movement"}
          render={(record: any) => {
            if (record.movement === "in") return <SuccessChip />;
            else if (record.movement === "out") return <ErrorChip />;
            else return <></>;
          }}
        />
        <WrapperField source="article" sortBy="article.designation">
          <TextField source={"article.designation"} />
        </WrapperField>
        <NumberField source={"quantity"}/>
        <NumberField source={"unitPrice"}/>
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

// const QuantityRemaining = ({a} : {a:any}) => {
//   const QO = useWatch({ name: 'quantityOut' });
//   return (
//     <NumberInput
//       source="quantityRemaining"
//       format={v => QO + 100}
//       readOnly
//     />
//   );
// };

export const StockMovementCreate: React.FC = (props) => {
  const [movementType, setMovementType] = useState(null);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/stock-movements/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.addstockmovement')}
      mutationOptions={{ onSuccess }}
      {...props}
    >
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <SelectInput source={"movement"} validate={[required("Champ obligatoire")]} choices={[
            { id: "in", name: translate('labels.in') },
            { id: "out", name: translate('labels.out') },
          ]}
            onChange={(e) => setMovementType(e.target.value)} />
          <ReferenceInput source={"article"} reference="stock-articles" perPage={1000}>
            <AutocompleteInput optionText={ArticleInfo} filterToQuery={filterToQuery} validate={[required("Champ obligatoire")]} />
          </ReferenceInput>
          <DateInput
            source={"date"}
            //label="Date"
            defaultValue={new Date()}
            validate={[required("Champ obligatoire")]}
          />
        </Box>
        {movementType === "in" &&
          <>
            <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
              Article entrant
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
                lg: "repeat(4, 1fr)",
                xl: "repeat(5, 1fr)",
              }}
              gap={2}
              width="100%"
            >
              <TextInput source={"blbsNumber"} />
              <NumberInput source={"quantity"} />
              <NumberInput source={"unitPrice"} />
              <SelectInput
                source={"state"}
                choices={[
                  { id: "Neuf", name: translate('labels.Neuf') },
                  { id: "Bonne occasion", name: translate('labels.Bonneoccasion') },
                  { id: "Réparé", name: translate('labels.Réparé') },
                  { id: "Confectionné", name: translate('labels.Confectionné') },
                ]}
              />
            </Box>
          </>
        }
        {movementType === "out" &&
          <>
            <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
              Article sortant
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
                lg: "repeat(4, 1fr)",
                xl: "repeat(5, 1fr)",
              }}
              gap={2}
              width="100%"
            >
              <TextInput source={"bsNumber"}/>
              <TextInput source={"fiNumber"}/>
              <NumberInput source={"quantity"} />
              <TextInput source={"applicant"}/>
              <ReferenceInput source="vehicle" reference="vehicles" perPage={1000}>
                <AutocompleteInput optionText={"parkingNumber"} label="Véhicule" filterToQuery={filterToQuery} />
              </ReferenceInput>

            </Box>
          </>
        }
      </SimpleForm>
    </Create >
  );
};

interface StockMovementTitleProps {
  record: RaRecord;
}

const StockMovementTitle: React.FC<StockMovementTitleProps> = ({ record }) => {
  const translate = useTranslate();
  return <span>Entrée/Sortie {record ? `"${record.title}"` : ""}</span>;
};

export const StockMovementEdit: React.FC<StockMovementTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();
  const [movementType, setMovementType] = useState(
    useRecordContext()?.movement
  );

  const onSuccess = () => {
    notify(`L'élément a bien été modifié`);
    redirect("/stock-movements/");
    refresh();
  };
  return (
    <Edit
      title={<StockMovementTitle record={props.record} />}
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess }}
      {...props}
    >
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <SelectInput source={"movement"} validate={[required("Champ obligatoire")]} choices={[
            { id: "in", name: translate('labels.in') },
            { id: "out", name: translate('labels.out') },
          ]}
            onChange={(e) => setMovementType(e.target.value)} />
          <ReferenceInput source={"article[@id]"} reference="stock-articles" perPage={1000}>
            <AutocompleteInput optionText={ArticleInfo} filterToQuery={filterToQuery} validate={[required("Champ obligatoire")]} />
          </ReferenceInput>
          <DateInput
            source={"date"}
            //label="Date"
            defaultValue={new Date()}
            validate={[required("Champ obligatoire")]}
          />
        </Box>
        {movementType === "in" &&
          <>
            <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
              Article entrant
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
                lg: "repeat(4, 1fr)",
                xl: "repeat(5, 1fr)",
              }}
              gap={2}
              width="100%"
            >
              <TextInput source={"blbsNumber"} />
              <NumberInput source={"quantity"} />
              <NumberInput source={"unitPrice"} />
              <SelectInput
                source={"state"}
                choices={[
                  { id: "Neuf", name: translate('labels.Neuf') },
                  { id: "Bonne occasion", name: translate('labels.Bonneoccasion') },
                  { id: "Réparé", name: translate('labels.Réparé') },
                  { id: "Confectionné", name: translate('labels.Confectionné') },
                ]}
              />
            </Box>
          </>
        }
        {movementType === "out" &&
          <>
            <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
              Article sortant
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
                lg: "repeat(4, 1fr)",
                xl: "repeat(5, 1fr)",
              }}
              gap={2}
              width="100%"
            >
              <TextInput source={"bsNumber"} />
              <TextInput source={"fiNumber"} />
              <NumberInput source={"quantity"} />
              <TextInput source={"applicant"} />
              <ReferenceInput source="vehicle[@id]" reference="vehicles" perPage={1000}>
                <AutocompleteInput optionText={"parkingNumber"} label="Véhicule" filterToQuery={filterToQuery} />
              </ReferenceInput>

            </Box>
          </>
        }
      </SimpleForm>
    </Edit>
  );
};

export const StockMovementShow: React.FC<StockMovementTitleProps> = (props) => {
  const translate = useTranslate();
  return (
    <Show
      actions={<EditAction />}
      title={<StockMovementTitle record={props.record} />}
      {...props}
      sx={{ fontSize: "20px" }}
    >
      <SimpleShowLayout>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <Labeled>
            <DateField source={"date"} /*label="Date"*/ locales="fr-FR" />
          </Labeled>
          <Labeled>
            <TextField source={"article.designation"} />
          </Labeled>

          <Labeled>
            <SelectField source={"movement"} choices={[
              { id: "in", name: translate('labels.in') },
              { id: "out", name: translate('labels.out') },
            ]} />
          </Labeled>
          <Labeled>
            <TextField source={"blbsNumber"} /*label="Numéro BL"*/ />
          </Labeled>
          <Labeled>
            <TextField source={"bsNumber"} /*label="Numéro BS"*/ />
          </Labeled>
          <Labeled>
            <TextField source={"fiNumber"} /*label="Numéro FI"*/ />
          </Labeled>
        </Box>

      </SimpleShowLayout>
    </Show>
  );
};
