import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const exporter = (fuelAllocations: any) => {
  const fuelAllocationsForExport = fuelAllocations.map(
    (fuelAllocation: any) => {
      console.log("fuelAllocation", fuelAllocation);
      const {
        "@id": arobasId,
        "@type": arobasType,
        attachments,
        id,
        originId,
        operator,
        site,
        updatedAt,
        ...fuelAllocationForExport
      } = fuelAllocation; // omit backlinks and author
      return fuelAllocationForExport;
    }
  );
  jsonexport(
    fuelAllocationsForExport,
    {
      headers: [
        "date",
        "fuel",
        "blNumber",
        "quantity",
        "totalPrice",
        "createdAt",
      ],
      rename: [
        "Date de dotation",
        "Type de fluide",
        "N° bon de livraison",
        "Quantité",
        "Prix total (MAD)",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Réapprovisionnements"); // download as 'posts.csv` file
    }
  );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const FuelAllocationListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const FuelAllocationFilters = [
  <TextInput /* label="Recherche" */ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const FuelAllocationList: React.FC = (props) => {
  const permissions = localStorage.getItem("permissions");
  const translate = useTranslate();
  return (
    <List
      actions={<FuelAllocationListActions />}
      title={translate('titles.Réapprovisionnements')}
      filters={FuelAllocationFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField locales="fr-FR" source={"date"} /* label={"Date de dotation"} */ />
        <SelectField source={"fuel"} choices={[
              { id: "Essence", name: translate('labels.Essence') },
              { id: "Gasoil", name: translate('labels.Gasoil') },
              { id: "AdBlue", name: translate('labels.AdBlue') },
              { id: "Huile moteur", name: translate('labels.Huilemoteur') },
              { id: "Autre", name: translate('labels.autre') },
            ]} /* label={"Type de fluide"} */ />
        <TextField source={"blNumber"} /* label={"Numéro de BL"} */ />
        <NumberField source={"quantity"} /* label={"Quantité (L)"} */ />
        <NumberField source={"totalPrice"} /* label={"Prix total"} */ />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /* label="Modifier" */ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const FuelAllocationCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/fuel-allocations/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.Addendowment')}
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateTimeInput source={"date"} /* label="Date de dotation" */ />
          <SelectInput
            source={"fuel"}
            //label="Type de fluide"
            choices={[
              { id: "Essence", name: translate('labels.Essence') },
              { id: "Gasoil", name: translate('labels.Gasoil') },
              { id: "AdBlue", name: translate('labels.AdBlue') },
              { id: "Huile moteur", name: translate('labels.Huilemoteur') },
              { id: "Autre", name: translate('labels.autre') },
            ]}
          />
          <TextInput source={"blNumber"} /* label="Numéro de BL" */ />
          <NumberInput source={"quantity"} /* label="Quantité (L)" */ />
          <NumberInput source={"totalPrice"} /* label="Prix total" */ />
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

interface FuelAllocationTitleProps {
  record: RaRecord;
}

const FuelAllocationTitle: React.FC<FuelAllocationTitleProps> = ({
  record,
}) => {
  const translate = useTranslate();
  return <span>{translate('titles.Réapprovisionnement')} {record ? `"${record.title}"` : ""}</span>;
};

export const FuelAllocationEdit: React.FC<FuelAllocationTitleProps> = (
  props
) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/fuel-allocations/");
    refresh();
  };
  return (
    <Edit
      title={<FuelAllocationTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateTimeInput source={"date"} /* label="Date de dotation" */ />
          <SelectInput
            source={"fuel"}
            //label="Type de fluide"
            choices={[
              { id: "Essence", name: translate('labels.Essence') },
              { id: "Gasoil", name: translate('labels.Gasoil') },
              { id: "AdBlue", name: translate('labels.AdBlue') },
              { id: "Huile moteur", name: translate('labels.Huilemoteur') },
              { id: "Autre", name: translate('labels.autre') },
            ]}
          />
          <TextInput source={"blNumber"} /* label="Numéro de BL" */ />
          <NumberInput source={"quantity"} /* label="Quantité (L)" */ />
          <NumberInput source={"totalPrice"} /* label="Prix total" */ />
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export const FuelAllocationShow: React.FC<FuelAllocationTitleProps> = (
  props
) => (
  <Show
    actions={<EditAction />}
    title={<FuelAllocationTitle record={props.record} />}
    {...props}
    sx={{ fontSize: "20px" }}>
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%">
        <Labeled /* label="Date de dotation" */>
          <DateField
            locales="fr-FR"
            showTime
            source={"date"}
            //label="Date de dotation"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled /* label="Type de fluide" */>
          <TextField source={"fuel"} /* label="Type de fluide" */ fontWeight="bold" />
        </Labeled>
        <Labeled /* label="Numéro de BL" */>
          <TextField source={"blNumber"} /* label="Numéro de BL" */ fontWeight="bold" />
        </Labeled>
        <Labeled /* label="Quantité (L)" */>
          <TextField source={"quantity"} /* label="Quantité (L)" */ fontWeight="bold" />
        </Labeled>
        <Labeled /* label="Prix total" */>
          <TextField source={"totalPrice"} /* label="Prix total" */ fontWeight="bold" />
        </Labeled>
      </Box>
      <Labeled /* label="Fichiers" */ marginTop="16px">
        <FileField
          source={"attachments"}
          src="contentUrl"
          title="title"
          target="_blank"
        />
      </Labeled>
    </SimpleShowLayout>
  </Show>
);
