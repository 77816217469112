import { Box, Typography } from "@mui/material";
import MUITextField from "@mui/material/TextField";
import jsonexport from "jsonexport/dist";
import { useState } from "react";
import {
  ArrayField,
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FunctionField,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  required,
  useInput,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh
} from "react-admin";
import { useWatch } from "react-hook-form";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const exporter = (interventions: any) => {
  const interventionsForExport = interventions.map((intervention: any) => {
    const {
      "@id": arobasVehicleId,
      "@type": arobasVehicleType,
      brand: brandVehicle,
      model: modelVehicle,
      createdAt: vehicleCreatedAt,
      site: vehicleSite,
      operator: vehicleOperator,
      updatedAt: vehicleUpdatedAt,
      ...vehicleForExport
    } = intervention.vehicle;
    intervention.vehicle = vehicleForExport;
    const {
      "@id": arobasArticlesId,
      "@type": arobasArticlesType,
      id: idArticles,
      ...articlesForExport
    } = intervention.articles;
    intervention.articles = articlesForExport;
    intervention.organFamily = intervention.organFamily.join("-");
    intervention.nature = intervention.nature.join("-");
    intervention.createdAt = intervention.createdAt.slice(0, 10);

    if (
      intervention.articles &&
      typeof intervention.articles === "object" &&
      Object.keys(intervention.articles).length > 0
    ) {
      // Extract values from articles object
      const articleDesignations = Object.values(intervention.articles)
        .map((article: any) => article.designation)
        .filter((designation: string) => designation !== undefined); // Filter out undefined designations
      const articleReferences = Object.values(intervention.articles)
        .map((article: any) => article.reference)
        .filter((reference: string) => reference !== undefined); // Filter out undefined references

      const articleUnitPrices = Object.values(intervention.articles)
        .map((article: any) => article.unitPrice)
        .filter((unitPrice: number) => unitPrice !== undefined);
      const articleQuantities = Object.values(intervention.articles)
        .map((article: any) => article.quantity)
        .filter((quantity: number) => quantity !== undefined);
      const articleTotalPrices = Object.values(intervention.articles)
        .map((article: any) => article.totalPrice)
        .filter((totalPrice: number) => totalPrice !== undefined);
      const articleStates = Object.values(intervention.articles)
        .map((article: any) => article.state)
        .filter((state: string) => state !== undefined);
      const articleFamilies = Object.values(intervention.articles)
        .map((article: any) => article.family)
        .filter((family: string) => family !== undefined);
      const articleSubfamilies = Object.values(intervention.articles)
        .map((article: any) => article.subfamily)
        .filter((subfamily: string) => subfamily !== undefined);

      // Create new properties with concatenated values
      intervention.articlesDesignation = articleDesignations.join(", ");
      intervention.articlesReference = articleReferences.join(", ");
      intervention.articlesUnitPrice = articleUnitPrices.join(", ");
      intervention.articlesQuantity = articleQuantities.join(", ");
      intervention.articlesTotalPrice = articleTotalPrices.join(", ");
      intervention.articlesState = articleStates.join(", ");
      intervention.articlesFamily = articleFamilies.join(", ");
      intervention.articlesSubfamily = articleSubfamilies.join(", ");
    }

    if (typeof intervention.returnToServiceDate !== "undefined")
      intervention.returnToServiceDate = intervention.returnToServiceDate.slice(
        0,
        10
      );
    if (typeof intervention.stopDate !== "undefined")
      intervention.stopDate = intervention.stopDate.slice(0, 10);
    if (typeof intervention.startDate !== "undefined")
      intervention.startDate = intervention.startDate.slice(0, 10);
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      originId,
      site,
      updatedAt,
      claim,
      review,
      applicant,
      remainingWork,
      deliveryDate,
      cashVoucherNumber,
      articles,
      createdAt,
      ...interventionForExport
    } = intervention;
    console.log(interventionForExport); // omit backlinks and author
    return interventionForExport;
  });
  jsonexport(
    interventionsForExport,
    {
      headers: [
        "sheetNumber",
        "vehicle.parkingNumber",
        "vehicle.gear",
        "stopDate",
        "startDate",
        "returnToServiceDate",
        "family",
        "timeMeter",
        "maintenanceType",
        "mileageMeter",
        "type",
        "defunctSystem",
        "organFamily",
        "nature",
        "supplier",
        "purchaseOrder",
        "quote",
        "delivery",
        "articlesDesignation",
        "articlesReference",
        "articlesUnitPrice",
        "articlesQuantity",
        "articlesTotalPrice",
        "articlesState",
        "articlesFamily",
        "articlesSubfamily",
        "totalCost",
      ],
      rename: [
        "N° fiche d'intervention",
        "Numéro de parc",
        "Équipement",
        "Date d'arrêt",
        "Date de début d'intervention",
        "Date de mise en service",
        "Famille",
        "Compteur horaire",
        "Type de maintenance",
        "Compteur Km",
        "Type d'intervention",
        "Système défectueux",
        "Famille d'organes",
        "Nature d'intervention",
        "Fournisseur",
        "Bon de commande",
        "Devis",
        "Bon de livraison",
        "Désignations articles",
        "Références articles",
        "Prix unitaires articles",
        "Quantités articles",
        "Prix total articles",
        "États articles",
        "Familles articles",
        "Sous-familles articles",
        "Coût total de l'intervention (MAD)",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Interventions"); // download as 'posts.csv` file
    }
  );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;
const VehicleInfo = (choice: { parkingNumber: string }) =>
  `${choice.parkingNumber}`;


const ReturnedTotalCost = (props: any) => {
  const articles = useWatch({ name: "articles" });
  const totalCosts = articles?.map((article: any) => {
    return article.totalPrice;
  });
  let Sum = 0;
  for (let i = 0; i < totalCosts?.length; i++) {
    Sum += totalCosts[i];
  }
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
  } = useInput(props);

  return (
    <MUITextField
      {...field}
      InputProps={{
        readOnly: true,
      }}
      //label="Coût total de l'intervention (auto)"
      type="number"
      value={Sum}
    />
  );
};
// const cu = articles;
// return (
//   <NumberInput
//     fullWidth
//     label="Coût total de l'intervention"
//     source="totalCost"
//     parse={() => Sum}
//     InputProps={{
//       readOnly: true,
//     }}
//   />
// );
//   return (
//     <label htmlFor={id}>
//         {"Total COST"}
//         <input id={id} {...field} value={Sum} />
//         {/* {fieldState.error && <span>{fieldState.error.message}</span>} */}
//     </label>
// );
const InterventionListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const InterventionFilters = (translate: any) => {
  return [
    <TextInput /*label="Recherche"*/ source={"query"} alwaysOn resettable />,
    <SelectInput
      source={"nature"}
      //label="Nature d'intervention"
      alwaysOn
      resettable
      choices={[
        { id: "Dressage", name: translate('labels.Dressage') },
        { id: "Réparation", name: translate('labels.Réparation') },
        { id: "Remplacement", name: translate('labels.Remplacement') },
        { id: "Contrôle", name: translate('labels.Contrôle') },
        { id: "Réglage", name: translate('labels.Réglage') },
        { id: "Serrage", name: translate('labels.Serrage') },
        { id: "Appoint", name: translate('labels.Appoint') },
      ]}
    />,
    // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
  ];
};
export const InterventionList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<InterventionListActions />}
      title="Interventions"
      filters={InterventionFilters(translate)}
      empty={<Empty create />}
      exporter={exporter}
      {...props}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <TextField source={"sheetNumber"} /*label={"N° de fiche d'intervention"}*/ />
        <WrapperField source="vehicle" /*label="Véhicule"*/ sortBy="vehicle.parkingNumber">
          <TextField source={"vehicle.parkingNumber"} />
        </WrapperField>
        <ArrayField source={"nature"} /*label="Nature d'intervention"*/>
          <SingleFieldList>
            <FunctionField
              render={(record: any) => (
                <>
                  <SelectField record={{ name: record }} source={"name"} choices={[
                    { id: "Dressage", name: translate('labels.Dressage') },
                    { id: "Réparation", name: translate('labels.Réparation') },
                    { id: "Remplacement", name: translate('labels.Remplacement') },
                    { id: "Contrôle", name: translate('labels.Contrôle') },
                    { id: "Réglage", name: translate('labels.Réglage') },
                    { id: "Serrage", name: translate('labels.Serrage') },
                    { id: "Appoint", name: translate('labels.Appoint') },
                  ]} /> &nbsp;
                </>

              )}
            />
          </SingleFieldList>
        </ArrayField>
        <SelectField source={"maintenanceType"} /*label={"Type de maintenance"}*/ choices={[
          { id: "Corrective", name: translate('labels.corrective') },
          { id: "Preventive", name: translate('labels.preventive') },
          { id: "Incident", name: translate('labels.incident') },
        ]} />
        <NumberField
          source={"totalCost"}
        //label={"Coût total de l'intervention (MAD)"}
        />
        <DateField locales="fr-FR" source={"stopDate"} /*label={"Date d'arrêt"}*/ />
        <DateField
          locales="fr-FR"
          source={"returnToServiceDate"}
        //label={"Date de remise en service"}
        />
        <TextField source={"claim"} /*label={"Réclamation"}*/ />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const InterventionCreate: React.FC = (props) => {
  const [interventionType, setInterventionType] = useState("");

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const choices = [
    { id: "Boite de vitesse", name: translate('labels.Boitedevitesse') },
    { id: "Cabine", name: translate('labels.Cabine') },
    { id: "Circuit de gazoil", name: translate('labels.Circuitdegazoil') },
    { id: "Circuit de refroidissement", name: translate('labels.Circuitderefroidissement') },
    { id: "Circuit électrique", name: translate('labels.Circuitélectrique') },
    { id: "Circuit pneumatique", name: translate('labels.Circuitpneumatique') },
    { id: "Embrayage", name: translate('labels.Embrayage') },
    { id: "Mécanisme de freinage", name: translate('labels.Mécanismedefreinage') },
    { id: "Moteur", name: translate('labels.Moteur') },
    { id: "Pneumatique", name: translate('labels.Pneumatique') },
    { id: "Suspension", name: translate('labels.Suspension') },
    { id: "Lame de ressort", name: translate('labels.Lamederessort') },
    { id: "Transmission", name: translate('labels.Transmission') },
    { id: "Autre", name: translate('labels.autre') },
    { id: "Accessoires hydraulique", name: translate('labels.Accessoireshydraulique') },
    { id: "Accessoires pneumatique", name: translate('labels.Accessoirespneumatique') },
    { id: "Balais", name: translate('labels.Balais') },
    { id: "Chaudronnerie", name: translate('labels.Chaudronnerie') },
    { id: "Distributeur", name: translate('labels.Distributeur') },
    { id: "Flexible", name: translate('labels.Flexible') },
    { id: "Huile hydraulique", name: translate('labels.Huilehydraulique') },
    { id: "Peinture", name: translate('labels.Peinture') },
    { id: "Pompe", name: translate('labels.Pompe') },
    { id: "Prise de force", name: translate('labels.Prisedeforce') },
    { id: "Vérin", name: translate('labels.Vérin') },
    { id: "Autre", name: translate('labels.autre') },
  ];

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/vehicle-intervention-histories/");
    refresh();
  };
  const filterToQuery = (searchText: any) => ({ query: `${searchText}` });
  return (
    <Create
      title={translate('titles.addintervention')}
      mutationOptions={{ onSuccess }}
      {...props}
    >
      <SimpleForm justifyContent="center">
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.demandedintervention')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <TextInput
            source={"sheetNumber"}
          //label="N° de fiche d'intervention"
          />
          <ReferenceInput
            source={"vehicle"}
            reference="vehicles"
            alwaysOn={true}
            sort={{ field: "parkingNumber", order: "ASC" }}
            perPage={1000}
            validate={[required("Champ obligatoire")]}
            filterToQuery={filterToQuery}
          >
            <AutocompleteInput
              validate={[required("Champ obligatoire")]}
              filterToQuery={filterToQuery}
              optionText={VehicleInfo}
            //label="Véhicule"
            />
          </ReferenceInput>
          <SelectInput
            source={"maintenanceType"}
            //label="Type de maintenance"
            validate={[required("Champ obligatoire")]}
            choices={[
              { id: "Corrective", name: translate('labels.corrective') },
              { id: "Preventive", name: translate('labels.preventive') },
              { id: "Incident", name: translate('labels.incident') },
            ]}
          />
          <TextInput source={"claim"} /*label="Réclamation"*/ />
          <DateInput source={"stopDate"} /*label="Date d'arrêt"*/ />
          <NumberInput source={"mileageMeter"} /*label="Compteur Km"*/ />
          <NumberInput source={"timeMeter"} /*label="Compteur horaire"*/ />
          <SelectInput
            source={"family"}
            //label="Famille"
            choices={[
              { id: "Chassis", name: translate('labels.Chassis') },
              { id: "Equipement", name: translate('labels.Equipement') },
              { id: "Chassis/Equipement", name: translate('labels.ChassisEquipement') },
            ]}
          />
          {/* <TextInput source={"operator"} label="Demandeur" /> */}
        </Box>

        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.exécutiondintervention')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"startDate"} /*label="Date début d'intervention"*/ />

          <TextInput source={"applicant"} /*label="Intervenant"*/ />
          <TextInput source={"defunctSystem"} /*label="Système défectueux"*/ />
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <AutocompleteArrayInput
            source={"nature"}
            //label="Nature d'intervention"
            choices={[
              { id: "Dressage", name: translate('labels.Dressage') },
              { id: "Réparation", name: translate('labels.Réparation') },
              { id: "Remplacement", name: translate('labels.Remplacement') },
              { id: "Contrôle", name: translate('labels.Contrôle') },
              { id: "Réglage", name: translate('labels.Réglage') },
              { id: "Serrage", name: translate('labels.Serrage') },
              { id: "Appoint", name: translate('labels.Appoint') },
            ]}
          />
          <AutocompleteArrayInput
            source={"organFamily"}
            //label="Famille d'organes"
            choices={[
              { id: "Circuit d'eau", name: translate('labels.Circuitdeau') },
              { id: "Vanne lixiviat", name: translate('labels.Vannelixiviat') },
              { id: "Roue", name: translate('labels.Roue') },
              { id: "Circuit AdBlue", name: translate('labels.CircuitAdBlue') },
              { id: "Moteur et c. annexe", name: translate('labels.Moteurcannexe') },
              { id: "Boîte à vitesse", name: translate('labels.Boitedevitesse') },
              { id: "Embrayage/Transmission", name: translate('labels.EmbrayageTransmission') },
              { id: "Direction", name: translate('labels.Direction') },
              { id: "Suspension", name: translate('labels.Suspension') },
              { id: "Système de freinage", name: translate('labels.Systèmedefreinage') },
              { id: "C. électrique", name: translate('labels.Circuitélectrique') },
              { id: "C. pneumatique", name: translate('labels.Circuitpneumatique') },
              { id: "Cabine", name: translate('labels.Cabine') },
              { id: "Prise de force", name: translate('labels.Prisedeforce') },
              { id: "Pompe", name: translate('labels.Pompe') },
              { id: "Distributeur", name: translate('labels.Distributeur') },
              { id: "Flexible/Raccord", name: translate('labels.FlexibleRaccord') },
              { id: "Vérins", name: translate('labels.Vérins') },
              { id: "Capteurs", name: translate('labels.Capteurs') },
              { id: "Acc. Hydr.", name: translate('labels.Accessoireshydraulique') },
              { id: "Balais", name: translate('labels.Balais') },
              { id: "Chaudronnerie", name: translate('labels.Chaudronnerie') },
              { id: "Autre", name: translate('labels.autre') },
            ]}
          />
        </Box>

        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.restitution')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput
            source={"returnToServiceDate"}
          //label="Date de remise en service"
          />
          <SelectInput
            source={"review"}
            //label="Avis sur l'intervention"
            choices={[
              { id: "Efficace", name: translate('labels.Efficace') },
              { id: "Inefficace", name: translate('labels.Inefficace') },
            ]}
          />
          <TextInput source={"remainingWork"} /*label="Travaux restants"*/ />
        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.articles')}
        </Typography>
        <SelectInput
          source={"type"}
          //label="Type d'intervention"
          choices={[
            { id: "Interne", name: translate('labels.Interne') },
            { id: "Externe", name: translate('labels.Externe') },
          ]}
          onChange={(e) => setInterventionType(e.target.value)}
        />
        {interventionType === "Interne" && (
          <TextInput source={"exitTicket"} /*label="Bon de sortie"*/ />
        )}
        {interventionType === "Externe" && (
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
            }}
            gap={2}
            width="100%"
          >
            <TextInput
              source={"supplier"}
            //label="Fournisseur"
            // choices={[
            //   { id: "SEFAMAR-MAN", name: "SEFAMAR-MAN" },
            //   { id: "ROCAMORA", name: "ROCAMORA" },
            //   { id: "PMEC", name: "PMEC" },
            //   { id: "TOTAL MAROC", name: "TOTAL MAROC" },
            //   { id: "ATLANTIC BENNE", name: "ATLANTIC BENNE" },
            //   { id: "EURAFRIC", name: "EURAFRIC" },
            //   { id: "CODETRAM", name: "CODETRAM" },
            //   { id: "FLEXI AUTO", name: "FLEXI AUTO" },
            //   { id: "FLEXI HYDRAULIQUE", name: "FLEXI HYDRAULIQUE" },
            //   { id: "VERAUTO", name: "VERAUTO" },
            //   { id: "SCANIA", name: "SCANIA" },
            //   { id: "BENNE MARREL", name: "BENNE MARREL" },
            //   { id: "DEPANAGE ELGHAZLANI", name: "DEPANAGE ELGHAZLANI" },
            //   { id: "EVINE", name: "EVINE" },
            //   { id: "AFRIQUIA", name: "AFRIQUIA" },
            //   { id: "GENESCO", name: "GENESCO" },
            //   { id: "3S", name: "3S" },
            //   { id: "NEW CATALOGUE", name: "NEW CATALOGUE" },
            //   { id: "HYDRAU TECHNIQUE", name: "HYDRAU TECHNIQUE" },
            //   { id: "HYUNDAI", name: "HYUNDAI" },
            //   { id: "SABATIER", name: "SABATIER" },
            // ]}
            />
            <TextInput source={"purchaseOrder"} /*label="N° bon de commande"*/ />
            <TextInput source={"quote"} /*label="N° devis"*/ />
            <TextInput source={"delivery"} /*label="N° bon de livraison"*/ />
            <DateInput source={"deliveryDate"} /*label="Date de livraison"*/ />
            <TextInput source={"cashVoucherNumber"} /*label="N° bon de caisse"*/ />
            {/* <TextInput source={"frequency"} label="Fréquence" /> */}
          </Box>
        )}

        <ArrayInput source={"articles"}>
          <SimpleFormIterator disableReordering disableClear inline>
            <TextInput source={"designation"} /*label="Désignation"*/ />
            <TextInput source={"reference"} /*label="Référence"*/ />
            <NumberInput source={"unitPrice"} /*label="Prix unitaire (MAD)"*/ />
            <NumberInput source={"quantity"} /*label="Quantité"*/ />
            <NumberInput source={"totalPrice"} /*label="Prix total (MAD)"*/ />
            <SelectInput
              source={"state"}
              //label="État"
              choices={[
                { id: "Neuf", name: translate('labels.Neuf') },
                { id: "Bonne occasion", name: translate('labels.Bonneoccasion') },
                { id: "Réparé", name: translate('labels.Réparé') },
                { id: "Confectionné", name: translate('labels.Confectionné') },
              ]}
            />
            <SelectInput
              source={"family"}
              //label="Famille"
              choices={[
                { id: "Chassis", name: translate('labels.Chassis') },
                { id: "Equipement", name: translate('labels.Equipement') },
              ]}
            />
            <AutocompleteInput
              source={"subfamily"}
              //label="Sous-famille"
              choices={choices}
            />
          </SimpleFormIterator>
        </ArrayInput>

        <ReturnedTotalCost source={"test"} />
      </SimpleForm>
    </Create>
  );
};

interface InterventionTitleProps {
  record: RaRecord;
}

const InterventionTitle: React.FC<InterventionTitleProps> = ({ record }) => {
  return <span>Intervention {record ? `"${record.title}"` : ""}</span>;
};

export const InterventionEdit: React.FC<InterventionTitleProps> = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const choices = [
    { id: "Boite de vitesse", name: translate('labels.Boitedevitesse') },
    { id: "Cabine", name: translate('labels.Cabine') },
    { id: "Circuit de gazoil", name: translate('labels.Circuitdegazoil') },
    { id: "Circuit de refroidissement", name: translate('labels.Circuitderefroidissement') },
    { id: "Circuit électrique", name: translate('labels.Circuitélectrique') },
    { id: "Circuit pneumatique", name: translate('labels.Circuitpneumatique') },
    { id: "Embrayage", name: translate('labels.Embrayage') },
    { id: "Mécanisme de freinage", name: translate('labels.Mécanismedefreinage') },
    { id: "Moteur", name: translate('labels.Moteur') },
    { id: "Pneumatique", name: translate('labels.Pneumatique') },
    { id: "Suspension", name: translate('labels.Suspension') },
    { id: "Lame de ressort", name: translate('labels.Lamederessort') },
    { id: "Transmission", name: translate('labels.Transmission') },
    { id: "Autre", name: translate('labels.autre') },
    { id: "Accessoires hydraulique", name: translate('labels.Accessoireshydraulique') },
    { id: "Accessoires pneumatique", name: translate('labels.Accessoirespneumatique') },
    { id: "Balais", name: translate('labels.Balais') },
    { id: "Chaudronnerie", name: translate('labels.Chaudronnerie') },
    { id: "Distributeur", name: translate('labels.Distributeur') },
    { id: "Flexible", name: translate('labels.Flexible') },
    { id: "Huile hydraulique", name: translate('labels.Huilehydraulique') },
    { id: "Peinture", name: translate('labels.Peinture') },
    { id: "Pompe", name: translate('labels.Pompe') },
    { id: "Prise de force", name: translate('labels.Prisedeforce') },
    { id: "Vérin", name: translate('labels.Vérin') },
    { id: "Autre", name: translate('labels.autre') },
  ];

  const [interventionType, setInterventionType] = useState(
    useRecordContext()?.type
  );
  const onSuccess = () => {
    notify(`L'élément a bien été modifié`);
    redirect("/vehicle-intervention-histories/");
    refresh();
  };
  return (
    <Edit
      title={<InterventionTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}
    >
      <SimpleForm justifyContent="center">
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.demandedintervention')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <TextInput
            source={"sheetNumber"}
            //label="N° de fiche d'intervention"
          />
          <ReferenceInput
            source={"vehicle[@id]"}
            reference="vehicles"
            perPage={1000}
            validate={[required("Champ obligatoire")]}
          >
            <AutocompleteInput
              validate={[required("Champ obligatoire")]}
              optionText={VehicleInfo}
            //label="Véhicule"
            />
          </ReferenceInput>
          <SelectInput
            source={"maintenanceType"}
            //label="Type de maintenance"
            validate={[required("Champ obligatoire")]}
            choices={[
              { id: "Corrective", name: translate('labels.corrective') },
              { id: "Preventive", name: translate('labels.preventive') },
              { id: "Incident", name: translate('labels.incident') },
            ]}
          />
          <TextInput source={"claim"} /*label="Réclamation"*/ />
          <DateInput source={"stopDate"} /*label="Date d'arrêt"*/ />
          <NumberInput source={"mileageMeter"} /*label="Compteur Km"*/ />
          <NumberInput source={"timeMeter"} /*label="Compteur horaire"*/ />
          <SelectInput
            source={"family"}
            //label="Famille"
            choices={[
              { id: "Chassis", name: translate('labels.Chassis') },
              { id: "Equipement", name: translate('labels.Equipement') },
              { id: "Chassis/Equipement", name: translate('labels.ChassisEquipement') },
            ]}
          />
          {/* <TextInput source={"operator"} label="Demandeur" /> */}
        </Box>

        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.exécutiondintervention')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"startDate"} /*label="Date début d'intervention"*/ />

          <TextInput source={"applicant"} /*label="Intervenant"*/ />
          <TextInput source={"defunctSystem"} /*label="Système défectueux"*/ />
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <AutocompleteArrayInput
            source={"nature"}
            //label="Nature d'intervention"
            choices={[
              { id: "Dressage", name: translate('labels.Dressage') },
              { id: "Réparation", name: translate('labels.Réparation') },
              { id: "Remplacement", name: translate('labels.Remplacement') },
              { id: "Contrôle", name: translate('labels.Contrôle') },
              { id: "Réglage", name: translate('labels.Réglage') },
              { id: "Serrage", name: translate('labels.Serrage') },
              { id: "Appoint", name: translate('labels.Appoint') },
            ]}
          />
          <AutocompleteArrayInput
            source={"organFamily"}
            //label="Famille d'organes"
            choices={[
              { id: "Circuit d'eau", name: translate('labels.Circuitdeau') },
              { id: "Vanne lixiviat", name: translate('labels.Vannelixiviat') },
              { id: "Roue", name: translate('labels.Roue') },
              { id: "Circuit AdBlue", name: translate('labels.CircuitAdBlue') },
              { id: "Moteur et c. annexe", name: translate('labels.Moteurcannexe') },
              { id: "Boîte à vitesse", name: translate('labels.Boitedevitesse') },
              { id: "Embrayage/Transmission", name: translate('labels.EmbrayageTransmission') },
              { id: "Direction", name: translate('labels.Direction') },
              { id: "Suspension", name: translate('labels.Suspension') },
              { id: "Système de freinage", name: translate('labels.Systèmedefreinage') },
              { id: "C. électrique", name: translate('labels.Circuitélectrique') },
              { id: "C. pneumatique", name: translate('labels.Circuitpneumatique') },
              { id: "Cabine", name: translate('labels.Cabine') },
              { id: "Prise de force", name: translate('labels.Prisedeforce') },
              { id: "Pompe", name: translate('labels.Pompe') },
              { id: "Distributeur", name: translate('labels.Distributeur') },
              { id: "Flexible/Raccord", name: translate('labels.FlexibleRaccord') },
              { id: "Vérins", name: translate('labels.Vérins') },
              { id: "Capteurs", name: translate('labels.Capteurs') },
              { id: "Acc. Hydr.", name: translate('labels.Accessoireshydraulique') },
              { id: "Balais", name: translate('labels.Balais') },
              { id: "Chaudronnerie", name: translate('labels.Chaudronnerie') },
              { id: "Autre", name: translate('labels.autre') },
            ]}
          />
        </Box>

        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.restitution')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput
            source={"returnToServiceDate"}
          //label="Date de remise en service"
          />
          <SelectInput
            source={"review"}
            //label="Avis sur l'intervention"
            choices={[
              { id: "Efficace", name: translate('labels.Efficace') },
              { id: "Inefficace", name: translate('labels.Inefficace') },
            ]}
          />
          <TextInput source={"remainingWork"} /*label="Travaux restants"*/ />
        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.articles')}
        </Typography>
        <SelectInput
          source={"type"}
          //label="Type d'intervention"
          choices={[
            { id: "Interne", name: translate('labels.Interne') },
            { id: "Externe", name: translate('labels.Externe') },
          ]}
          onChange={(e) => setInterventionType(e.target.value)}
        />
        {interventionType === "Interne" && (
          <TextInput source={"exitTicket"} /*label="Bon de sortie"*/ />
        )}
        {interventionType === "Externe" && (
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
            }}
            gap={2}
            width="100%"
          >
            <TextInput
              source={"supplier"}
            //label="Fournisseur"
            // choices={[
            //   { id: "SEFAMAR-MAN", name: "SEFAMAR-MAN" },
            //   { id: "ROCAMORA", name: "ROCAMORA" },
            //   { id: "PMEC", name: "PMEC" },
            //   { id: "TOTAL MAROC", name: "TOTAL MAROC" },
            //   { id: "ATLANTIC BENNE", name: "ATLANTIC BENNE" },
            //   { id: "EURAFRIC", name: "EURAFRIC" },
            //   { id: "CODETRAM", name: "CODETRAM" },
            //   { id: "FLEXI AUTO", name: "FLEXI AUTO" },
            //   { id: "FLEXI HYDRAULIQUE", name: "FLEXI HYDRAULIQUE" },
            //   { id: "VERAUTO", name: "VERAUTO" },
            //   { id: "SCANIA", name: "SCANIA" },
            //   { id: "BENNE MARREL", name: "BENNE MARREL" },
            //   { id: "DEPANAGE ELGHAZLANI", name: "DEPANAGE ELGHAZLANI" },
            //   { id: "EVINE", name: "EVINE" },
            //   { id: "AFRIQUIA", name: "AFRIQUIA" },
            //   { id: "GENESCO", name: "GENESCO" },
            //   { id: "3S", name: "3S" },
            //   { id: "NEW CATALOGUE", name: "NEW CATALOGUE" },
            //   { id: "HYDRAU TECHNIQUE", name: "HYDRAU TECHNIQUE" },
            //   { id: "HYUNDAI", name: "HYUNDAI" },
            //   { id: "SABATIER", name: "SABATIER" },
            // ]}
            />
            <TextInput source={"purchaseOrder"} /*label="N° bon de commande"*/ />
            <TextInput source={"quote"} /*label="N° devis"*/ />
            <TextInput source={"delivery"} /*label="N° bon de livraison"*/ />
            <DateInput source={"deliveryDate"} /*label="Date de livraison"*/ />
            <TextInput source={"cashVoucherNumber"} /*label="N° bon de caisse"*/ />
            {/* <TextInput source={"frequency"} label="Fréquence" /> */}
          </Box>
        )}
        {interventionType !== "" && (
          <ArrayInput source={"articles"}>
            <SimpleFormIterator disableReordering disableClear inline>
              <TextInput source={"designation"} /*label="Désignation"*/ />
              <TextInput source={"reference"} /*label="Référence"*/ />
              <NumberInput source={"unitPrice"} /*label="Prix unitaire (MAD)"*/ />
              <NumberInput source={"quantity"} /*label="Quantité"*/ />
              <NumberInput source={"totalPrice"} /*label="Prix total (MAD)"*/ />
              <SelectInput
                source={"state"}
                //label="État"
                choices={[
                  { id: "Neuf", name: translate('labels.Neuf') },
                  { id: "Bonne occasion", name: translate('labels.Bonneoccasion') },
                  { id: "Réparé", name: translate('labels.Réparé') },
                  { id: "Confectionné", name: translate('labels.Confectionné') },
                ]}
              />
              <SelectInput
                source={"family"}
                //label="Famille"
                choices={[
                  { id: "Chassis", name: translate('labels.Chassis') },
                  { id: "Equipement", name: translate('labels.Equipement') },
                ]}
              />
              <SelectInput
                source={"subfamily"}
                //label="Sous-famille"
                choices={choices}
              />
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </SimpleForm>
    </Edit>
  );
};

export const InterventionShow: React.FC<InterventionTitleProps> = (props) => {
  const interventionType = useRecordContext()?.type;
  const translate = useTranslate();

  return (
    <Show
      actions={<EditAction />}
      title={<InterventionTitle record={props.record} />}
      {...props}
    >
      <SimpleShowLayout>
        <Typography variant="h6" fontWeight="fontWeightBold" mt={2}>
          {translate('titles.demandedintervention')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <Labeled label="N° de fiche d'intervention">
            <TextField
              source={"sheetNumber"}
            //label="N° de fiche d'intervention"
            />
          </Labeled>
          <Labeled label="Véhicule">
            <Box display="flex">
              <TextField source={"vehicle.parkingNumber"} />
              &nbsp;-&nbsp;
              <TextField source={"vehicle.brand"} />
              &nbsp;
              <TextField source={"vehicle.model"} />
            </Box>
          </Labeled>
          <Labeled label="Type de maintenance">
            <TextField source={"maintenanceType"} /*label="Type de maintenance"*/ />
          </Labeled>
          <Labeled label="Réclamation">
            <TextField source={"claim"} /*label="Réclamation"*/ />
          </Labeled>
          <Labeled label="Date d'arrêt">
            <DateField
              locales="fr-FR"
              source={"stopDate"}
            //label="Date d'arrêt"
            />
          </Labeled>
          <Labeled label="Compteur Km">
            <NumberField source={"mileageMeter"} /*label="Compteur Km"*/ />
          </Labeled>
          <Labeled label="Compteur horaire">
            <NumberField source={"timeMeter"} /*label="Compteur horaire"*/ />
          </Labeled>
          <Labeled label="Famille">
            <TextField source={"family"} /*label="Famille"*/ />
          </Labeled>
          {/* <Labeled label="Demandeur">
            <TextField source="operator" label="Demandeur" />
          </Labeled> */}
        </Box>

        <Typography variant="h6" fontWeight="fontWeightBold" mt={2}>
          {translate('titles.exécutiondintervention')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <Labeled label="Début d'intervention">
            <DateField
              locales="fr-FR"
              source={"startDate"}
            //label="Date début d'intervention"
            />
          </Labeled>

          <Labeled label="Intervenant">
            <TextField source={"applicant"} /*label="Intervenant"*/ />
          </Labeled>
          <Labeled label="Système défectueux">
            <TextField source={"defunctSystem"} /*label="Système défectueux"*/ />
          </Labeled>
        </Box>
        <ArrayField source={"nature"} /*label="Nature d'intervention"*/>
          <SingleFieldList>
            <FunctionField
              render={(record: any) => (
                <ChipField record={{ name: record }} source={"name"} />
              )}
            />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source={"organFamily"} /*label="Famille d'organes"*/>
          <SingleFieldList>
            <FunctionField
              render={(record: any) => (
                <ChipField record={{ name: record }} source={"name"} />
              )}
            />
          </SingleFieldList>
        </ArrayField>
        {/* <SelectField source="nature"/> */}

        <Typography variant="h6" fontWeight="fontWeightBold" mt={2}>
          {translate('titles.restitution')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <Labeled label="Date de remise en service">
            <DateField
              locales="fr-FR"
              source={"returnToServiceDate"}
            //label="Date de remise en service"
            />
          </Labeled>
          <Labeled label="Avis sur l'intervention">
            <TextField source={"review"} /*label="Avis sur l'intervention"*/ />
          </Labeled>
          <Labeled label="Travaux restants">
            <TextField source={"remainingWork"} /*label="Travaux restants"*/ />
          </Labeled>
        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mt={2}>
          {translate('titles.articles')}
        </Typography>
        <Labeled label="Type d'intervention">
          <TextField source={"type"} /*label="Type d'intervention"*/ />
        </Labeled>
        {/* <Labeled label="Bon de sortie">
          <TextField source={"exitTicket"} label="Bon de sortie" />
        </Labeled> */}
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <Labeled label="Fournisseur">
            <TextField source={"supplier"} /*label="Fournisseur"*/ />
          </Labeled>
          <Labeled label="N° bon de commande">
            <TextField source={"purchaseOrder"} /*label="N° bon de commande"*/ />
          </Labeled>
          <Labeled label="N° devis">
            <TextField source={"quote"} /*label="N° devis"*/ />
          </Labeled>
          <Labeled label="N° bon de livraison">
            <TextField source={"delivery"} /*label="N° bon de livraison"*/ />
          </Labeled>
          <Labeled label="Date de livraison">
            <DateField
              locales="fr-FR"
              source={"deliveryDate"}
            //label="Date de livraison"
            />
          </Labeled>
          <Labeled label="N° bon de caisse">
            <TextField source={"cashVoucherNumber"} /*label="Date de livraison"*/ />
          </Labeled>
          {/* <Labeled label="Fréquence">
            <TextField source={"frequency"} label="Fréquence" />
          </Labeled> */}
        </Box>
        <ArrayField source={"articles"}>
          <Datagrid bulkActionButtons={false}>
            <TextField source={"designation"} /*label="Désignation"*/ />
            <TextField source={"reference"} /*label="Référence"*/ />
            <NumberField source={"unitPrice"} /*label="Prix unitaire (MAD)"*/ />
            <NumberField source={"quantity"} /*label="Quantité"*/ />
            <NumberField source={"totalPrice"} /*label="Prix total (MAD)"*/ />
            <TextField source={"state"} /*label="État"*/ />
            <TextField source={"family"} /*label="Famille"*/ />
            <TextField source={"subfamily"} /*label="Sous-famille"*/ />
          </Datagrid>
        </ArrayField>
        {/* <ArrayField source="articles" label="Famille d'organes">
        <SingleFieldList>
          <FunctionField
            render={(record: any) => (
              <ChipField record={{ name: record }} source="designation" />
            )}
          />
        </SingleFieldList>
      </ArrayField> */}
        <Labeled label="Coût total de l'intervention">
          <NumberField fontWeight="bold" source={"totalCost"} />
        </Labeled>
      </SimpleShowLayout>
    </Show>
  );
};
