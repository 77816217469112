import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { useTranslate } from 'react-admin';

ChartJS.register(ArcElement, Tooltip, Legend);

export const InterventionsTypeDoughnutCost = ({ data }: { data: any }) => {

  const translate = useTranslate();
  const textcost = translate('labels.costinterventions');

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: textcost,
      },
    },
  };

  const config = {
    labels: [
      translate('labels.corrective'),
      translate('labels.preventive'),
      translate('labels.incident')
    ],
    datasets: [
      {
        data: [
          data.correctiveTotalCost,
          data.preventiveTotalCost,
          data.incidentTotalCost,
        ],
        backgroundColor: [
          "rgba(103, 165, 135, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(252, 165, 165, 0.2)",
        ],
        borderColor: [
          "rgba(103, 165, 135, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(252, 165, 165, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return <Doughnut data={config} options={options} />;
};
