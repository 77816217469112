import { Box, Chip, Stack } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ImageField,
  ImageInput,
  Labeled,
  List,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const exporter = (ContainerReforms: any) => {
  const ContainerReformsForExport = ContainerReforms.map((ContainerReform: any) => {
    console.log("ContainerReform", ContainerReform);
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      originId,
      operator,
      site,
      updatedAt,
      ...ContainerReformForExport
    } = ContainerReform; // omit backlinks and author
    return ContainerReformForExport;
  });
  jsonexport(
    ContainerReformsForExport,
    {
      headers: [
        "date",
        "fuel",
        "blNumber",
        "quantity",
        "totalPrice",
        "createdAt",
      ],
      rename: [
        "Date de dotation",
        "Type de fluide",
        "N° bon de livraison",
        "Quantité",
        "Prix total (MAD)",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Bacs"); // download as 'posts.csv` file
    }
  );
};

const SuccessChip = () => (
  <Stack direction="row" alignItems="center" spacing={0}>
    <Chip color="success" size="small" /* label="Actif" */ />
  </Stack>
);
const ErrorChip = () => (
  <Stack direction="row" alignItems="center" spacing={0}>
    <Chip color="error" size="small" /* label="Inactif" */ />
  </Stack>
);
const containerInfo = (choice: { number: string, type: string }) =>
  `${choice.number} • ${choice.type}`;

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const ContainerReformListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      {/* <ExportButton /> */}
    </TopToolbar>
  );
};
const ContainerReformFilters = [
  <TextInput /* label="Recherche" */ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const ContainerReformList: React.FC = (props) => {
  const permissions = localStorage.getItem("permissions");
  const translate = useTranslate();
  return (
    <List
      actions={<ContainerReformListActions />}
      title={translate('titles.ContainerReform')}
      filters={ContainerReformFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <WrapperField label={translate('labels.containernumber')}>
          <TextField source={"container.number"} />
        </WrapperField>
        <WrapperField label={translate('labels.containertype')}>
          <TextField source={"container.type"} />
        </WrapperField>
        <SelectField source={"cause"} /* label={"Cause"} */ choices={[
          { id: "Casse", name: translate('labels.casse') },
          { id: "Vol", name: translate('labels.vol') },
          { id: "Incendie", name: translate('labels.incendie') },
          { id: "Autre", name: translate('labels.autre') },
        ]} />
        <DateField locales="fr-FR" source={"date"} /* label={"Date de réforme"} */ />

        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /* label="Modifier" */ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const ContainerReformCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/container-reforms/");
    refresh();
  };
  return (
    <Create title={translate('titles.AddReform')} mutationOptions={{ onSuccess }} {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} /* label="Date de réforme" */ />
          <ReferenceInput
            source={"container"}
            reference="containers"
            optionText={containerInfo}
            perPage={1000}>
            <AutocompleteInput /* label="Bac" */ optionText={containerInfo} />
          </ReferenceInput>
          <SelectInput
            source={"cause"}
            //label="Cause"
            choices={[
              { id: "Casse", name: translate('labels.casse') },
              { id: "Vol", name: translate('labels.vol') },
              { id: "Incendie", name: translate('labels.incendie') },
              { id: "Autre", name: translate('labels.autre') },
            ]}
          />
          <TextInput source={"lifecycle"} /* label="Durée de vie" */ />
        </Box>
        <ImageInput source={"images"} /* label="Images" */ accept="image/*" multiple>
          <ImageField source={"src"} />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

interface ContainerReformTitleProps {
  record: RaRecord;
}

const ContainerReformTitle: React.FC<ContainerReformTitleProps> = ({ record }) => {
  return <span>Réapprovisionnement {record ? `"${record.title}"` : ""}</span>;
};

export const ContainerReformEdit: React.FC<ContainerReformTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/container-reforms/");
    refresh();
  };
  return (
    <Edit title={translate('titles.EditReform')} mutationMode="pessimistic" {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} /* label="Date de réforme" */ />
          <ReferenceInput
            source={"container[@id]"}
            reference="containers"
            perPage={1000}>
            <AutocompleteInput /* label="Bac" */ optionText={containerInfo} />
          </ReferenceInput>
          <SelectInput
            source={"cause"}
            //label="Cause"
            choices={[
              { id: "Casse", name: translate('labels.casse') },
              { id: "Vol", name: translate('labels.vol') },
              { id: "Incendie", name: translate('labels.incendie') },
              { id: "Autre", name: translate('labels.autre') },
            ]}
          />
          <TextInput source={"lifecycle"} /* label="Durée de vie" */ />
        </Box>
        <ImageInput source={"images"} /* label="Images" */ accept="image/*" multiple>
          <ImageField source={"src"} />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const ContainerReformShow: React.FC<ContainerReformTitleProps> = (props) => {
  const translate = useTranslate();
  return (
    <Show actions={<EditAction />} title={translate('titles.showbin')} {...props}>
      <SimpleShowLayout>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <Labeled label="Numéro du bac">
            <TextField source={"container.number"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Type du bac">
            <TextField source={"container.type"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Cause">
            <TextField source={"cause"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Date de réforme">
            <DateField locales="fr-FR" source={"date"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Durée de vie">
            <TextField source={"lifecycle"} fontWeight="bold" />
          </Labeled>

        </Box>
        <Labeled /* label="Image" */ marginTop="16px" fontSize="16px">
          <ImageField
            source={"images"}
            src="contentUrl"
            //label="Image"
            sx={{
              padding: "0",
            }}
          />
        </Labeled>
      </SimpleShowLayout>
    </Show>
  );
};