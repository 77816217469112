import { Box } from "@mui/material";
import {
    Create,
    CreateButton,
    DatagridConfigurable,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    ExportButton,
    Labeled,
    List,
    NumberField,
    NumberInput,
    ReferenceInput,
    SelectColumnsButton,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    downloadCSV,
} from "react-admin";
import { Empty } from "../components/Empty";
import jsonexport from "jsonexport/dist";
import isGranted from "../services/security";

// Exporter for CSV
const exporter = (streets: any) => {
    const streetsForExport = streets.map((street: any) => {
        const { "@id": arobasId, "@type": arobasType, ...streetForExport } = street;
        return streetForExport;
    });

    jsonexport(
        streetsForExport,
        {
            headers: [
                "name",
                "type",
                "width",
                "length",
                "mechanicalSweepingFrequency",
                "mechanicalWashingFrequency",
                "manualSweepingFrequency",
                "activity",
                "accessibility",
                "tpl",
                "trafficDirection",
                "neighborhood",
            ],
            rename: [
                "Nom",
                "Type",
                "Largeur",
                "Longueur",
                "Fréquence de balayage mécanique",
                "Fréquence de lavage mécanique",
                "Fréquence de balayage manuel",
                "Activité",
                "Accessibilité",
                "TPL",
                "Sens de circulation",
                "Quartier",
            ],
        },
        (err: any, csv: string) => {
            downloadCSV(csv, "Rues");
        }
    );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
    choice.children;

const permissions = localStorage.getItem("permissions");

const StreetListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
        <ExportButton />
    </TopToolbar>
);

export const StreetList: React.FC = (props) => (
    <List
        actions={<StreetListActions />}
        empty={<Empty create />}
        exporter={exporter}
        {...props}
    >
        <DatagridConfigurable bulkActionButtons={false} rowClick="show">
            <TextField source="name" label="Nom" />
            <TextField source="type" label="Type" />
            <NumberField source="width" label="Largeur" />
            <NumberField source="length" label="Longueur" />
            <TextField
                source="mechanicalSweepingFrequency"
                label="Fréquence de balayage mécanique"
            />
            <TextField
                source="mechanicalWashingFrequency"
                label="Fréquence de lavage mécanique"
            />
            <TextField
                source="manualSweepingFrequency"
                label="Fréquence de balayage manuel"
            />
            <TextField source="activity" label="Activité" />
            <TextField source="accessibility" label="Accessibilité" />
            <TextField source="tpl" label="TPL" />
            <TextField source="trafficDirection" label="Sens de circulation" />
            <TextField source="neighborhood.name" label="Quartier" />
            {isGranted("ROLE_MANAGER", permissions) && (
                <FieldWrapper label="Actions">
                    <EditButton />
                    &nbsp;
                    <DeleteWithConfirmButton />
                </FieldWrapper>
            )}
        </DatagridConfigurable>
    </List>
);

export const StreetCreate: React.FC = (props) => (
    <Create {...props}>
        <SimpleForm>
            <Box
                display="grid"
                gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(5, 1fr)",
                }}
                gap={2}
                width="100%"
            >
                <TextInput source="name" label="Nom" />
                <SelectInput
                    source="type"
                    label="Type"
                    choices={[
                        { id: "Principal", name: "Principal" },
                        { id: "Secondaire", name: "Secondaire" },
                        { id: "Tertiaire", name: "Tertiaire" },
                        { id: "Piste", name: "Piste" },
                    ]}
                />
                <NumberInput source="width" label="Largeur" />
                <NumberInput source="length" label="Longueur" />
                <SelectInput
                    source="mechanicalSweepingFrequency"
                    label="Fréquence de balayage mécanique"
                    choices={[
                        { id: "1/30", name: "1/30" },
                        { id: "1/15", name: "1/15" },
                        { id: "1/7", name: "1/7" },
                        { id: "2/7", name: "2/7" },
                        { id: "3/7", name: "3/7" },
                        { id: "7/7", name: "7/7" },
                    ]}
                />
                <SelectInput
                    source="mechanicalWashingFrequency"
                    label="Fréquence de lavage mécanique"
                    choices={[
                        { id: "1/30", name: "1/30" },
                        { id: "1/15", name: "1/15" },
                        { id: "1/7", name: "1/7" },
                        { id: "2/7", name: "2/7" },
                        { id: "3/7", name: "3/7" },
                        { id: "7/7", name: "7/7" },
                    ]}
                />
                <SelectInput
                    source="manualSweepingFrequency"
                    label="Fréquence de balayage manuel"
                    choices={[
                        { id: "21/7", name: "21/7" },
                        { id: "14/7", name: "14/7" },
                        { id: "1/7", name: "1/7" },
                        { id: "2/7", name: "2/7" },
                        { id: "3/7", name: "3/7" },
                        { id: "7/7", name: "7/7" },
                    ]}
                />
                <SelectInput
                    source="activity"
                    label="Activité"
                    choices={[
                        { id: "Habitation", name: "Habitation" },
                        { id: "Commercial", name: "Commercial" },
                        { id: "Mixte", name: "Mixte" },
                    ]}
                />
                <TextInput source="accessibility" label="Accessibilité" />
                <TextInput source="tpl" label="TPL" />
                <TextInput source="trafficDirection" label="Sens de circulation" />
                <ReferenceInput source="neighborhood" reference="neighborhoods">
                    <SelectInput optionText="name" label="Quartier" />
                </ReferenceInput>
            </Box>
        </SimpleForm>
    </Create>
);

export const StreetEdit: React.FC = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <Box
                display="grid"
                gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(5, 1fr)",
                }}
                gap={2}
                width="100%"
            >
                <TextInput source="name" label="Nom" />
                <SelectInput
                    source="type"
                    label="Type"
                    choices={[
                        { id: "Principal", name: "Principal" },
                        { id: "Secondaire", name: "Secondaire" },
                        { id: "Tertiaire", name: "Tertiaire" },
                        { id: "Piste", name: "Piste" },
                    ]}
                />
                <NumberInput source="width" label="Largeur" />
                <NumberInput source="length" label="Longueur" />
                <SelectInput
                    source="mechanicalSweepingFrequency"
                    label="Fréquence de balayage mécanique"
                    choices={[
                        { id: "1/30", name: "1/30" },
                        { id: "1/15", name: "1/15" },
                        { id: "1/7", name: "1/7" },
                        { id: "2/7", name: "2/7" },
                        { id: "3/7", name: "3/7" },
                        { id: "7/7", name: "7/7" },
                    ]}
                />
                <SelectInput
                    source="mechanicalWashingFrequency"
                    label="Fréquence de lavage mécanique"
                    choices={[
                        { id: "1/30", name: "1/30" },
                        { id: "1/15", name: "1/15" },
                        { id: "1/7", name: "1/7" },
                        { id: "2/7", name: "2/7" },
                        { id: "3/7", name: "3/7" },
                        { id: "7/7", name: "7/7" },
                    ]}
                />
                <SelectInput
                    source="manualSweepingFrequency"
                    label="Fréquence de balayage manuel"
                    choices={[
                        { id: "21/7", name: "21/7" },
                        { id: "14/7", name: "14/7" },
                        { id: "1/7", name: "1/7" },
                        { id: "2/7", name: "2/7" },
                        { id: "3/7", name: "3/7" },
                        { id: "7/7", name: "7/7" },
                    ]}
                />
                <SelectInput
                    source="activity"
                    label="Activité"
                    choices={[
                        { id: "Habitation", name: "Habitation" },
                        { id: "Commercial", name: "Commercial" },
                        { id: "Mixte", name: "Mixte" },
                    ]}
                />
                <TextInput source="accessibility" label="Accessibilité" />
                <TextInput source="tpl" label="TPL" />
                <TextInput source="trafficDirection" label="Sens de circulation" />
                <ReferenceInput source="neighborhood" reference="neighborhoods">
                    <SelectInput optionText="name" label="Quartier" />
                </ReferenceInput>
            </Box>
        </SimpleForm>
    </Edit>
);

export const StreetShow: React.FC = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <Box
                display="grid"
                gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(5, 1fr)",
                }}
                gap={2}
                width="100%"
            >
                <Labeled><TextField source="name" label="Nom" /></Labeled>
                <Labeled><TextField source="type" label="Type" /></Labeled>
                <Labeled><NumberField source="width" label="Largeur" /></Labeled>
                <Labeled><NumberField source="length" label="Longueur" /></Labeled>
                <Labeled><TextField
                    source="mechanicalSweepingFrequency"
                    label="Fréquence de balayage mécanique"
                /></Labeled>
                <Labeled><TextField
                    source="mechanicalWashingFrequency"
                    label="Fréquence de lavage mécanique"
                /></Labeled>
                <Labeled><TextField
                    source="manualSweepingFrequency"
                    label="Fréquence de balayage manuel"
                /></Labeled>
                <Labeled><TextField source="activity" label="Activité" /></Labeled>
                <Labeled><TextField source="accessibility" label="Accessibilité" /></Labeled>
                <Labeled><TextField source="tpl" label="TPL" /></Labeled>
                <Labeled><TextField source="trafficDirection" label="Sens de circulation" /></Labeled>
                <Labeled><TextField source="neighborhood.name" label="Quartier" /></Labeled>
            </Box>
        </SimpleShowLayout>
    </Show>
);
