import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  Labeled,
  List,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const exporter = (fundings: any) => {
  const fundingsForExport = fundings.map((funding: any) => {
    console.log("funding", funding);
    const {
      "@id": arobasVehicleId,
      "@type": arobasVehicleType,
      createdAt,
      site: vehicleSite,
      operator: vehicleOperator,
      updatedAt: vehicleUpdatedAt,
      ...vehicleForExport
    } = funding.vehicle;
    funding.vehicle = vehicleForExport;
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      originId,
      site,
      updatedAt,
      ...fundingForExport
    } = funding; // omit backlinks and author
    return fundingForExport;
  });
  jsonexport(
    fundingsForExport,
    {
      headers: [
        "date",
        "vehicle.parkingNumber",
        "vehicle.brand",
        "vehicle.model",
        "refundAmount",
        "createdAt",
      ],
      rename: [
        "Date de paiement",
        "Numéro de parc",
        "Marque",
        "Modèle",
        "Montant de remboursement (MAD)",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Financements"); // download as 'posts.csv` file
    }
  );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;
const VehicleInfo = (choice: { parkingNumber: string }) =>
  `${choice.parkingNumber}`;

const FundingListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const FundingFilters = [
  <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const FundingList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<FundingListActions />}
      title={translate('titles.financement')}
      filters={FundingFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField locales="fr-FR"source={"date"} /*label={"Date de paiement"}*/ />
        <WrapperField source="vehicle" /*label="Véhicule"*/ sortBy={"vehicle.parkingNumber"}>
          <TextField source={"vehicle.parkingNumber"} />
        </WrapperField>
        <TextField source={"refundAmount"} /*label={"Montant de remboursement"}*/ />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const FundingCreate: React.FC = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/vehicle-funding-histories/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.addfunding')}
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateInput source={"date"} /*label="Date de paiement"*/ />
          <ReferenceInput source={"vehicle"} reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText={VehicleInfo} /*label="Véhicule"*/ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <NumberInput source={"refundAmount"} /*label="Montant de remboursement"*/ />
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

interface FundingTitleProps {
  record: RaRecord;
}

const FundingTitle: React.FC<FundingTitleProps> = ({ record }) => {
  return <span>Funding {record ? `"${record.title}"` : ""}</span>;
};

export const FundingEdit: React.FC<FundingTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`L'élément a bien été modifié`);
    redirect("/vehicle-funding-histories/");
    refresh();
  };
  return (
    <Edit
      title={<FundingTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateTimeInput source={"date"} /*label="Date de paiement"*/ />
          <ReferenceInput source={"vehicle[@id]"} reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText={VehicleInfo} /*label="Véhicule"*/ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <NumberInput source={"refundAmount"} /*label="Montant de remboursement"*/ />
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export const FundingShow: React.FC<FundingTitleProps> = (props) => (
  <Show
    actions={<EditAction />}
    title={<FundingTitle record={props.record} />}
    {...props}
    sx={{ fontSize: "20px" }}>
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%">
        <Labeled label="Date de paiement">
          <DateField
          locales="fr-FR"
            showTime
            source={"date"}
            //label="Date de paiement"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Véhicule">
          <Box display="flex">
            <TextField source={"vehicle.parkingNumber"} fontWeight="bold" />
            &nbsp;-&nbsp;
            <TextField source={"vehicle.brand"} fontWeight="bold" />
            &nbsp;
            <TextField source={"vehicle.model"} fontWeight="bold" />
          </Box>
        </Labeled>
        <Labeled label="Montant de remboursement">
          <TextField
            source={"refundAmount"}
            //label="Montant de remboursement"
            fontWeight="bold"
          />
        </Labeled>
      </Box>
      <Labeled label="Fichiers" marginTop="16px">
        <FileField
          source={"attachments"}
          src="contentUrl"
          title="title"
          target="_blank"
        />
      </Labeled>
    </SimpleShowLayout>
  </Show>
);
