import { Box } from "@mui/material";
import {
    Create,
    CreateButton,
    DatagridConfigurable,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    ExportButton,
    List,
    NumberField,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    SelectColumnsButton,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    Labeled,
    downloadCSV,
    DateField,
    WrapperField,
    useRedirect,
    useRefresh,
    TimeInput,
} from "react-admin";
import { Empty } from "../components/Empty";
import jsonexport from "jsonexport/dist";
import isGranted from "../services/security";

// Filter functions
const filterToQuery = (searchText: string) => ({ query: `${searchText}` });
const VehicleInfo = (choice: { parkingNumber: string }) => `${choice.parkingNumber}`;
const fullName = (employee: { firstName: string, lastName: string }) => `${employee.firstName} ${employee.lastName}`;

// Exporter for CSV
const exporter = (collections: any) => {
    const collectionsForExport = collections.map((collection: any) => {
        const { "@id": arobasId, "@type": arobasType, ...collectionForExport } = collection;
        return collectionForExport;
    });

    jsonexport(
        collectionsForExport,
        {
            headers: [
                "sectorName",
                "reference",
                "sectorChief.firstName",
                "sectorChief.lastName",
                "vehicle.parkingNumber",
                "startTime",
                "endTime",
                "serviceDuration",
                "collectionMode",
                "tonnage",
                "service",
                "collectionDistance",
                "dischargeDistance",
                "pavNumber1",
                "pavNumber2",
                "pavNumber3",
                "bavNumber",
                "longitude",
                "latitude",
            ],
            rename: [
                "Nom du secteur",
                "Référence",
                "Prénom du chef de secteur",
                "Nom du chef de secteur",
                "Véhicule",
                "Heure de début",
                "Heure de fin",
                "Durée du service",
                "Mode de collecte",
                "Tonnage",
                "Service",
                "Distance de collecte",
                "Distance de décharge",
                "Nombre PAV 1",
                "Nombre PAV 2",
                "Nombre PAV 3",
                "Nombre BAV",
                "Longitude",
                "Latitude",
            ],
        },
        (err: any, csv: string) => {
            downloadCSV(csv, "Collecte");
        }
    );
};

const FieldWrapper = (choice: { children: any; label: string }) => choice.children;

const permissions = localStorage.getItem("permissions");

const CollectingListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
        <ExportButton />
    </TopToolbar>
);

export const CollectingList: React.FC = (props) => (
    <List
        actions={<CollectingListActions />}
        empty={<Empty create />}
        exporter={exporter}
        {...props}
    >
        <DatagridConfigurable bulkActionButtons={false} rowClick="show">
            <TextField source="sectorName" label="Nom du secteur" />
            <TextField source="reference" label="Référence" />
            <WrapperField source="sectorChief" label="Chef de secteur">
                <TextField source="sectorChief.firstName" /> &nbsp;
                <TextField source="sectorChief.lastName" />
            </WrapperField>
            <TextField source="vehicle.parkingNumber" label="Véhicule" />
            <DateField source="startTime" label="Début" showTime showDate={false} />
            <DateField source="endTime" label="Fin" showTime showDate={false} />
            <NumberField source="serviceDuration" label="Durée" />
            <TextField source="collectionMode" label="Mode collecte" />
            <TextField source="service" label="Service" />
            {isGranted("ROLE_MANAGER", permissions) && (
                <FieldWrapper label="Actions">
                    <EditButton />
                    &nbsp;
                    <DeleteWithConfirmButton />
                </FieldWrapper>
            )}
        </DatagridConfigurable>
    </List>
);

export const CollectingCreate: React.FC = (props) => {
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        redirect("/collectings/");
        refresh();
    };
    return (
        <Create mutationOptions={{ onSuccess }} {...props}>
            <SimpleForm>
                <Box
                    display="grid"
                    gridTemplateColumns={{
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(3, 1fr)",
                        lg: "repeat(4, 1fr)",
                        xl: "repeat(5, 1fr)",
                    }}
                    gap={2}
                    width="100%"
                >
                    <TextInput source="sectorName" label="Nom du secteur" />
                    <TextInput source="reference" label="Référence" />
                    <ReferenceInput
                        source={"sectorChief"}
                        reference="employees"
                        perPage={1000}
                    >
                        <AutocompleteInput optionText={fullName} filterToQuery={filterToQuery} label='Chef de secteur' />
                    </ReferenceInput>
                    <ReferenceInput
                        source={"vehicle"}
                        reference="vehicles"
                        perPage={1000}
                    >
                        <AutocompleteInput
                            optionText={VehicleInfo}
                            filterToQuery={filterToQuery}
                            label='Véhicule' />
                    </ReferenceInput>
                    <TimeInput source="startTime" label="Heure de début" />
                    <TimeInput source="endTime" label="Heure de fin" />
                    <NumberInput source="serviceDuration" label="Durée du service" />
                    <SelectInput
                        source="collectionMode"
                        label="Mode de collecte"
                        choices={[
                            { id: "P&P", name: "P&P" },
                            { id: "villa", name: "villa" },
                            { id: "Ind", name: "Ind" },
                            { id: "PAV", name: "PAV" },
                        ]}
                    />
                    <NumberInput source="tonnage" label="Tonnage" />
                    <TextInput source="service" label="Service" />
                    <NumberInput source="collectionDistance" label="Distance de collecte" />
                    <NumberInput source="dischargeDistance" label="Distance de décharge" />
                    <NumberInput source="pavNumber1" label="Nombre PAV 1" />
                    <NumberInput source="pavNumber2" label="Nombre PAV 2" />
                    <NumberInput source="pavNumber3" label="Nombre PAV 3" />
                    <NumberInput source="bavNumber" label="Nombre BAV" />
                    <NumberInput source="longitude" label="Longitude" />
                    <NumberInput source="latitude" label="Latitude" />
                </Box>
            </SimpleForm>
        </Create>
    );
};

export const CollectingEdit: React.FC = (props) => {
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        redirect("/collectings/");
        refresh();
    };
    return (
        <Edit mutationMode="pessimistic" mutationOptions={{ onSuccess }} {...props}>
        <SimpleForm>
            <Box
                display="grid"
                gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(5, 1fr)",
                }}
                gap={2}
                width="100%"
            >
                <TextInput source="sectorName" label="Nom du secteur" />
                <TextInput source="reference" label="Référence" />
                <ReferenceInput
                    source={"sectorChief"}
                    reference="employees"
                    perPage={1000}
                >
                    <AutocompleteInput optionText={fullName} filterToQuery={filterToQuery} label='Chef de secteur' />
                </ReferenceInput>
                <ReferenceInput
                    source={"vehicle"}
                    reference="vehicles"
                    perPage={1000}
                >
                    <AutocompleteInput
                        optionText={VehicleInfo}
                        filterToQuery={filterToQuery}
                        label='Véhicule' />
                </ReferenceInput>
                <TimeInput source="startTime" label="Heure de début" />
                <TimeInput source="endTime" label="Heure de fin" />
                <NumberInput source="serviceDuration" label="Durée du service" />
                <SelectInput
                    source="collectionMode"
                    label="Mode de collecte"
                    choices={[
                        { id: "P&P", name: "P&P" },
                        { id: "villa", name: "villa" },
                        { id: "Ind", name: "Ind" },
                        { id: "PAV", name: "PAV" },
                    ]}
                />
                <NumberInput source="tonnage" label="Tonnage" />
                <TextInput source="service" label="Service" />
                <NumberInput source="collectionDistance" label="Distance de collecte" />
                <NumberInput source="dischargeDistance" label="Distance de décharge" />
                <NumberInput source="pavNumber1" label="Nombre PAV 1" />
                <NumberInput source="pavNumber2" label="Nombre PAV 2" />
                <NumberInput source="pavNumber3" label="Nombre PAV 3" />
                <NumberInput source="bavNumber" label="Nombre BAV" />
                <NumberInput source="longitude" label="Longitude" />
                <NumberInput source="latitude" label="Latitude" />
            </Box>
        </SimpleForm>
    </Edit>
);};

export const CollectingShow: React.FC = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <Box
                display="grid"
                gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(5, 1fr)",
                }}
                gap={2}
                width="100%"
            >
                <Labeled><TextField source="sectorName" label="Nom du secteur" /></Labeled>
                <Labeled><TextField source="reference" label="Référence" /></Labeled>
                <Labeled><TextField source="sectorChief.firstName" label="Prénom du chef de secteur" /></Labeled>
                <Labeled><TextField source="sectorChief.lastName" label="Nom du chef de secteur" /></Labeled>
                <Labeled><TextField source="vehicle.parkingNumber" label="Véhicule" /></Labeled>
                <Labeled><TextField source="startTime" label="Heure de début" /></Labeled>
                <Labeled><TextField source="endTime" label="Heure de fin" /></Labeled>
                <Labeled><NumberField source="serviceDuration" label="Durée du service" /></Labeled>
                <Labeled><TextField source="collectionMode" label="Mode de collecte" /></Labeled>
                <Labeled><NumberField source="tonnage" label="Tonnage" /></Labeled>
                <Labeled><TextField source="service" label="Service" /></Labeled>
                <Labeled><NumberField source="collectionDistance" label="Distance de collecte" /></Labeled>
                <Labeled><NumberField source="dischargeDistance" label="Distance de décharge" /></Labeled>
                <Labeled><NumberField source="pavNumber1" label="Nombre PAV 1" /></Labeled>
                <Labeled><NumberField source="pavNumber2" label="Nombre PAV 2" /></Labeled>
                <Labeled><NumberField source="pavNumber3" label="Nombre PAV 3" /></Labeled>
                <Labeled><NumberField source="bavNumber" label="Nombre BAV" /></Labeled>
                <Labeled><NumberField source="longitude" label="Longitude" /></Labeled>
                <Labeled><NumberField source="latitude" label="Latitude" /></Labeled>
            </Box>
        </SimpleShowLayout>
    </Show>
);
