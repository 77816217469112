import entrypoint from "../config/params";

export function uploadImage(image) {
  const body = new FormData();
  body.append("file", image);

  return fetch(`${entrypoint}/images`, {
    body,
    headers: new Headers({
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    }),
    method: "POST",
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

export function uploadDocument(document) {
  console.log("document", document);

  const body = new FormData();

  body.append("file", document);

  return fetch(`${entrypoint}/attachments`, {
    body,
    headers: new Headers({
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    }),
    method: "POST",
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}
