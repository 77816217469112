import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  Labeled,
  List,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TimeInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRedirect,
  required,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const exporter = (presences: any) => {
  const presencesForExport = presences.map((presence: any) => {
    if (presence.dueDate) {
      presence.dueDate = presence.dueDate.slice(0, 10);
    };
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      createdAt,
      ...presenceForExport
    } = presence;
    return presenceForExport;
  });
  jsonexport(
    presencesForExport,
    {
      headers: [
        "employee['firstName']",
        "employee['lastName']",
        "date",
        "situation"
      ],
      rename: [
        "Prenom",
        "Nom",
        "Date",
        "Situation"
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Presence"); // download as 'posts.csv` file
    }
  );
};

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const fullName = (choice: { firstName: string; lastName: string }) =>
  `${choice.firstName} ${choice.lastName}`;

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const PresenceListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const PresenceFilters = [
  <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const PresenceList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<PresenceListActions />}
      filters={PresenceFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField source={"date"} /*label="Matricule"*/ />
        <WrapperField source="employee" sortBy="employee.lastName">
          <TextField source={"employee.lastName"} />
          &nbsp;
          <TextField source={"employee.firstName"} />
        </WrapperField>
        <SelectField
          source={"situation"}
          // label="Situation"
          choices={[
            { id: "ANA", name: translate('ANA') },
            { id: "CDI", name: translate('CDI') },
            { id: "AA", name: translate('AA') },
            { id: "CM", name: translate('CM') },
            { id: "AT", name: translate('AT') },
            { id: "R", name: translate('R') },
            { id: "CN", name: translate('CN') },
            { id: "CD", name: translate('CD') },

          ]}
        />


        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const PresenceCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/presences/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.addpresence')}
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} validate={[required("Champ obligatoire")]} />
          <ReferenceInput
            source={"employee"}
            reference="employees"
            perPage={1000}
           
          >
            <AutocompleteInput optionText={fullName} /* label="Conducteur" */ filterToQuery={filterToQuery}  validate={[required("Champ obligatoire")]} />
          </ReferenceInput>
          <SelectInput
            source={"situation"}
            // label="Situation"
            choices={[
              { id: "ANA", name: translate('ANA') },
              { id: "CDI", name: translate('CDI') },
              { id: "AA", name: translate('AA') },
              { id: "CM", name: translate('CM') },
              { id: "AT", name: translate('AT') },
              { id: "R", name: translate('R') },
              { id: "CN", name: translate('CN') },
              { id: "CD", name: translate('CD') },

            ]}
          />

        </Box>

        <FileInput
          source={"timeSheet"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} />
        </FileInput>

        <FileInput
          source={"justifications"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>

      </SimpleForm>
    </Create>
  );
};

interface PresenceTitleProps {
  record: RaRecord;
}

const PresenceTitle: React.FC<PresenceTitleProps> = ({ record }) => {
  return <span>Contrôle {record ? `"${record.title}"` : ""}</span>;
};

export const PresenceEdit: React.FC<PresenceTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/presences/");
    refresh();
  };
  return (
    <Edit
      title={<PresenceTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} /*label="date"*/ />
          <ReferenceInput
            source={"employee"}
            reference="employees"
            perPage={1000}
          >
            <AutocompleteInput optionText={fullName} /* label="Conducteur" */ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <SelectInput
            source={"situation"}
            // label="Situation"
            choices={[
              { id: "ANA", name: translate('ANA') },
              { id: "CDI", name: translate('CDI') },
              { id: "AA", name: translate('AA') },
              { id: "CM", name: translate('CM') },
              { id: "AT", name: translate('AT') },
              { id: "R", name: translate('R') },
              { id: "CN", name: translate('CN') },
              { id: "CD", name: translate('CD') },

            ]}
          />

        </Box>

        <FileInput
          source={"timeSheet"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>

        <FileInput
          source={"justifications"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>

      </SimpleForm>
    </Edit>
  );
};

export const PresenceShow: React.FC<PresenceTitleProps> = (props) => (
  <Show
    actions={<EditAction />}
    title={<PresenceTitle record={props.record} />}
    sx={{ fontSize: "20px" }}
    {...props}>
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%"
      >

        <Labeled label="Presence Date">
          <DateField source={"date"} /*label="Presence Date"*/ fontWeight="bold" />
        </Labeled>
        <Labeled label="Employee">
            <Box display={"flex"}>
            <TextField source={"employee.lastName"} />
            &nbsp;
            <TextField source={"employee.firstName"} />
            </Box>
        </Labeled>
        <Labeled label="Situation">
          <TextField source={"situation"} /*label="Situation"*/ fontWeight="bold" />
        </Labeled>

      </Box>

      <Labeled label="justifications" marginTop="16px">
        <FileField
          source={"justifications"}
          src="contentUrl"
          title="title"
          target="_blank"
        />
      </Labeled>
    </SimpleShowLayout>
  </Show>
);
