import * as React from "react";
// import { shadows } from "@mui/system";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import { Loading, useAuthenticated } from "react-admin";
import { ReformDoughnout } from "../components/charts/ReformDoughnout";
import entrypoint from "../config/params";
import { useTranslate } from 'react-admin';

interface DashboardStat {
  containerRates: ContainerRate;
}
interface ContainerRate {
  casse: number;
  vol: number;
  incendie: number;
  autre: number;
  totalContainers: number;
  totalReforms: number;
  totalMaintenances: number;
}

const DashboardContainers = () => {
  useAuthenticated();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<DashboardStat | undefined>(undefined);
  const translate = useTranslate();

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    if (token === null) {
      return null;
    }
    const { data } = await axios.get<DashboardStat>(
      `${entrypoint}/container-dashboard-stats`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setData(data);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (data) {
    console.log("data-stats", data);
    return (
      <Box>
        <Box sx={{ display: "flex" }} gap="24px" margin="24px">
          <Box sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }} >
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 2,
                borderRadius: "12px",
                padding: "24px",
              }}
            >
              <Typography sx={{ textAlign: "start", mb: "16px" }}>
                {translate('titles.bacs')}
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ textAlign: "start" }}
              >
                {data?.containerRates?.totalContainers}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 2,
                borderRadius: "12px",
                padding: "24px",
              }}
            >
              <Typography sx={{ textAlign: "start", mb: "16px" }}>
                {translate('titles.reforms')}
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ textAlign: "start" }}
              >
                {data?.containerRates?.totalReforms}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 2,
                borderRadius: "12px",
                padding: "24px",
              }}
            >
              <Typography sx={{ textAlign: "start", mb: "16px" }}>
                {translate('titles.maintenances')}
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ textAlign: "start" }}
              >
                {data?.containerRates?.totalMaintenances}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              bgcolor: "background.paper",
              boxShadow: 2,
              borderRadius: "12px",
              padding: "24px",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              {translate('titles.typereforms')}
            </Typography>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Box sx={{ margin: "auto" }}>
                <ReformDoughnout
                  casse={data?.containerRates?.casse}
                  vol={data.containerRates?.vol}
                  incendie={data.containerRates?.incendie}
                  autre={data.containerRates?.autre}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  return null;
};

export default DashboardContainers;
