import { Box, Chip } from "@mui/material";
import jsonexport from "jsonexport/dist";
import React from "react";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  FunctionField,
  Labeled,
  List,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const exporter = (controls: any) => {
  const controlsForExport = controls.map((control: any) => {
    console.log("control1", control);
    console.log("control1", control);
    const {
      "@id": arobasVehicleId,
      "@type": arobasVehicleType,
      createdAt,
      operator: vehicleOperator,
      site: vehicleSite,
      updatedAt: vehicleUpdatedAt,
      ...vehicleForExport
    } = control.vehicle;
    control.vehicle = vehicleForExport;
    const {
      "@id": arobasId,
      "@type": arobasType,
      id,
      originId,
      attachments,
      operator,
      site,
      updatedAt,
      ...controlForExport
    } = control;
    return controlForExport;
  });
  jsonexport(
    controlsForExport,
    {
      headers: [
        "date",
        "vehicle.parkingNumber",
        "vehicle.brand",
        "vehicle.model",
        "designation",
        "createdAt",
      ],
      rename: [
        "Date échéance",
        "Numéro de parc",
        "Marque",
        "Modèle",
        "Type de contrôle",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Contrôles"); // download as 'posts.csv` file
    }
  );
};
const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;
const VehicleInfo = (choice: {
  parkingNumber: string;
}) => `${choice.parkingNumber}`;

const ControlListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {/* <FilterButton /> */}
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};

export const ControlList: React.FC = (props) => {

  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");

  const ControlFilters = [
    <TextInput source={"query"} alwaysOn />,
    <SelectInput
      source={"status"}
      alwaysOn
      choices={[
        { id: "yes", name: translate('labels.effectue') },
        { id: "todo", name: translate('labels.avenir') },
        { id: "expired", name: translate('labels.enretard') },
        { id: "no", name: translate('labels.noneffectue') },
      ]}
      resettable
    />,
  ];

  return (
    <List
      actions={<ControlListActions />}
      title={translate('labels.controls')}
      filters={ControlFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField locales="fr-FR" source={"date"} />
        <WrapperField source="vehicle" sortBy={"vehicle.parkingNumber"}>
          <TextField source={"vehicle.parkingNumber"} />
        </WrapperField>
        <SelectField source={"designation"} choices={[
          { id: "Visite technique", name: translate('labels.visitetechnique') },
          { id: "Visite tachygraphe", name: translate('labels.visitetachygraphe') },
          { id: "Extincteurs", name: translate('labels.extincteurs') },
          { id: "Autre", name: translate('labels.autre') },
        ]} />
        <FunctionField
          source={"status"}
          render={(record: any) => {
            if (record.status === "yes") return <Chip color="success" size="small" label={translate('labels.effectue')} />;
            else if (record.status === "expired") return <Chip color="error" size="small" label={translate('labels.enretard')} />;
            else if (record.status === "no") return <Chip size="small" label={translate('labels.noneffectue')} />;
            else if (record.status === "todo") return <Chip color="warning" size="small" label={translate('labels.avenir')} />;
            else return <></>;
          }}
        />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const ControlCreate: React.FC = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const defaultValue = () => ({ status: 'no' });

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/vehicle-control-histories/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.addcontrol')}
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center" defaultValues={defaultValue}>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateTimeInput source={"date"} /*label="Date échéance"*/ />
          <ReferenceInput source={"vehicle"} reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText={VehicleInfo} /*label="Véhicule"*/ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <SelectInput
            source={"designation"}
            //label="Type de contrôle"
            choices={[
              { id: "Visite technique", name: translate('labels.visitetechnique') },
              { id: "Visite tachygraphe", name: translate('labels.visitetachygraphe') },
              { id: "Extincteurs", name: translate('labels.extincteurs') },
              { id: "Autre", name: translate('labels.autre') },
            ]}
          />
          <SelectInput
            source={"status"}
            //label="Effectué"
            choices={[
              { id: "yes", name: translate('labels.effectue') },
              { id: "no", name: translate('labels.non-effectue') },
            ]}
          />
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

interface ControlTitleProps {
  record: RaRecord;
}

const ControlTitle: React.FC<ControlTitleProps> = ({ record }) => {
  return <span>Contrôle {record ? `"${record.title}"` : ""}</span>;
};

export const ControlEdit: React.FC<ControlTitleProps> = (props) => {
  const translate = useTranslate();
  return (
    <Edit
      title={<ControlTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateTimeInput source={"date"} /*label="Date échéance"*/ />
          <ReferenceInput source={"vehicle[@id]"} reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText={VehicleInfo} /*label="Véhicule"*/ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <SelectInput
            source={"designation"}
            //label="Type de contrôle"
            choices={[
              { id: "Visite technique", name: translate('labels.visitetechnique') },
              { id: "Visite tachygraphe", name: translate('labels.visitetachygraphe') },
              { id: "Extincteurs", name: translate('labels.extincteurs') },
              { id: "Autre", name: translate('labels.autre') },
            ]}
          />
          <SelectInput
            source={"status"}
            //label="Effectué"
            choices={[
              { id: "yes", name: translate('labels.effectue') },
              { id: "no", name: translate('labels.non-effectue') },
            ]}
          />
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export const ControlShow: React.FC<ControlTitleProps> = (props) => (
  <Show actions={<EditAction />}
    title={<ControlTitle record={props.record} />}
    {...props}
    sx={{ fontSize: "20px" }}>
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
        }}
        gap={2}
        width="100%">
        <Labeled label="Date échéance">
          <DateField
            locales="fr-FR"
            showTime
            source={"date"}
            //label="Date échéance"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Véhicule">
          <Box display="flex" fontWeight="bold">
            <TextField source={"vehicle.parkingNumber"} fontWeight="bold" />
            &nbsp;-&nbsp;
            <TextField source={"vehicle.brand"} fontWeight="bold" />
            &nbsp;
            <TextField source={"vehicle.model"} fontWeight="bold" />
          </Box>
        </Labeled>
        <Labeled label="Type de contrôle">
          <TextField
            source={"designation"}
            //label="Type de contrôle"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Effectué">
          <TextField
            source={"status"}
            //label="Effectué"
            fontWeight="bold"
          />
        </Labeled>
      </Box>
      <Labeled label="Fichiers" marginTop="16px">
        {/* <ArrayField source="attachments">
            <UrlField source="contentUrl" label="Fichier" />
</ArrayField> */}
        <FileField
          source={"attachments"}
          src="contentUrl"
          title="title"
          target="_blank"
        />
      </Labeled>
    </SimpleShowLayout>
  </Show>
);
