import { Box, Chip } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  FunctionField,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const exporter = (taxes: any) => {
  const taxesForExport = taxes.map((taxe: any) => {
    const {
      "@id": arobasVehicleId,
      "@type": arobasVehicleType,
      createdAt,
      site: vehicleSite,
      operator: vehicleOperator,
      updatedAt: vehicleUpdatedAt,
      ...vehicleForExport
    } = taxe.vehicle;
    taxe.vehicle = vehicleForExport;
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      originId,
      site,
      updatedAt,
      totalPlacesAllowed,
      totalSeats,
      usage,
      taxHorsePower,
      damageValue,
      fireValue,
      brokenWindowValue,
      rcBonus,
      damageBonus,
      fireBonus,
      theftBonus,
      brokenWindowBonus,
      der,
      ...taxeForExport
    } = taxe; // omit backlinks and author
    return taxeForExport;
  });
  jsonexport(
    taxesForExport,
    {
      headers: [
        "date",
        "vehicle.parkingNumber",
        "vehicle.brand",
        "vehicle.model",
        "type",
        "amount",
        "createdAt",
      ],
      rename: [
        "Date échéance",
        "Numéro de parc",
        "Marque",
        "Modèle",
        "Type",
        "Montant total en net (MAD)",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Taxes/Vignettes"); // download as 'posts.csv` file
    }
  );
};
const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;
const VehicleInfo = (choice: { parkingNumber: string }) =>
  `${choice.parkingNumber}`;

const RecordId = () => {
  const record = useRecordContext();
  const redirect = `/${record.id}/show`;
  if (!record) return null;
  return redirect;
};
const TaxListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};

export const TaxList: React.FC = (props) => {

  const translate = useTranslate(); // Call the hook inside the component

  const TaxFilters = [
    <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
    <SelectInput
      source={"status"}
      // label="Statut"
      alwaysOn
      choices={[
        { id: "yes", name: translate('labels.effectue') },
        { id: "todo", name: translate('labels.avenir') },
        { id: "expired", name: translate('labels.enretard') },
        { id: "no", name: translate('labels.noneffectue') },
      ]}
      resettable
    />,
    // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
  ];

  const permissions = localStorage.getItem("permissions");

  return (
    <List
      actions={<TaxListActions />}
      title={translate('labels.taxes')}
      filters={TaxFilters}
      empty={<Empty create />}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField locales="fr-FR" source={"date"} /*label={"Date échéance"}*/ />
        <WrapperField /*label="Véhicule"*/ source="vehicle" sortBy="vehicle.parkingNumber">
          <TextField source={"vehicle.parkingNumber"} />
        </WrapperField>
        <SelectField source={"type"} /*label={"Type"}*/ choices={[
          { id: "taxe", name: translate('labels.Taxe') },
          { id: "vignette", name: translate('labels.Vignette') },
        ]} />
        <TextField source={"amount"} /*label={"Montant"}*/ />
        <FunctionField
          source={"status"}
          //label={"Statut"}
          render={(record: any) => {
            if (record.status === "yes") return <Chip color="success" size="small" label={translate('labels.effectue')} />;
            else if (record.status === "expired") return <Chip color="error" size="small" label={translate('labels.enretard')} />;
            else if (record.status === "no") return <Chip size="small" label={translate('labels.noneffectue')} />;
            else if (record.status === "todo") return <Chip color="warning" size="small" label={translate('labels.avenir')} />;
            else return <></>;
          }}
        />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions" >
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const TaxCreate: React.FC = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const defaultValue = () => ({ status: 'no' });

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/vehicle-tax-histories/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.addtax')}
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center" defaultValues={defaultValue}>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateTimeInput source={"date"} /*label="Date échéance"*/ />
          <ReferenceInput source={"vehicle"} reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText={VehicleInfo} /*label="Véhicule"*/ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <SelectInput
            source={"type"}
            //label="Type"
            choices={[
              { id: "taxe", name: translate('labels.Taxe') },
              { id: "vignette", name: translate('labels.Vignette') },
            ]}
          />
          <NumberInput source={"amount"} /*label="Montant"*/ />
          <SelectInput
            source={"status"}
            //label="Effectué"
            choices={[
              { id: "yes", name: translate('labels.effectue') },
              { id: "no", name: translate('labels.non-effcetue') },
            ]}
          />
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

interface TaxTitleProps {
  record: RaRecord;
}
const TaxTitle: React.FC<TaxTitleProps> = ({ record }) => {
  return <span>Taxe/Vignette {record ? `"${record.id}"` : ""}</span>;
};

export const TaxEdit: React.FC<TaxTitleProps> = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`L'élément a bien été modifié`);
    redirect("/vehicle-tax-histories/");
    refresh();
  };
  return (
    <Edit
      title={<TaxTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateTimeInput source={"date"} /*label="Date échéance"*/ />
          <ReferenceInput source={"vehicle[@id]"} reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText={VehicleInfo} /*label="Véhicule"*/ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <SelectInput
            source={"type"}
            //label="Type"
            choices={[
              { id: "taxe", name: translate('labels.Taxe') },
              { id: "vignette", name: translate('labels.Vignette') },
            ]}
          />
          <NumberInput source={"amount"} /*label="Montant"*/ />
          <SelectInput
            source={"status"}
            //label="Effectué"
            choices={[
              { id: "yes", name: translate('labels.effectue') },
              { id: "no", name: translate('labels.non-effcetue') },
            ]}
          />
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept=".pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export const TaxShow: React.FC<TaxTitleProps> = (props) => {
  return (
    <Show
      actions={<EditAction />}
      title={<TaxTitle record={props.record} />}
      {...props}
      sx={{ fontSize: "20px" }}>
      <SimpleShowLayout>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <Labeled label="Date échéance">
            <DateField
              locales="fr-FR"
              showTime
              source={"date"}
              //label="Date échéance"
              fontWeight="bold"
            />
          </Labeled>
          <Labeled label="Véhicule">
            <Box display="flex" fontWeight="bold">
              <TextField source={"vehicle.parkingNumber"} fontWeight="bold" />
              &nbsp;-&nbsp;
              <TextField source={"vehicle.brand"} fontWeight="bold" />
              &nbsp;
              <TextField source={"vehicle.model"} fontWeight="bold" />
            </Box>
          </Labeled>
          <Labeled label="Type">
            <TextField source={"type"} /*label="Type"*/ fontWeight="bold" />
          </Labeled>
          <Labeled label="Montant">
            <NumberField source={"amount"} /*label="Montant"*/ fontWeight="bold" />
          </Labeled>
        </Box>
        <Labeled label="Fichiers" marginTop="16px">
          {/* <ArrayField source="attachments">
            <UrlField source="contentUrl" label="Fichier" />
</ArrayField> */}
          <FileField
            source={"attachments"}
            src="contentUrl"
            title="title"
            target="_blank"
          />
        </Labeled>
      </SimpleShowLayout>
    </Show>
  );
};
