import { Box, Chip, Stack } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  FunctionField,
  ImageField,
  ImageInput,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  SelectColumnsButton,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import MyMap from "../components/MyMap";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import { useTranslate } from 'react-admin';
import isGranted from "../services/security";

const exporter = (Containers: any) => {
  const ContainersForExport = Containers.map((Container: any) => {
    console.log("Container", Container);
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      originId,
      operator,
      site,
      updatedAt,
      ...ContainerForExport
    } = Container; // omit backlinks and author
    return ContainerForExport;
  });
  jsonexport(
    ContainersForExport,
    {
      headers: [
        "date",
        "fuel",
        "blNumber",
        "quantity",
        "totalPrice",
        "createdAt",
      ],
      rename: [
        "Date de dotation",
        "Type de fluide",
        "N° bon de livraison",
        "Quantité",
        "Prix total (MAD)",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Bacs"); // download as 'posts.csv` file
    }
  );
};


const SuccessChip = () => {
  const translate = useTranslate();
  return (
    <Stack direction="row" alignItems="center" spacing={0}>
      <Chip color="success" size="small" label={translate('labels.Actif')} />
    </Stack>
  );
};
const ErrorChip = () => {
  const translate = useTranslate();
  return (
    <Stack direction="row" alignItems="center" spacing={0}>
      <Chip color="error" size="small" label={translate('labels.Inactif')} />
    </Stack>
  );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const ContainerListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      {/* <ExportButton /> */}
    </TopToolbar>
  );
};
const ContainerFilters = [
  <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const ContainerList: React.FC = (props) => {
  const permissions = localStorage.getItem("permissions");
  const translate = useTranslate();
  return (
    <List
      actions={<ContainerListActions />}
      title={translate('titles.bacs')}
      filters={ContainerFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <TextField source={"number"} /*label={"Numéro du bac"}*/ />
        <TextField source={"volume"} /*label={"Volume (L)"}*/ />
        <TextField source={"supplier"} /*label={"Fournisseur"}*/ />
        <FunctionField
          source={"status"}
          //label={"Statut"}
          render={(record: any) => {
            if (record.status === "active") return <SuccessChip />;
            else if (record.status === "inactive") return <ErrorChip />;
            else return <></>;
          }}
        />
        <NumberField source={"price"} /*label={"Prix acquisition (MAD)"}*/ />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />

            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const ContainerCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/containers/");
    refresh();
  };
  return (
    <Create title={translate('titles.addbin')} mutationOptions={{ onSuccess }} {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <TextInput
            source={"status"}
            //label="Statut"
            defaultValue={"active"}
            hidden={true}
            sx={{ display: "none" }}
          />
          <TextInput source={"number"} /*label="Numéro du bac"*/ />
          <SelectInput
            source={"type"}
            //label="Type"
            choices={[
              { id: "Bac métallique", name: translate('labels.Bacmétallique') },
              { id: "Bac Plastique", name: translate('labels.BacPlastique') },
              { id: "caisson ampli ROL", name: translate('labels.caissonampliROL') },
              { id: "caisson multi-benne", name: translate('labels.caissonmultibenne') },
              { id: "BAV", name: translate('labels.BAV') },
              { id: "Corbeille de ville", name: translate('labels.Corbeilledeville') },
              { id: "Corbeille de plage", name: translate('labels.Corbeilledeplage') },
            ]}
          />
          <SelectInput
            source={"volume"}
            //label="Volume (L)"
            choices={[
              { id: "50", name: "50" },
              { id: "90", name: "90" },
              { id: "120", name: "120" },
              { id: "240", name: "240" },
              { id: "360", name: "360" },
              { id: "660", name: "660" },
              { id: "770", name: "770" },
              { id: "1 100", name: "1 100" },
              { id: "3 000", name: "3 000" },
              { id: "4 000", name: "4 000" },
              { id: "5 000", name: "5 000" },
              { id: "6 000", name: "6 000" },
              { id: "15 000", name: "15 000" },
              { id: "20 000", name: "20 000" },
              { id: "25 000", name: "25 000" },
              { id: "30 000", name: "30 000" },
            ]}
          />
          <TextInput source={"supplier"} /* label="Fournisseur" */ />
          <NumberInput source={"price"} /* label="Prix (MAD)" */ />
          <DateInput source={"stockEnterDate"} /* label="Date entrée stock" */ />
          <DateInput source={"stockExitDate"} /* label="Date sortie stock" */ />
          <TextInput source={"blNumber"} /* label="Numéro bon de livraison" */ />
          <TextInput source={"bsNumber"} /* label="Numéro bon de sortie" */ />
          <TextInput source={"fdNumber"} /* label="Numéro fiche de dotation" */ />
          <DateInput source={"startDate"} /* label="Date de mise en place" */ />
          <SelectInput
            source={"allocation"}
            //label="Type de dotaion"
            choices={[
              { id: "Publique", name: translate('labels.Publique') },
              { id: "Privée", name: translate('labels.Privée') },
            ]}
          />
          <TextInput source={"location"} /* label="Adresse" */ />
          <NumberInput source={"latitude"} /* label="Latitude" */ />
          <NumberInput source={"longitude"} /* label="Longitude" */ />
          <TextInput source={"sector"} /* label="Arrondissement" */ />
        </Box>
        <ImageInput source={"images"} /* label="Images" */ accept="image/*" multiple>
          <ImageField source={"src"} />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

interface ContainerTitleProps {
  record: RaRecord;
}

const ContainerTitle: React.FC<ContainerTitleProps> = ({ record }) => {
  return <span>Réapprovisionnement {record ? `"${record.title}"` : ""}</span>;
};

export const ContainerEdit: React.FC<ContainerTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/containers/");
    refresh();
  };
  return (
    <Edit title={translate('titles.modifybin')} mutationMode="pessimistic" {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <TextInput source={"number"} /* label="Numéro du bac" */ />
          <SelectInput
            source={"type"}
            //label="Type"
            choices={[
              { id: "Bac métallique", name: translate('labels.Bacmétallique') },
              { id: "Bac Plastique", name: translate('labels.BacPlastique') },
              { id: "caisson ampli ROL", name: translate('labels.caissonampliROL') },
              { id: "caisson multi-benne", name: translate('labels.caissonmultibenne') },
              { id: "BAV", name: translate('labels.BAV') },
              { id: "Corbeille de ville", name: translate('labels.Corbeilledeville') },
              { id: "Corbeille de plage", name: translate('labels.Corbeilledeplage') },
            ]}
          />
          <SelectInput
            source={"volume"}
            //label="Volume (L)"
            choices={[
              { id: "50", name: "50" },
              { id: "90", name: "90" },
              { id: "120", name: "120" },
              { id: "240", name: "240" },
              { id: "360", name: "360" },
              { id: "660", name: "660" },
              { id: "770", name: "770" },
              { id: "1 100", name: "1 100" },
              { id: "3 000", name: "3 000" },
              { id: "4 000", name: "4 000" },
              { id: "5 000", name: "5 000" },
              { id: "6 000", name: "6 000" },
              { id: "15 000", name: "15 000" },
              { id: "20 000", name: "20 000" },
              { id: "25 000", name: "25 000" },
              { id: "30 000", name: "30 000" },
            ]}
          />
          <TextInput source={"supplier"} /* label="Fournisseur" */ />
          <NumberInput source={"price"} /* label="Prix (MAD)" */ />
          <DateInput source={"stockEnterDate"} /* label="Date entrée stock" */ />
          <DateInput source={"stockExitDate"} /* label="Date sortie stock" */ />
          <TextInput source={"blNumber"} /* label="Numéro bon de livraison" */ />
          <TextInput source={"bsNumber"} /* label="Numéro bon de sortie" */ />
          <TextInput source={"fdNumber"} /* label="Numéro fiche de dotation" */ />
          <DateInput source={"startDate"} /* label="Date de mise en place" */ />
          <SelectInput
            source={"allocation"}
            //label="Type de dotaion"
            choices={[
              { id: "Publique", name: translate('labels.Publique') },
              { id: "Privée", name: translate('labels.Privée') },
            ]}
          />
          <TextInput source={"location"} /* label="Adresse" */ />
          <NumberInput source={"latitude"} /* label="Latitude" */ />
          <NumberInput source={"longitude"} /* label="Longitude" */ />
          <TextInput source={"sector"} /* label="Arrondissement" */ />
        </Box>
        <ImageInput source={"images"} /* label="Images" */ accept="image/*" multiple>
          <ImageField source={"src"} />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const ContainerShow: React.FC<ContainerTitleProps> = (props) => {
  const translate = useTranslate();
  return (
    <Show actions={<EditAction />} title={translate('titles.showbin')} {...props}>
      <SimpleShowLayout>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <Labeled label="Numéro du bac">
            <TextField source={"number"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Type">
            <TextField source={"type"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Volume (L)">
            <TextField source={"volume"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Fournisseur">
            <TextField source={"supplier"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Prix (MAD)">
            <TextField source={"price"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Date entrée stock">
            <DateField locales="fr-FR" source={"stockEnterDate"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Date sortie stock">
            <DateField locales="fr-FR" source={"stockExitDate"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Numéro bon de livraison">
            <TextField source={"blNumber"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Numéro bon de sortie">
            <TextField source={"bsNumber"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Numéro fiche de dotation">
            <TextField source={"fdNumber"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Date de mise en place">
            <DateField locales="fr-FR" source={"startDate"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Type de dotaion">
            <TextField source={"allocation"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Adresse">
            <TextField source={"location"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Latitude">
            <TextField source={"latitude"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Longitude">
            <TextField source={"longitude"} fontWeight="bold" />
          </Labeled>
          <Labeled label="Arrondissement">
            <TextField source={"sector"} fontWeight="bold" />
          </Labeled>
        <Labeled label="Map">
          <FunctionField
            source="map"
            render={(record: any) => <MyMap latitude={record.latitude} longitude={record.longitude}/>}
          />
        </Labeled>
        </Box>
        <Labeled /* label="Image" */ marginTop="16px" fontSize="16px">
          <ImageField
            source={"images"}
            src="contentUrl"
            //label="Image"
            sx={{
              padding: "0",
            }}
          />
        </Labeled>
      </SimpleShowLayout>
    </Show>
  );
};
