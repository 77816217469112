import { Layout, LayoutProps } from 'react-admin';
import { MyAppBar } from './MyAppBar';
import MyError from './MyError';
import MyMenu from './MyMenu';

interface MyLayoutProps extends LayoutProps {
  // define any additional props specific to your layout
}

export const MyLayout = (props: MyLayoutProps) => (
  <Layout {...props} menu={MyMenu} appBar={MyAppBar} error={MyError}/>
);
