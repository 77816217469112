import History from "@mui/icons-material/History";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import { Title } from "react-admin";
import { useLocation } from "react-router-dom";

function MyError() {
  const { pathname } = useLocation();
  const originalPathname = React.useRef(pathname);

  // Effect that resets the error state whenever the location changes
  // React.useEffect(() => {
  //     if (pathname !== originalPathname.current) {
  //         resetErrorBoundary();
  //     }
  // }, [pathname, resetErrorBoundary]);

  return (
    <div>
      <Title title="Erreur" />
      <Box textAlign="center" height="100%" my="200px">
        <Box
          component="img"
          width="200px"
          src={require("../images/empty2.png")}
        />
        <h1>Une erreur s'est produite </h1>
        <Button
          variant="contained"
          startIcon={<History />}
          onClick={() => window.history.go(-1)}>
          Retour
        </Button>
      </Box>
    </div>
  );
}

export default MyError;
