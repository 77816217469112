import * as React from "react";
// import { shadows } from "@mui/system";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from "axios";
import { Loading, useAuthenticated, useTranslate } from "react-admin";
import { ReformDoughnout } from "../components/charts/ReformDoughnout";
import entrypoint from "../config/params";



interface DashboardStat {
  totalEmployees: number;
  absenseRate: number;
  totalNumberOfTrafficCodeViolations: number;
  totalNumberOfTrainings: number;
  daysOfVacations: number;
  hoursOfOvertime: number;

}

const DashboardHr = () => {
  const translate = useTranslate();
  useAuthenticated();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<DashboardStat | undefined>(undefined);
  const currMonth = new Date().getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = React.useState(String(currMonth));

  const today = new Date();

  // Extract year, month, and day components
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
  const day = String(today.getDate()).padStart(2, '0');

  // Concatenate components in the desired format
  const formattedDate = `${year}-${month}-${day}`;

  const fetchData = async (month: string) => {
    const token = localStorage.getItem("token");
    if (token === null) {
      return null;
    }
    const { data } = await axios.get<DashboardStat>(
      `${entrypoint}/rh-dashboard-stats`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { month }, // Pass selected month as a query parameter
      }
    );
    console.log(data);
    console.log(month)
    console.log(typeof month)
    setData(data);
    setLoading(false);
  };


  React.useEffect(() => {
    fetchData(selectedMonth);
  }, [selectedMonth]);

  //console.log("data-stats", data);

  if (loading) {
    return <Loading />;
  }

  if (data) {


    return (
      <Box>
        <Box sx={{ display: "flex", justifyContent: 'end', alignItems: 'center', gap: '8px', p: '8px 16px' }}>
          <InputLabel id="select-label">{translate('labels.month')}</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={selectedMonth}
            onChange={(event) => setSelectedMonth(event.target.value)}
          >
            <MenuItem value={1}>{translate('labels.month1-24')}</MenuItem>
            <MenuItem value={2}>{translate('labels.month2-24')}</MenuItem>
            <MenuItem value={3}>{translate('labels.month3-24')}</MenuItem>
            <MenuItem value={4}>{translate('labels.month4-24')}</MenuItem>
            <MenuItem value={5}>{translate('labels.month5-24')}</MenuItem>
            <MenuItem value={6}>{translate('labels.month6-24')}</MenuItem>
            <MenuItem value={7}>{translate('labels.month7-24')}</MenuItem>
            <MenuItem value={8}>{translate('labels.month8-24')}</MenuItem>
            <MenuItem value={9}>{translate('labels.month9-24')}</MenuItem>
            <MenuItem value={10}>{translate('labels.month10-24')}</MenuItem>
            <MenuItem value={11}>{translate('labels.month11-24')}</MenuItem>
            <MenuItem value={12}>{translate('labels.month12-24')}</MenuItem>
          </Select>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(2, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          }}
          gap={2}
          width="100%"
        >
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 2,
                borderRadius: "12px",
                padding: "24px",
              }}
            >
              <Typography sx={{ textAlign: "start", mb: "16px" }}>
                {translate('titles.employees')}
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ textAlign: "start" }}
              >
                {data?.totalEmployees}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 2,
                borderRadius: "12px",
                padding: "24px",
              }}
            >
              <Typography sx={{ textAlign: "start", mb: "16px" }}>
                {translate('titles.absenserate')}
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ textAlign: "start" }}
              >
                {`${data?.absenseRate} %`}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 2,
                borderRadius: "12px",
                padding: "24px",
              }}
            >
              <Typography sx={{ textAlign: "start", mb: "16px" }}>
                {translate('titles.totalnumberoftrafficcodeviolations')}
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ textAlign: "start" }}
              >
                {data?.totalNumberOfTrafficCodeViolations}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 2,
                borderRadius: "12px",
                padding: "24px",
              }}
            >
              <Typography sx={{ textAlign: "start", mb: "16px" }}>
                {translate('titles.totalnumberoftrainings')}
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ textAlign: "start" }}
              >
                {data?.totalNumberOfTrainings}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 2,
                borderRadius: "12px",
                padding: "24px",
              }}
            >
              <Typography sx={{ textAlign: "start", mb: "16px" }}>
                {translate('titles.daysofvacations')}
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ textAlign: "start" }}
              >
                {data?.daysOfVacations}
              </Typography>
            </Box>
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 2,
                borderRadius: "12px",
                padding: "24px",
              }}
            >
              <Typography sx={{ textAlign: "start", mb: "16px" }}>
                {translate('titles.hoursofovertime')}
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ textAlign: "start" }}
              >
                {data?.hoursOfOvertime}
              </Typography>
            </Box>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              bgcolor: "background.paper",
              boxShadow: 2,
              borderRadius: "12px",
              padding: "24px",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              {translate('titles.typereforms')}
            </Typography>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Box sx={{ margin: "auto" }}>
                <ReformDoughnout
                  casse={data?.totalNumberOfTrafficCodeViolations}
                  vol={data.totalNumberOfTrainings}
                  incendie={data.daysOfVacations}
                  autre={data.hoursOfOvertime}
                />
              </Box>
            </Box>
          </Box> */}
        </Box>






    );
  }
  return null;
};

export default DashboardHr;
