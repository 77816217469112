import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  ImageField,
  ImageInput,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectInput,
  Show,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  required,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const exporter = (incidents: any) => {
  const incidentsForExport = incidents.map((incident: any) => {
    const {
      "@id": arobasVehicleId,
      "@type": arobasVehicleType,
      createdAt,
      updatedAt: vehicleUpdatedAt,
      ...vehicleForExport
    } = incident.vehicle;
    incident.vehicle = vehicleForExport;
    const {
      "@id": arobasDriverId,
      "@type": arobasDriverType,
      createdAt: driverCreatedAt,
      attachments: driverAttachments,
      registrationNumber: driverRegistrationNumber,
      updatedAt: driverUpdatedAt,
      ...driverForExport
    } = incident.driver;
    incident.driver = driverForExport;
    const {
      "@id": arobasId,
      "@type": arobasType,
      id,
      place,
      responsibility,
      materialDamage,
      bodilyDamage,
      meansOfProof,
      circumstance,
      damageObserved,
      insuranceDeclarationDate,
      compensation,
      updatedAt,
      images,
      originId,
      ...incidentForExport
    } = incident; // omit backlinks and author
    return incidentForExport;
  });
  jsonexport(
    incidentsForExport,
    {
      headers: [
        "date",
        "vehicle.parkingNumber",
        "vehicle.brand",
        "vehicle.model",
        "driver.firstName",
        "driver.lastName",
        "location",
        "costOfWork",
        "createdAt",
      ],
      rename: [
        "Date de l'incident",
        "Numéro de parc",
        "Marque",
        "Modèle",
        "Prénom conducteur",
        "Nom conducteur",
        "Lieu",
        "Coût des travaux (MAD)",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Incidents"); // download as 'posts.csv` file
    }
  );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;
const fullName = (choice: { firstName: string; lastName: string }) =>
  `${choice.firstName} ${choice.lastName}`;
const VehicleInfo = (choice: { parkingNumber: string }) =>
  `${choice.parkingNumber}`;
const IncidentListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {/* <FilterButton /> */}
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const IncidentFilters = [
  <TextInput source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const IncidentList: React.FC = (props) => {
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<IncidentListActions />}
      filters={IncidentFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField locales="fr-FR" source={"date"} /*label={"Date incident"}*/ />

        <WrapperField /*label="Véhicule"*/ source="vehicle" sortBy="vehicle.parkingNumber">
          <TextField source={"vehicle.parkingNumber"} />
        </WrapperField>
        <WrapperField /*label="Conducteur"*/source="driver" sortBy="driver.lastName">
          <TextField source={"driver.lastName"} />
          &nbsp;
          <TextField source={"driver.firstName"} />
        </WrapperField>
        <TextField source={"location"} /*label={"Lieu"}*/ />
        <TextField source={"costOfWork"} /*label={"Coûts des travaux (MAD)"}*/ />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const IncidentCreate: React.FC = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/vehicle-incident-histories/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.addincident')}
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateTimeInput
            source={"date"}
            //label="Date et heure de l'incident"
            validate={[required("Champ obligatoire")]}
          />
          <ReferenceInput
            source={"driver"}
            reference="employees"
            queryOptions={{ meta: { query: "driver" } }} perPage={1000}>
            <AutocompleteInput optionText={fullName} /*label="Conducteur"*/ />
          </ReferenceInput>
          <ReferenceInput source={"vehicle"} reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText={VehicleInfo} /*label="Véhicule"*/ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <TextInput source={"location"} /*label="Lieu de l'incident"*/ />
          <SelectInput
            source={"responsibility"}
            //label="Responsabilité"
            choices={[
              { id: "0%", name: "0%" },
              { id: "50%", name: "50%" },
              { id: "100%", name: "100%" },
            ]}
          />
          <SelectInput
            source={"meansOfProof"}
            //label="Moyens de preuve"
            choices={[
              { id: "PV police", name: translate('labels.PVpolice') },
              { id: "PV gendarme", name: translate('labels.PVgendarme') },
              { id: "Constat à l'amiable", name: translate('labels.Constatàlamiable') },
              {
                id: "Déclarations écrites des temoins",
                name: translate('labels.Déclarationsécritesdestemoins'),
              },
            ]}
          />
          <SelectInput
            source={"bodilyDamage"}
            //label="Dégâts corporels"
            choices={[
              { id: "Oui", name: translate('labels.oui') },
              { id: "Non", name: translate('labels.non') },
            ]}
          />
          <SelectInput
            source={"materialDamage"}
            //label="Dégâts matériels"
            choices={[
              { id: "Oui", name: translate('labels.oui') },
              { id: "Non", name: translate('labels.non') },
            ]}
          />
          <SelectInput
            source={"circumstance"}
            //label="Circonstance de l'accident"
            choices={[
              { id: "Changement de fil", name: translate('labels.Changementdefil') },
              {
                id: "Non-respect de la priorité",
                name: translate('labels.Nonrespectdelapriorité'),
              },
              { id: "Perte de contrôle", name: translate('labels.Pertedecontrôle') },
              { id: "Excès de vitesse", name: translate('labels.Excèsdevitesse') },
              {
                id: "Arrêt obligatoire non respecté",
                name: translate('labels.Arrêtobligatoirenonrespecté'),
              },
              { id: "Défaut mécanique", name: translate('labels.Défautmécanique') },
            ]}
          />
          <DateInput
            source={"insuranceDeclarationDate"}
          //label="Date déclaration assurance"
          />
          <NumberInput source={"costOfWork"} /*label="Coût des travaux"*/ />
          <SelectInput
            source={"compensation"}
            //label="Indemnisation"
            choices={[
              { id: "Oui", name: translate('labels.oui') },
              { id: "Non", name: translate('labels.non') },
              { id: "En cours", name: translate('labels.encours') },
            ]}
          />
        </Box>
        <ImageInput source="images" label="Images" accept="image/*" multiple>
          <ImageField source="src" />
        </ImageInput>
        {/* <ArrayInput source={"images"}>
          <SimpleFormIterator>
            <ImageInput source="image" label="Image" accept="image/*">
              <ImageField source="contentUrl" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput> */}
      </SimpleForm>
    </Create>
  );
};

interface IncidentTitleProps {
  record: RaRecord;
}

const IncidentTitle: React.FC<IncidentTitleProps> = ({ record }) => {
  return <span>Incident {record ? `"${record.title}"` : ""}</span>;
};

export const IncidentEdit: React.FC<IncidentTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été modifié`);
    redirect("/vehicle-incident-histories/");
    refresh();
  };
  return (
    <Edit
      title={<IncidentTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateTimeInput
            source={"date"}
            //label="Date et heure de l'incident"
            validate={[required("Champ obligatoire")]}
          />
          <ReferenceInput
            source="driver[@id]"
            reference="employees"
            perPage={1000}
            queryOptions={{ meta: { query: "driver" } }}>
            <AutocompleteInput optionText={fullName} /*label="Conducteur"*/ />
          </ReferenceInput>
          <ReferenceInput source="vehicle[@id]" reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText={VehicleInfo} /*label="Véhicule"*/ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <TextInput source={"location"} /*label="Lieu de l'incident"*/ />
          <SelectInput
            source={"responsibility"}
            //label="Responsabilité"
            choices={[
              { id: "0%", name: "0%" },
              { id: "50%", name: "50%" },
              { id: "100%", name: "100%" },
            ]}
          />
          <SelectInput
            source={"meansOfProof"}
            //label="Moyens de preuve"
            choices={[
              { id: "PV police", name: translate('labels.PVpolice') },
              { id: "PV gendarme", name: translate('labels.PVgendarme') },
              { id: "Constat à l'amiable", name: translate('labels.Constatàlamiable') },
              {
                id: "Déclarations écrites des temoins",
                name: translate('labels.Déclarationsécritesdestemoins'),
              },
            ]}
          />
          <SelectInput
            source={"bodilyDamage"}
            //label="Dégâts corporels"
            choices={[
              { id: "Oui", name: translate('labels.oui') },
              { id: "Non", name: translate('labels.non') },
            ]}
          />
          <SelectInput
            source={"materialDamage"}
            //label="Dégâts matériels"
            choices={[
              { id: "Oui", name: translate('labels.oui') },
              { id: "Non", name: translate('labels.non') },
            ]}
          />
          <SelectInput
            source={"responsibility"}
            //label="Responsabilité"
            choices={[
              { id: "0%", name: "0%" },
              { id: "50%", name: "50%" },
              { id: "100%", name: "100%" },
            ]}
          />
          <SelectInput
            source={"meansOfProof"}
            //label="Moyens de preuve"
            choices={[
              { id: "PV police", name: translate('labels.PVpolice') },
              { id: "PV gendarme", name: translate('labels.PVgendarme') },
              { id: "Constat à l'amiable", name: translate('labels.Constatàlamiable') },
              {
                id: "Déclarations écrites des temoins",
                name: translate('labels.Déclarationsécritesdestemoins'),
              },
            ]}
          />
          <SelectInput
            source={"bodilyDamage"}
            //label="Dégâts corporels"
            choices={[
              { id: "Oui", name: translate('labels.oui') },
              { id: "Non", name: translate('labels.non') },
            ]}
          />
          <SelectInput
            source={"materialDamage"}
            //label="Dégâts matériels"
            choices={[
              { id: "Oui", name: translate('labels.oui') },
              { id: "Non", name: translate('labels.non') },
            ]}
          />

          <SelectInput
            source={"circumstance"}
            //label="Circonstance de l'accident"
            choices={[
              { id: "Changement de fil", name: translate('labels.Changementdefil') },
              {
                id: "Non-respect de la priorité",
                name: translate('labels.Nonrespectdelapriorité'),
              },
              { id: "Perte de contrôle", name: translate('labels.Pertedecontrôle') },
              { id: "Excès de vitesse", name: translate('labels.Excèsdevitesse') },
              {
                id: "Arrêt obligatoire non respecté",
                name: translate('labels.Arrêtobligatoirenonrespecté'),
              },
              { id: "Défaut mécanique", name: translate('labels.Défautmécanique') },
            ]}
          />
          <DateInput
            source={"insuranceDeclarationDate"}
          //label="Date déclaration assurance"
          />
          <NumberInput source={"costOfWork"} /*label="Coût des travaux"*/ />
          <SelectInput
            source={"compensation"}
            //label="Indemnisation"
            choices={[
              { id: "Oui", name: translate('labels.oui') },
              { id: "Non", name: translate('labels.non') },
              { id: "En cours", name: translate('labels.encours') },
            ]}
          />
        </Box>
        <ImageInput source="images" label="Images" accept="image/*" multiple>
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const IncidentShow: React.FC<IncidentTitleProps> = (props) => (
  <Show
    actions={<EditAction />}
    title={<IncidentTitle record={props.record} />}
    {...props}
    sx={{ fontSize: "20px" }}>
    <SimpleForm justifyContent="center">
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%">
        <Labeled label="Date et heure de l'incident">
          <DateField
            locales="fr-FR"
            showTime
            source={"date"}
            //label="Date et heure de l'incident"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Véhicule">
          <Box display="flex" fontWeight="bold">
            <TextField source={"vehicle.parkingNumber"} fontWeight="bold" />
            &nbsp;-&nbsp;
            <TextField source={"vehicle.brand"} fontWeight="bold" />
            &nbsp;
            <TextField source={"vehicle.model"} fontWeight="bold" />
          </Box>
        </Labeled>
        <Labeled label="Conducteur">
          <Box display="flex" fontWeight="bold">
            <TextField source={"driver.lastName"} fontWeight="bold" />
            &nbsp;
            <TextField source={"driver.firstName"} fontWeight="bold" />
          </Box>
        </Labeled>
        <Labeled label="Lieu de l'incident">
          <TextField
            source={"location"}
            //label="Lieu de l'incident"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Responsabilité">
          <TextField
            source={"responsibility"}
            //label="Responsabilité"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Moyens de preuve">
          <TextField
            source={"meansOfProof"}
            //label="Moyens de preuve"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Dégâts corporels">
          <TextField
            source={"bodilyDamage"}
            //label="Dégâts corporels"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Dégâts matériels">
          <TextField
            source={"materialDamage"}
            //label="Dégâts matériels"
            fontWeight="bold"
          />
        </Labeled>

        <Labeled label="Circonstance de l'accident">
          <TextField
            source={"circumstance"}
            //label="Circonstance de l'accident"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Date déclaration assurance">
          <DateField
            locales="fr-FR"
            source={"insuranceDeclarationDate"}
            //label="Date déclaration assurance"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Coût des travaux">
          <NumberField
            source={"costOfWork"}
            //label="Coût des travaux"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Indemnisation">
          <TextField
            source={"compensation"}
            //label="Indemnisation"
            fontWeight="bold"
          />
        </Labeled>
      </Box>
      <Labeled label="Image" marginTop="16px" fontSize="16px">
        <ImageField
          source="images"
          src="contentUrl"
          //label="Image"
          sx={{
            padding: "0",
          }}
        />
      </Labeled>
    </SimpleForm>
  </Show>
);
