import { createTheme } from "@mui/material/styles";
import merge from "lodash/merge";
import { defaultTheme } from "react-admin";

export const MyTheme = createTheme(
  merge({}, defaultTheme, {
    sidebar: {
      width: 260, // The default value is 240
      closedWidth: 260, // The default value is 55
    },
    palette: {
      // Your theme goes here
      // Write the following code to have an orange app bar. We'll explain it later in this article.
      secondary: {
        main: "#b0b0b0", // Not far from orange #262626
      },
      primary: {
        main: "#026937", // Not far from orange
      },
    },
    components: {
      ...defaultTheme.components,
      MuiTextField: {
        defaultProps: {
          variant: "outlined" as const,
        },
      },
      RaMenuItemLink: {
        styleOverrides: {
          root: {
            height: "48px",
            color: "#344054",
            // borderLeft: '3px solid #fff',
            margin: "4px 8px",
            "&:hover": {
              backgroundColor: "#F0F1F2",
              borderRadius: "6px",
            },
            svg: {
              marginRight: "12px"
            },
            "&.RaMenuItemLink-active": {
              borderRadius: "6px",
              color: "#ffffff", // #262626 text selected
              backgroundColor: "#027A48", // #fafafb of selected item
              svg: {
                path:{
                  stroke: "#ffffff", 
                },
              },
            },
          },
        },
      },
      RaImageField: {
        styleOverrides: {
          root: {
            img: {
              border: "1px solid #AAA",
              borderRadius: "8px",
              width: "300px !important",
              height: "200px !important",
              objectFit: "cover !important",
            },
            ul: {
              padding: "0px",
              margin: "0px",
            },
            a: {
              width: "300px !important",
            },

          },
        },
      },
      RaField: {
        styleOverrides: {
          root: {
            a: {

              width: "300px !important",

            },

          },
        },
      },
      RaSidebar: {
        bottom: "0px !important",
        styleOverrides: {
          root: {
            bottom: "0px !important",
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: "#FFF",
          },
        },
      },
      RaMenu: {
        styleOverrides: {
          root: {
            marginTop: "0px",
            marginBottom: "0px",
            hr: {
              marginTop: "4px !important",
              marginBottom: "4px !important",
            },
          },
        },
      },
      RaLogout: {
        styleOverrides: {
          root: {
            svg: {
              color: "#000",
            },
          },
        },
      },
      RaLayout: {
        styleOverrides: {
          root: {
            "& .RaLayout-content": {
              padding: "24px",
            }
          },
        },
      },
      MuiPaper: {
        elevation: 6,
        styleOverrides: {
          elevation3: {
            // boxShadow: 'none',
          },
          root: {
            border: "1px solid #e0e0e3",
            backgroundClip: "padding-box",
            "&.MuiCard-root": {},
            "&.RaList-content": {
              overflow: "hidden",
              marginTop: "24px",
              marginBottom: "12px",
            },
          },
          rounded: {
            borderRadius: "12px",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorSecondary: {
            color: "#262626",
            backgroundColor: "#ffffff", // #CCE1D7
          },
          marginBottom: "80px",
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: "#f5f5f5",
          },
          barColorPrimary: {
            backgroundColor: "#d7d7d7",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:last-child td": { border: 0 },
            "& .RaDatagrid-headerCell": {
              textTransform: "uppercase",
              fontFamily: "Inter, sans-serif !important",
              fontSize: "13px !important",
              fontWeight: "600 !important",
              color: "#5e7877 !important",
            },
          },
        },
      },

      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "16px 16px",
            "& .MuiTypography-root": {
              fontWeight: "500",
              "&.RaLabeled-label": {
                color: "#F00",
              },
            },
          },
        },
      },

      "MuiTableCell-head": {
        textTransform: "uppercase",
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            // fontSize: "15px !important",
            fontFamily: "Inter, sans-serif !important",
            "&.RaLabeled-label": {
              fontSize: "12px",
              fontWeight: "500",
              color: "#5e7877",
              textTransform: "uppercase",
            },
            "&.MuiTypography-body2": {
              fontSize: "15px",
              fontWeight: "500",
            },
            // fontWeight: "inherit !important",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            ".RaSidebar-fixed": { bottom: "0px !important" },
          },
        },
      },

      // MuiButton: {
      //     variant: "contained",
      //     styleOverrides: {
      //         variant: "contained",
      //         root: {
      //             variant: "contained",
      //         },
      //     },
      // },
    },
  })
);
