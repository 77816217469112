import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useTranslate } from 'react-admin';

ChartJS.register(ArcElement, Tooltip, Legend);

export function ReformDoughnout({ casse, vol, incendie, autre }: { casse: number, vol: number, incendie: number, autre: number }) {

  const translate = useTranslate();
  const textcost = translate('labels.typereforms');

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: textcost,
      },
    },
  };

  const config = {
    labels: [
      translate('labels.casse'),
      translate('labels.vol'),
      translate('labels.incendie'),
      translate('labels.autre')
    ],
    datasets: [
      {
        data: [
          casse,
          vol,
          incendie,
          autre,
        ],
        backgroundColor: [
          "rgba(103, 165, 135, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(252, 165, 165, 0.2)",
          "rgba(0, 100, 220, 0.2)",
        ],
        borderColor: [
          "rgba(103, 165, 135, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(252, 165, 165, 1)",
          "rgba(0, 100, 220, 0.7)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut data={config} options={options} />;

}
