import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  Labeled,
  List,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TimeInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh,
  required
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const exporter = (medicalVisits: any) => {
  const medicalVisitsForExport = medicalVisits.map((medicalVisit: any) => {
    if (medicalVisit.dueDate) {
      medicalVisit.dueDate = medicalVisit.dueDate.slice(0, 10);
    };
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      createdAt,
      ...medicalVisitForExport
    } = medicalVisit;
    return medicalVisitForExport;
  });
  jsonexport(
    medicalVisitsForExport,
    {
      headers: [
        "employee['firstName']",
        "employee['lastName']",
        "date",
        "situation"
      ],
      rename: [
        "Prenom",
        "Nom",
        "Date",
        "Situation"
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "MedicalVisit"); // download as 'posts.csv` file
    }
  );
};

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const fullName = (choice: { firstName: string; lastName: string }) =>
  `${choice.firstName} ${choice.lastName}`;

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const MedicalVisitListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const MedicalVisitFilters = [
  <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const MedicalVisitList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<MedicalVisitListActions />}
      filters={MedicalVisitFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField source={"date"} /*label="Matricule"*/ />
        <WrapperField source="agent" sortBy="agent.lastName">
          <TextField source={"agent.lastName"} />
          &nbsp;
          <TextField source={"agent.firstName"} />
        </WrapperField>
        <SelectField
          source={"typeDeVisit"}
          // label="typeDeVisit"
          choices={[
            { id: "Visite annuelle", name: translate('Visite annuelle') },
            { id: "Visite d’embauche", name: translate('Visite d’embauche') },
            { id: "Contre-visite", name: translate('Contre-visite') },
            { id: "Vaccination", name: translate('Vaccination') },
            

          ]}
        />
        <TextField source={"medecin"} /*label="Medecin"*/ />


        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const MedicalVisitCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/medical-visits/");
    refresh();
  };
  return (
    <Create
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} validate={[required("Champ obligatoire")]} /*label="date"*/ />
          <ReferenceInput
            source={"agent"}
            reference="employees"
            perPage={1000}
          >
            <AutocompleteInput optionText={fullName} /* label="Conducteur" */ filterToQuery={filterToQuery} validate={[required("Champ obligatoire")]} />
          </ReferenceInput>
          <SelectInput
            source={"typeDeVisit"}
            // label="typeDeVisit"
            choices={[
              { id: "Visite annuelle", name: translate('Visite annuelle') },
              { id: "Visite d’embauche", name: translate('Visite d’embauche') },
              { id: "Contre-visite", name: translate('Contre-visite') },
              { id: "Vaccination", name: translate('Vaccination') },


            ]}
        />  
        <TextInput source={"medecin"} /*label="Medecin"*/ />

        </Box>

        

      </SimpleForm>
    </Create>
  );
};

interface MedicalVisitTitleProps {
  record: RaRecord;
}

const MedicalVisitTitle: React.FC<MedicalVisitTitleProps> = ({ record }) => {
  return <span>Contrôle {record ? `"${record.title}"` : ""}</span>;
};

export const MedicalVisitEdit: React.FC<MedicalVisitTitleProps> = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const translate = useTranslate();
  
    const onSuccess = () => {
      notify(`L'élément a bien été créé`);
      redirect("/medical-visits/");
      refresh();
    };
    return (
      <Edit
        title={<MedicalVisitTitle record={props.record} />}
        mutationMode="pessimistic"
        {...props}>
        <SimpleForm>
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(5, 1fr)",
            }}
            gap={2}
            width="100%"
          >
            <DateInput source={"date"} /*label="date"*/ />
          <ReferenceInput
            source={"agent"}
            reference="employees"
            perPage={1000}
          >
            <AutocompleteInput optionText={fullName} /* label="Conducteur" */ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <SelectField
            source={"typeDeVisit"}
            // label="typeDeVisit"
            choices={[
              { id: "Visite annuelle", name: translate('Visite annuelle') },
              { id: "Visite d’embauche", name: translate('Visite d’embauche') },
              { id: "Contre-visite", name: translate('Contre-visite') },
              { id: "Vaccination", name: translate('Vaccination') },


            ]}
            />  
        <TextField source={"medecin"} /*label="Medecin"*/ />
  
          </Box>
  
        </SimpleForm>
      </Edit>
    );
  };
  
  export const MedicalVisitShow: React.FC<MedicalVisitTitleProps> = (props) => (
    <Show
      actions={<EditAction />}
      title={<MedicalVisitTitle record={props.record} />}
      sx={{ fontSize: "20px" }}
      {...props}>
      <SimpleShowLayout>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
  
          <Labeled>
            <DateField source={"date"} /*label="MedicalVisit Date"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <WrapperField source="agent" sortBy="agent.lastName">
              <Box display={"flex"}>
              <TextField source={"agent.lastName"} />
              &nbsp;
              <TextField source={"agent.firstName"} />
              </Box>
            </WrapperField>      
          </Labeled>
          <Labeled>
            <TextField source={"typeDeVisit"} /*label="type De Visit"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"medecin"} /*label="Medecin"*/ fontWeight="bold" />
          </Labeled>
  
        </Box>
  
        <Labeled label="Fichiers" marginTop="16px">
          <FileField
            source={"justifications"}
            src="contentUrl"
            title="title"
            target="_blank"
          />
        </Labeled>
      </SimpleShowLayout>
    </Show>
  );
  