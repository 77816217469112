import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslate } from 'react-admin';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  scales: {
    y: {
      suggestedMin: 0,
      suggestedMax: 100,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
    },
  },
  
};

export function EssenceLine({ data1, data2 } : {data1: any, data2: any}) {
  const translate = useTranslate();
  const config = {
    labels: (data1 || []).reduce((acc: any[], current: any) => {
      const date = new Date(current.date);
      acc.push(date.getDate() + "/" + (date.getMonth()+1));
      return acc;
    }, []),
    datasets: [
      {
        data: (data1 || []).reduce((acc: any[], current: any) => {
          acc.push(current.value);
          return acc;
        }, []),
        borderColor: "rgba(103, 165, 135, 1)",
        backgroundColor: "rgba(103, 165, 135, 0.5)",
        label: translate('labels.consumptionliters'),
      },
      {
        data: (data2 || []).reduce((acc: any[], current: any) => {
          acc.push(current.value);
          return acc;
        }, []),
        borderColor: "rgba(252, 165, 165, 1)",
        backgroundColor: "rgba(252, 165, 165, 0.5)",
        label: translate('labels.consumptioncurrency'),
      },
    ],
  };

  return <Line options={options} data={config} />;
}
