import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import jsonexport from "jsonexport/dist";
import numberWithCommas from "../components/numberWithCommas";
import {
  DatagridConfigurable,
  ExportButton,
  FunctionField,
  List,
  SelectInput,
  TextField,
  TopToolbar,
  downloadCSV,
  required
} from "react-admin";
import { Empty } from "../components/Empty";
import { useState } from 'react';

const exporter_by_vehicle = (fuelConsumptions: any) => {
  console.log(fuelConsumptions);
  const fuelConsumptionsForExport = fuelConsumptions.map(
    (fuelConsumption: any) => {
      const {
        "@id": arobasId,
        "@type": arobasType,
        id,
        createdAt: fuelConsumptionCreatedAt,
        updatedAt: fuelConsumptionUpdatedAt,
        vehicle_id: vehicleId,
        ...fuelConsumptionForExport
      } = fuelConsumption;
      return fuelConsumptionForExport;
    }
  );
  jsonexport(
    fuelConsumptionsForExport,
    {
      headers: [
        "parkingNumber",
        "totalDiffMileage",
        "totalDiffTimeMeter",
        "totalQuantity",
      ],
      rename: [
        "N de parc",
        "Total KM parcouru",
        "Total Heures moteur",
        "Total Carburant",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Consommation mensuelle par véhicule"); // download as 'posts.csv` file
    }
  );
};
const exporter_by_day = (fuelConsumptions: any) => {
  console.log(fuelConsumptions);
  const fuelConsumptionsForExport = fuelConsumptions.map(
    (fuelConsumption: any) => {
      const {
        "@id": arobasId,
        "@type": arobasType,
        id,
        createdAt: fuelConsumptionCreatedAt,
        updatedAt: fuelConsumptionUpdatedAt,
        vehicle_id: vehicleId,
        ...fuelConsumptionForExport
      } = fuelConsumption;
      return fuelConsumptionForExport;
    }
  );
  jsonexport(
    fuelConsumptionsForExport,
    {
      headers: [
        "date",
        "totalDiffMileage",
        "totalDiffTimeMeter",
        "totalQuantity",
      ],
      rename: [
        "Jour",
        "Total KM parcouru",
        "Total Heures moteur",
        "Total Carburant",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Consommation mensuelle par jour"); // download as 'posts.csv` file
    }
  );
};

const FuelSummaryListActions = ({ gridState, handleChangeGrid }: { gridState: any, handleChangeGrid: any }) => {
  return (
    <TopToolbar>
      <ButtonGroup
        sx={{
          "& .MuiButton-startIcon": {
            marginRight: "0px",
            marginLeft: "0px",
          },
        }}
        size="small">
        <Button
          variant={gridState === "véhicule" ? "contained" : "outlined"}
          startIcon={<DirectionsBusIcon />}
          onClick={() => {
            handleChangeGrid("véhicule")
          }}>&nbsp;&nbsp;Par véhicule</Button>
        <Button
          variant={gridState === "jour" ? "contained" : "outlined"}
          startIcon={<CalendarMonthIcon />}
          onClick={() => {
            handleChangeGrid("jour")
          }}>&nbsp;&nbsp;Par jour</Button>
      </ButtonGroup>
      <ExportButton />
    </TopToolbar>
  );
};

const FuelFilters = [
  <SelectInput
    source="query"
    label="Mois"
    alwaysOn
    choices={[
      { id: "1", name: "Janvier" },
      { id: "2", name: "Février" },
      { id: "3", name: "Mars" },
      { id: "4", name: "Avril" },
      { id: "5", name: "Mai" },
      { id: "6", name: "Juin" },
      { id: "7", name: "Juillet" },
      { id: "8", name: "Août" },
      { id: "9", name: "Septembre" },
      { id: "10", name: "Octobre" },
      { id: "11", name: "Novembre" },
      { id: "12", name: "Décembre" },
    ]}
    validate={required()}
    emptyValue={"1"}
  />,
];

export const FuelSummaryList: React.FC = (props) => {
  const [gridState, setGridState] = useState<string>("véhicule")
  const handleChangeGrid = (e: string) => {
    setGridState(e)
    localStorage.setItem("gridState", e);
  }
  return (
    <>
      {gridState === "véhicule" ? (
        <List
          title="Consommation mensuelle"
          filters={FuelFilters}
          empty={<Empty create={false} />}
          exporter={exporter_by_vehicle}
          resource="fuel-summary-by-vehicle"
          {...props}
          actions={<FuelSummaryListActions gridState={gridState} handleChangeGrid={handleChangeGrid} />}
        >
          <DatagridConfigurable bulkActionButtons={false}>
            <TextField source={"parkingNumber"} label={"Véhicule"} />
            <FunctionField
              source="totalDiffMileage"
              label={"total KM parcouru"}
              textAlign="right"
              render={(record: any) =>
                record.totalDiffMileage ? numberWithCommas(record.totalDiffMileage.toFixed(1)) : "-"
              }
            />
            <FunctionField
              source="totalDiffTimeMeter"
              label={"total Heures moteur"}
              textAlign="right"
              render={(record: any) =>
                record.totalDiffTimeMeter ? numberWithCommas(record.totalDiffTimeMeter.toFixed(1)) : "-"
              }
            />
            <FunctionField
              source="totalQuantity"
              label={"total Carburant"}
              textAlign="right"
              render={(record: any) =>
                record.totalQuantity ? numberWithCommas(record.totalQuantity.toFixed(1)) : "-"
              }
            />
          </DatagridConfigurable>
        </List>) : (<>
          <List
            title="Consommation mensuelle"
            filters={FuelFilters}
            empty={<Empty create={false} />}
            exporter={exporter_by_day}
            resource="fuel-summary-by-day"
            {...props}
            actions={<FuelSummaryListActions gridState={gridState} handleChangeGrid={handleChangeGrid} />}
          >
            <DatagridConfigurable bulkActionButtons={false}>
              <TextField source={"date"} label={"Jour"} />
              <FunctionField
                source="totalDiffMileage"
                label={"total KM parcouru"}
                textAlign="right"
                render={(record: any) =>
                  record.totalDiffMileage ? numberWithCommas(record.totalDiffMileage.toFixed(1)) : "-"
                }
              />
              <FunctionField
                source="totalDiffTimeMeter"
                label={"total Heures moteur"}
                textAlign="right"
                render={(record: any) =>
                  record.totalDiffTimeMeter ? numberWithCommas(record.totalDiffTimeMeter.toFixed(1)) : "-"
                }
              />
              <FunctionField
                source="totalQuantity"
                label={"total Carburant"}
                textAlign="right"
                render={(record: any) =>
                  record.totalQuantity ? numberWithCommas(record.totalQuantity.toFixed(1)) : "-"
                }
              />
            </DatagridConfigurable>
          </List></>)}
    </>

  );
};
