import {
  Close,
  Done,
  Download,
  Upload,
  Warning
} from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Chip, IconButton, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import {
  ArrayField,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ReferenceField,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext
} from "react-admin";
import { Empty } from "../components/Empty";
import { useTranslate } from 'react-admin';

function DownloadCanvasesMenu() {
  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        endIcon={<KeyboardArrowDownIcon />}>
        {translate('titles.Téléchargercanevas')}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        <MenuItem
          onClick={() => {
            window.open(urls.v1, "_blank", "noreferrer");
            handleClose();
          }}>
          Visite V1
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(urls.v2, "_blank", "noreferrer");
            handleClose();
          }}>
          Visite V2
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(urls.v3, "_blank", "noreferrer");
            handleClose();
          }}>
          Visite V3
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(urls.v4, "_blank", "noreferrer");
            handleClose();
          }}>
          Visite V4
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(urls.v5, "_blank", "noreferrer");
            handleClose();
          }}>
          Visite V5
        </MenuItem>
      </Menu>
    </div>
  );
}


const urls = {
  v1: "https://api.smartclean.ma/media/attachments/v1-64eb936cc1b92361435550.pdf",
  v2: "https://api.smartclean.ma/media/attachments/v2-64eb93c0628d4791344758.pdf",
  v3: "https://api.smartclean.ma/media/attachments/v3-64eb93f2abe6a983046715.pdf",
  v4: "https://api.smartclean.ma/media/attachments/v4-64eb96ed4a948103446551.pdf",
  v5: "https://api.smartclean.ma/media/attachments/v5-64eb9709541d3073050757.pdf",
};

const ToDoChip = () => {
  const record = useRecordContext();
  console.log("record2", encodeURI(record["@id"]));
  const a = record["@id"];
  return (
    <Stack direction="row" alignItems="center" spacing={0}>
      <Chip color="warning" size="small" icon={<Warning />} /*label="À faire"*/ />
      <EditButton
        to={`/vehicle-maintenance-histories/${encodeURIComponent(
          record["@id"]
        )}`}
        //label=""
        icon={<Upload />}
        sx={{ padding: "4px", minWidth: "0px" }}
      />

    </Stack>
  );
};
const PendingChip = () => {
  const record = useRecordContext();
  return (
    <Stack direction="row" alignItems="center" spacing={0}>
      <Chip size="small" icon={<Close />} /*label="Pas fait"*/ />
      <EditButton
        to={`/vehicle-maintenance-histories/${encodeURIComponent(
          record["@id"]
        )}`}
        //label=""
        icon={<Upload />}
        sx={{ padding: "4px", minWidth: "0px" }}
      />
    </Stack>
  );
};
const DoneChip = (record: any) => {
  return (
    <Stack direction="row" alignItems="center" spacing={0}>
      <Chip color="success" size="small" icon={<Done />} /*label="Fait"*/ />
      <IconButton
        aria-label="Télécharger"
        size="small"
        onClick={() =>
          window.open(
            record?.record?.attachments[0]?.contentUrl,
            "_blank",
            "noreferrer"
          )
        }>
        <Download sx={{ fontSize: "20px" }} />
      </IconButton>
      <EditButton
        to={`/vehicle-maintenance-histories/${encodeURIComponent(
          record?.record["@id"]
        )}`}
        //label=""
        icon={<EditIcon />}
        sx={{ padding: "4px", minWidth: "0px" }}
      />
    </Stack>
  );
};
const InitChip = () => (
  <Stack direction="row" alignItems="center" spacing={0}>
    <Chip color="success" size="small" icon={<Done />} /*label="Initial"*/ />
  </Stack>
);
const MaintenanceListActions = () => (
  <TopToolbar>
    <DownloadCanvasesMenu />
    {/* <ExportButton /> */}
  </TopToolbar>
);
const MaintenanceFilters = [
  <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export let MaintenanceList = () => {
  const translate = useTranslate();

  return (
    <List
      actions={<MaintenanceListActions />}
      title={translate('titles.Calendriermaintenance')}
      filters={MaintenanceFilters}
      empty={<Empty create />}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField
          //label="Véhicule"
          source={"maintenance.vehicle"}
          reference="vehicles"
          sortBy="parkingNumbers"
          link={false}
        >
          <TextField source={"parkingNumber"} />
        </ReferenceField>
        <TextField /*label="Cycle"*/ source={"position"} />
        <ArrayField source={"visits"} /*label="Visites"*/>
          <SingleFieldList
            sx={{ gap: "12px", textAlign: "center", display: "flex" }}
            linkType={false}
          >
            <Box
              px={1}
              border="1px solid #F1F1F1"
              borderRadius="6px"
              bgcolor="#FCFCFC"
            >
              <TextField source={"type"} color="black" />
              <FunctionField
                sx={{
                  "& .MuiButton-startIcon": {
                    marginRight: "0px",
                    marginLeft: "0px",
                  },
                }}
                render={(record: any) => {
                  if (record.status === "done")
                    return <DoneChip record={record} />;
                  else if (record.status === "pending") return <PendingChip />;
                  else if (record.status === "todo") return <ToDoChip />;
                  else if (record.status === "initial") return <InitChip />;
                }}
              />
            </Box>
          </SingleFieldList>
        </ArrayField>
        {/* <FunctionField
        label="V1"
        render={(vehicleMaintenanceHistories: any) => {
          return <Box>{vehicleMaintenanceHistories}</Box>;
        }}
      /> */}
        {/* <FunctionField
        label="V5"
        render={(v5: { status: string; url: string }) => {
          return <Box>{!v5.status ? <DoneChip /> : <ToDoChip />}</Box>;
        }}
      /> */}
      </Datagrid>
    </List>
  );
};
