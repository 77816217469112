import { Box, Typography } from "@mui/material";
import { CreateButton } from "react-admin";

export const Empty = ({ create }) => (
  <Box textAlign="center" height="100%" my="200px" mx="auto" display="inline-flex" flexDirection="column" gap="32px">
    <Typography variant="h5" paragraph>
      Aucun élément créé
    </Typography>
    <Box
      component="img"
      mx="auto"
      width="200px"
      src={require("../images/empty2.png")}
    />
    {create &&
      <CreateButton variant="contained" px="16px" py="16px" sx={{ fontSize: "16px" }} />
    }
  </Box>
);
