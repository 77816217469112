import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TimeInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh,
  required
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';
import { Typography } from '@mui/material';


const exporter = (vacations: any) => {
  const vacationsForExport = vacations.map((vacation: any) => {
    console.log("vacation", vacation);
    if (vacation.dueDate) {
      vacation.dueDate = vacation.dueDate.slice(0, 10);
    };
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      createdAt,
      ...vacationForExport
    } = vacation;
    return vacationForExport;
  });
  jsonexport(
    vacationsForExport,
    {
      headers: [
        "requestDate",
        "employee['firstName']",
        "employee['lastName']",
        "departureDate",
        "returnDate",

      ],
      rename: [
        "Date de demande",
        "Prenom",
        "Nom",
        "Date de départ",
        "Date de reprise",

      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Vacation"); // download as 'posts.csv` file
    }
  );
};

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const fullName = (choice: { firstName: string; lastName: string }) =>
  `${choice.firstName} ${choice.lastName}`;

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const VacationListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const VacationFilters = [
  <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const VacationList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<VacationListActions />}
      filters={VacationFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <WrapperField source="employee" sortBy="employee.lastName">
          <TextField source={"employee.lastName"} />
          &nbsp;
          <TextField source={"employee.firstName"} />
        </WrapperField>
        <DateField source={"requestDate"} /*label="Date de demande"*/ />
        <DateField source={"departureDate"} />
        <DateField source={"returnDate"} />
        <DateField source={"authorizationDate"} />
        <SelectField
          source={"authorizationType"}
          // label="Employee"
          choices={[
            { id: "Accordé", name: translate('labels.approved') },
            { id: "Non accordé", name: translate('labels.denied') },
          ]}
        />

        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const VacationCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/vacations/");
    refresh();
  };
  return (
    <Create
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center">
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.demandevacation')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"10px"}
        >
          <DateInput source={"requestDate"} validate={[required("Champ obligatoire")]} /*label="Date de demande"*/ />
          <ReferenceInput
            source={"employee"}
            reference="employees"
            perPage={1000}

          >
            <AutocompleteInput optionText={fullName} /* label="Conducteur" */ filterToQuery={filterToQuery} validate={[required("Champ obligatoire")]} />
          </ReferenceInput>
          <DateInput source={"departureDate"} validate={[required("Champ obligatoire")]} />
          <DateInput source={"returnDate"} validate={[required("Champ obligatoire")]} />

        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.situationcongepayees')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"10px"}
        >
          <NumberInput source={"remainingDaysPreviousYear"} />
          <NumberInput source={"remainingDaysCurrentYear"} />
          <NumberInput source={"requestedLeaveDays"} />
          <NumberInput source={"newPaidLeaveBalance"} />

        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.authorisationhr')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"10px"}
        >
          <DateInput source={"authorizationDate"} />
          <SelectInput
            source={"authorizationType"}
            // label="Employee"
            choices={[
              { id: "Accordé", name: translate('labels.approved') },
              { id: "Non accordé", name: translate('labels.denied') },
            ]}
          />

        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.directionrh')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"10px"}
        >
          <DateInput source={"hrApprovalDate"} />

        </Box>

      </SimpleForm>
    </Create>
  );
};

interface VacationTitleProps {
  record: RaRecord;
}


export const VacationEdit: React.FC<VacationTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/vacations/");
    refresh();
  };
  return (
    <Edit
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.demandevacation')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"10px"}
        >
          <DateInput source={"requestDate"} validate={[required("Champ obligatoire")]} /*label="Date de demande"*/ />
          <ReferenceInput
            source={"employee"}
            reference="employees"
            perPage={1000}
            validate={[required("Champ obligatoire")]}
          >
            <AutocompleteInput optionText={fullName} /* label="Conducteur" */ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <DateInput source={"departureDate"} validate={[required("Champ obligatoire")]} />
          <DateInput source={"returnDate"} validate={[required("Champ obligatoire")]} />

        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.situationcongépayées')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"10px"}
        >
          <NumberInput source={"remainingDaysPreviousYear"} />
          <NumberInput source={"remainingDaysCurrentYear"} />
          <NumberInput source={"requestedLeaveDays"} />
          <NumberInput source={"newPaidLeaveBalance"} />

        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.Authorisationhr')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"10px"}
        >
          <DateInput source={"authorizationDate"} />
          <SelectInput
            source={"authorizationType"}
            // label="Employee"
            choices={[
              { id: "Accordé", name: translate('labels.approved') },
              { id: "Non accordé", name: translate('labels.denied') },
            ]}
          />

        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.directionrh')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
          mb={"10px"}
        >
          <DateInput source={"hrApprovalDate"} />

        </Box>

      </SimpleForm>
    </Edit>
  );
};

export const VacationShow: React.FC<VacationTitleProps> = (props) => {
  const translate = useTranslate();

  return (
    <Show
      actions={<EditAction />}
      sx={{ fontSize: "20px" }}
      {...props}>
      <SimpleShowLayout>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <Labeled>
            <DateField source={"requestDate"} /*label="Date de demande"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <WrapperField source="employee">
            <Box display="flex" fontWeight="bold">
                <TextField source={"employee.lastName"} />
                &nbsp;
                <TextField source={"employee.firstName"} />
              </Box>
            </WrapperField>
          </Labeled>
          <Labeled>
            <DateField source={"departureDate"} /*label="Date de départ"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <DateField source={"returnDate"} /*label="Date de reprise"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <NumberField source={"remainingDaysPreviousYear"} /*label="remainingDaysFromPreviousYear"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <NumberField source={"remainingDaysCurrentYear"} /*label="remainingDaysCurrentYear"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <NumberField source={"requestedLeaveDays"} fontWeight="bold" />
          </Labeled>
          <Labeled>
            <NumberField source={"newPaidLeaveBalance"} /*label="newPaidLeaveBalance"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <DateField source={"authorizationDate"} /*label="authorizationDate"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <SelectField
              source={"authorizationType"}
              // label="Employee"
              choices={[
                { id: "Accordé", name: translate('labels.approved') },
                { id: "Non accordé", name: translate('labels.denied') },
              ]}
            />
          </Labeled>
          <Labeled>
            <DateField source={"hrApprovalDate"} /*label="hrApprovalDate"*/ fontWeight="bold" />
          </Labeled>

        </Box>

      </SimpleShowLayout>
    </Show>
  )
};
