import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  ImageField,
  ImageInput,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  ReferenceField,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  required,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';
import { formatDate } from "../utils";

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const exporter = (tires: any) => {
  const tiresForExport = tires.map((tire: any) => {
    if (tire.date) {
      tire.date = formatDate(tire.date);
    };
    const {
      "@id": arobasVehicleId,
      "@type": arobasVehicleType,
      createdAt: vehicleCreatedAt,
      site: vehicleSite,
      operator: vehicleOperator,
      updatedAt: vehicleUpdatedAt,
      gear: vehicleGear,
      ...vehicleForExport
    } = tire.vehicle;
    tire.vehicle = vehicleForExport;
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      originId,
      site,
      updatedAt,
      biNumber,
      dimension,
      images,
      mileage,
      serialNumber,
      createdAt,
      tire: tireTire,
      ...tireForExport
    } = tire; // omit backlinks and author
    return tireForExport;
  });
  jsonexport(
    tiresForExport,
    {
      headers: [
        "date",
        "vehicle.parkingNumber",
        "vehicle.brand",
        "interventionType",
        "type",
        "price"
      ],
      rename: [
        "Date du controle",
        "Numero de parc",
        "Marque",
        "Type de controle",
        "Type de pneu",
        "Prix"
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Pneumatiques"); // download as 'posts.csv` file
    }
  );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;
const VehicleInfo = (choice: { parkingNumber: string }) =>
  `${choice.parkingNumber}`;
const VehicleTires = (choice: { tires: string }) => `${choice.tires} `;

const TireListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const TireFilters = [
  <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const TireList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<TireListActions />}
      title={translate('titles.Pneumatiques')}
      filters={TireFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField locales="fr-FR" source={"date"} /*label={"Date du contrôle"}*/ />
        <WrapperField source="vehicle" /*label="Véhicule"*/ sortBy={"vehicle.parkingNumber"}>
          <TextField source={"vehicle.parkingNumber"} />
        </WrapperField>
        <SelectField source={"interventionType"} /*label={"Type de contrôle"}*/ choices={[
          { id: "Reparation", name: translate('labels.Réparation') },
          { id: "Changement", name: translate('labels.Changement') },
        ]} />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const TireCreate: React.FC = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/vehicle-tire-histories/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.addtire')}
      mutationOptions={{ onSuccess }}
      {...props}
    >
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput
            source={"date"}
            //label="Date d'intervention"
            validate={[required("Champ obligatoire")]}
          />
          <ReferenceInput source={"vehicle"} reference="vehicles" perPage={1000}>
            <AutocompleteInput
              optionText={VehicleInfo}
              //label="Véhicule"
              validate={[required("Champ obligatoire")]}
              filterToQuery={filterToQuery}
            />
            {/* <AutocompleteInput optionText={VehicleInfo} label="Pneu" validate={[required("Champ obligatoire")]}/> */}
          </ReferenceInput>
          <ReferenceInput source="tire" reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText="tires" label={translate('labels.tire')} disabled />
          </ReferenceInput>
          <SelectInput
            source={"interventionType"}
            //label="Type d'intervention"
            choices={[
              { id: "Reparation", name: translate('labels.Réparation') },
              { id: "Changement", name: translate('labels.Changement') },
            ]}
          />

          <NumberInput source={"mileage"} /*label="Kilométrage"*/ />
          <TextInput source={"serialNumber"} /*label="N° de série"*/ />

          <TextInput source={"biNumber"} /*label="Numéro de BL"*/ />
          <TextInput source={"dimension"} /*label="Dimensions"*/ />
          <NumberInput source={"price"} /*label="Prix"*/ />
          <SelectInput
            source={"type"}
            //label="Type de pneu"
            choices={[
              { id: "Original", name: translate('labels.Original') },
              { id: "Rechapé", name: translate('labels.Rechapé') },
            ]}
          />
          <SelectInput
            source={"tire"}
            //label="Position du pneu"
            choices={[
              { id: "AVG", name: "AVG" },
              { id: "AVD", name: "AVD" },
              { id: "ARG EXT1", name: "ARG EXT1" },
              { id: "ARG INT1", name: "ARG INT1" },
              { id: "ARD EXT1", name: "ARD EXT1" },
              { id: "ARD INT1", name: "ARD INT1" },
              { id: "ARG EXT2", name: "ARG EXT2" },
              { id: "ARG INT2", name: "ARG INT2" },
              { id: "ARD EXT2", name: "ARD EXT2" },
              { id: "ARD INT2", name: "ARD INT2" },
            ]}
          />
        </Box>
        <ImageInput source={"images"} /*label="Images"*/ accept="image/*" multiple>
          <ImageField source={"src"} />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

interface TireTitleProps {
  record: RaRecord;
}

const TireTitle: React.FC<TireTitleProps> = ({ record }) => {
  return <span>Pneumatique {record ? `"${record.title}"` : ""}</span>;
};

export const TireEdit: React.FC<TireTitleProps> = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`L'élément a bien été modifié`);
    redirect("/vehicle-tire-histories/");
    refresh();
  };
  return (
    <Edit
      title={<TireTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}
    >
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} /*label="Date d'intervention"*/ />
          <ReferenceInput
            source={"vehicle[@id]"}
            reference="vehicles"
            perPage={1000}
          >
            <AutocompleteInput
              optionText={VehicleInfo}
              //label="Véhicule"
              validate={[required("Champ obligatoire")]}
              filterToQuery={filterToQuery}
            />
          </ReferenceInput>
          <ReferenceInput source={"tire"} reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText="tires" label={translate('labels.tire')} readOnly />
          </ReferenceInput>
          <SelectInput
            source={"interventionType"}
            //label="Type d'intervention"
            choices={[
              { id: "Reparation", name: translate('labels.Réparation') },
              { id: "Changement", name: translate('labels.Changement') },
            ]}
          />

          <NumberInput source={"mileage"} /*label="Kilométrage"*/ />
          <TextInput source={"serialNumber"} /*label="N° de série"*/ />

          <TextInput source={"biNumber"} /*label="Numéro de BL"*/ />
          <TextInput source={"dimension"} /*label="Dimensions"*/ />
          <NumberInput source={"price"} /*label="Prix"*/ />
          <SelectInput
            source={"type"}
            //label="Type de pneu"
            choices={[
              { id: "Original", name: translate('labels.Original') },
              { id: "Rechapé", name: translate('labels.Rechapé') },
            ]}
          />
          <SelectInput
            source={"tire"}
            //label="Position du pneu"
            choices={[
              { id: "AVG", name: "AVG" },
              { id: "AVD", name: "AVD" },
              { id: "ARG EXT1", name: "ARG EXT1" },
              { id: "ARG INT1", name: "ARG INT1" },
              { id: "ARD EXT1", name: "ARD EXT1" },
              { id: "ARD INT1", name: "ARD INT1" },
              { id: "ARG EXT2", name: "ARG EXT2" },
              { id: "ARG INT2", name: "ARG INT2" },
              { id: "ARD EXT2", name: "ARD EXT2" },
              { id: "ARD INT2", name: "ARD INT2" },
            ]}
          />
        </Box>
        <ImageInput source={"images"} /*label="Images"*/ accept="image/*" multiple>
          <ImageField source={"src"} />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export const TireShow: React.FC<TireTitleProps> = (props) => (
  <Show
    actions={<EditAction />}
    title={<TireTitle record={props.record} />}
    {...props}
    sx={{ fontSize: "20px" }}
  >
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%"
      >
        <DateField
          locales="fr-FR"
          source={"date"}
          //label="Date d'intervention"
          fontWeight="bold"
        />
        <Labeled label="Véhicule">
          <Box display="flex" fontWeight="bold">
            <TextField source={"vehicle.parkingNumber"} fontWeight="bold" />
            &nbsp;-&nbsp;
            <TextField source={"vehicle.brand"} fontWeight="bold" />
            &nbsp;
            <TextField source={"vehicle.model"} fontWeight="bold" />
          </Box>
        </Labeled>
        <Labeled label="Type d'intervention">
          <TextField
            source={"interventionType"}
            //label="Type d'intervention"
            fontWeight="bold"
          />
        </Labeled>

        <Labeled label="Kilométrage">
          <NumberField
            source={"mileage"}
            //label="Kilométrage"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="N° de série">
          <TextField
            source={"serialNumber"}
            //label="N° de série"
            fontWeight="bold"
          />
        </Labeled>

        <Labeled label="Numéro de BL">
          <TextField
            source={"biNumber"}
            //label="Numéro de BL"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Dimensions">
          <TextField
            source={"dimension"}
            //label="Dimensions"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Prix">
          <NumberField source={"price"} /*label="Prix"*/ fontWeight="bold" />
        </Labeled>
        <Labeled label="Type de pneu">
          <TextField source={"type"} /*label="Type de pneu"*/ fontWeight="bold" />
        </Labeled>
        <Labeled label="Position du pneu">
          <TextField source={"tire"} /*label="Position du pneu"*/ fontWeight="bold" />
        </Labeled>
      </Box>
      <Labeled /*label="Images"*/ marginTop="16px">
        <ImageField
          source={"images"}
          src={"contentUrl"}
          //label="Image"
          sx={{
            padding: "0",
          }}
        />
      </Labeled>
    </SimpleShowLayout>
  </Show>
);
