import { Box, Chip } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  FunctionField,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
const filterToQuery = (searchText: string) => ({ query: `${searchText}` });


const exporter = (drains: any) => {
  const drainsForExport = drains.map((drain: any) => {
    console.log("drain", drain);
    const {
      "@id": arobasVehicleId,
      "@type": arobasVehicleType,
      createdAt,
      site: vehicleSite,
      operator: vehicleOperator,
      updatedAt: vehicleUpdatedAt,
      ...vehicleForExport
    } = drain.vehicle;
    drain.vehicle = vehicleForExport;
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      originId,
      site,
      updatedAt,
      ...drainForExport
    } = drain; // omit backlinks and author
    return drainForExport;
  });
  jsonexport(
    drainsForExport,
    {
      headers: [
        "date",
        "vehicle.parkingNumber",
        "vehicle.brand",
        "vehicle.model",
        "refundAmount",
        "createdAt",
      ],
      rename: [
        "Date de vidange",
        "Numéro de parc",
        "Marque",
        "Modèle",
        "Montant de remboursement (MAD)",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Vidanges"); // download as 'posts.csv` file
    }
  );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;
const VehicleInfo = (choice: { parkingNumber: string }) =>
  `${choice.parkingNumber}`;

const DrainListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      {/* <ExportButton /> */}
    </TopToolbar>
  );
};
const DrainFilters = [
  <TextInput label="Recherche" source="query" alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const DrainList: React.FC = (props) => {
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<DrainListActions />}
      title="Vidanges"
      filters={DrainFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        {/* <DateField locales="fr-FR"source="date" label={"Date de vidange"} /> */}
        <TextField source="vehicle.parkingNumber" label="Véhicule" />
        <FunctionField source={"timeMeter"} label={"Heures moteur du vidange"} textAlign="right"
          render={(record: any) =>
            record.timeMeter ? record.timeMeter : "-"
          } />
        <FunctionField source={"mileage"} label={"Kilométrage du vidange"} textAlign="right"
          render={(record: any) =>
            record.mileage ? record.mileage : "-"
          } />
        <FunctionField source={"vehicle.currentTimeMeter"} label={"Heures moteur actuel"} textAlign="right"
          render={(record: any) =>
            record.vehicle.currentTimeMeter ? record.vehicle.currentTimeMeter : "-"
          } />
        <FunctionField source={"vehicle.currentMileage"} label={"Kilométrage actuel"} textAlign="right"
          render={(record: any) =>
            record.vehicle.currentMileage ? record.vehicle.currentMileage : "-"
          } />
        <FunctionField
          source={"status"}
          label={"Statut"}
          render={(record: any) => {
            if (record.status === "yes") return <Chip color="success" size="small" label="Effectué" />;
            else if (record.status === "expired") return <Chip color="error" size="small" label="En retard" />;
            else if (record.status === "no") return <Chip size="small" label="Non effectué" />;
            else if (record.status === "todo") return <Chip color="warning" size="small" label="À venir" />;
            else return <></>;
          }}
        />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton label="Modifier" />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const DrainCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/vehicle-drain-histories/");
    refresh();
  };
  return (
    <Create
      title="Ajouter un vidange"
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          {/* <DateInput source={"date"} label="Date de vidange" /> */}
          <ReferenceInput source="vehicle" reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText={VehicleInfo} label="Véhicule" filterToQuery={filterToQuery} />
          </ReferenceInput>
          <NumberInput source="timeMeter" label="Heures moteur" />
          <NumberInput source="mileage" label="Kilométrage" />
          <SelectInput source="status" label="Statut" choices={[
            { id: "no", name: "Non effectué" },
            { id: "yes", name: "Effectué" },]} />
        </Box>
      </SimpleForm>
    </Create>
  );
};

interface DrainTitleProps {
  record: RaRecord;
}

const DrainTitle: React.FC<DrainTitleProps> = ({ record }) => {
  return <span>Drain {record ? `"${record.title}"` : ""}</span>;
};

export const DrainEdit: React.FC<DrainTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`L'élément a bien été modifié`);
    redirect("/vehicle-drain-histories/");
    refresh();
  };
  return (
    <Edit
      title={<DrainTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          {/* <DateInput source={"date"} label="Date de vidange" /> */}
          <ReferenceInput source="vehicle[@id]" reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText={VehicleInfo} label="Véhicule" filterToQuery={filterToQuery} />
          </ReferenceInput>
          <NumberInput source="timeMeter" label="Heures moteur" />
          <NumberInput source="mileage" label="Kilométrage" />
          <SelectInput source="status" label="Statut" choices={[
            { id: "no", name: "Non effectué" },
            { id: "yes", name: "Effectué" },]} />
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export const DrainShow: React.FC<DrainTitleProps> = (props) => (
  <Show
    actions={<EditAction />}
    title={<DrainTitle record={props.record} />}
    {...props}
    sx={{ fontSize: "20px" }}>
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%">
        <Labeled label="Date de vidange">
          <DateField
            locales="fr-FR"
            showTime
            source={"date"}
            label="Date de vidange"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Véhicule">
          <Box display="flex">
            <TextField source="vehicle.parkingNumber" fontWeight="bold" />
            &nbsp;-&nbsp;
            <TextField source="vehicle.brand" fontWeight="bold" />
            &nbsp;
            <TextField source="vehicle.model" fontWeight="bold" />
          </Box>
        </Labeled>
        <Labeled label="Montant de remboursement">
          <TextField
            source="refundAmount"
            label="Montant de remboursement"
            fontWeight="bold"
          />
        </Labeled>
      </Box>
    </SimpleShowLayout>
  </Show>
);
