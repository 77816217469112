import decodeJwt from "jwt-decode";

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(
      `${process.env.REACT_APP_API_ENTRYPOINT}/login-check`,
      {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token }) => {
        const decodedToken = decodeJwt(token);
        // console.log("decodedToken", decodedToken);
        localStorage.clear();
        localStorage.setItem("gridState", "table");
        localStorage.setItem("token", token);
        localStorage.setItem("permissions", decodedToken.roles);
        localStorage.setItem("firstName", decodedToken.firstName);
        localStorage.setItem("lastName", decodedToken.lastName);
        localStorage.setItem("siteId", decodedToken.site?.id);
        localStorage.setItem("siteLogo", decodedToken.site?.logo);
        // console.log("Token decoded and saved to storage");
      });
  },
  logout: () => {
    localStorage.clear();
    // localStorage.removeItem("token");
    // localStorage.removeItem("permissions");
    return Promise.resolve();
  },
  checkAuth: () => localStorage.getItem("token") ? Promise.resolve() : Promise.reject()
  ,
  checkError: (error) => {
    const status = error?.response?.status;

    if (status === 401 || status === 403) {
      console.log("checkError: 401 or 403")
      localStorage.removeItem("token");
      localStorage.removeItem("permissions");
      return Promise.reject(error);
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
