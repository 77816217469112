import { Box, Typography } from "@mui/material";

export const Blocked = () => (
  <Box textAlign="center" height="100%" my="200px" mx="auto" display="inline-flex" flexDirection="column" gap="32px">
    <Typography variant="h6" paragraph>
      Votre compte est temporairement suspendu.<br/>Veuillez contacter notre support pour rétablir l'accès.
    </Typography>
    <Typography variant="h1" paragraph>
      🔒
    </Typography>

  </Box>
);
