import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TimeInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  required
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import { useEffect, useState } from "react";

const exporter = (workAccidents: any) => {
  const workAccidentsForExport = workAccidents.map((workAccident: any) => {
    if (workAccident.dueDate) {
      workAccident.dueDate = workAccident.dueDate.slice(0, 10);
    };
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      createdAt,
      ...workAccidentForExport
    } = workAccident;
    return workAccidentForExport;
  });
  jsonexport(
    workAccidentsForExport,
    {
      headers: [
        "accidentDate",
        "accidentType",
        "victim['firstName']",
        "victim['lastName']",

      ],
      rename: [
        "Date d’accident",
        "Type d’accident",
        "Prenom",
        "Nom",

      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "workAccident"); // download as 'posts.csv` file
    }
  );
};

const RecoveryDateField: React.FC<{ accidentDate: string; numberOfDaysOff: string }> = ({ accidentDate, numberOfDaysOff }) => {
  const record = useRecordContext();
  
  if (!record) return null;

  const accidentDateObj = new Date(record[accidentDate]);
  const recoveryDate = new Date(accidentDateObj);
  recoveryDate.setDate(accidentDateObj.getDate() + (record[numberOfDaysOff] || 0));

  const formattedDate = new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
  }).format(recoveryDate);

  return <Typography>{formattedDate}</Typography>;
};

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const fullName = (choice: { firstName: string; lastName: string }) =>
  `${choice.firstName} ${choice.lastName}`;

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const WorkAccidentListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const WorkAccidentFilters = [
  <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const WorkAccidentList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<WorkAccidentListActions />}
      filters={WorkAccidentFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField source={"accidentDate"} /*label="Matricule"*/ />
        <SelectField
          source={"accidentType"}
          // label="AccidentType"
          choices={[
            { id: "Mortel", name: translate('Mortel') },
            { id: "Avec arrêt", name: translate('Avec arrêt') },
            { id: "Sans arrêt", name: translate('Sans arrêt') },
            { id: "Presque accident", name: translate('Presque accident') },
            { id: "Accident de trajet", name: translate('Accident de trajet') },

          ]}
        />
        <WrapperField source="victim" sortBy="victim.lastName">
          <TextField source={"victim.lastName"} />
          &nbsp;
          <TextField source={"victim.firstName"} />
        </WrapperField>
        <RecoveryDateField accidentDate="accidentDate" numberOfDaysOff="NumberOfDaysOff" />


        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const WorkAccidentCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();
  const [accidentDate, setAccidentDate] = useState<string | null>(null);
  const [numberOfDaysOff, setNumberOfDaysOff] = useState<number>(0);
  const [returnDate, setReturnDate] = useState<string | null>(null);

    useEffect(() => {
        if (accidentDate && numberOfDaysOff !== null) {
          console.log("accidentDate", accidentDate)
          console.log("numberOfDaysOff",numberOfDaysOff)
            const accidentDateObj = new Date(accidentDate);
            const newReturnDate = new Date(accidentDateObj);
            newReturnDate.setDate(accidentDateObj.getDate() + numberOfDaysOff);
            setReturnDate(newReturnDate.toISOString().split('T')[0]); // Format as yyyy-mm-dd
            console.log("returnDate",returnDate)
        }
    }, [accidentDate, numberOfDaysOff]);

  
  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/work-accidents/");
    refresh();
  };
  return (
    <Create
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
            <DateInput
                source="accidentDate"
                label="Accident Date"
                onChange={(e) => setAccidentDate(e.target.value)}
                validate={[required("Champ obligatoire")]}
            />
            <SelectInput
              source={"accidentType"}
              validate={[required("Champ obligatoire")]}
              // label="AccidentType"
              choices={[
                { id: "Mortel", name: translate('Mortel') },
                { id: "Avec arrêt", name: translate('Avec arrêt') },
                { id: "Sans arrêt", name: translate('Sans arrêt') },
                { id: "Presque accident", name: translate('Presque accident') },
                { id: "Accident de trajet", name: translate('Accident de trajet') },
            
              ]}
            />
            <ReferenceInput
              source={"victim"}
              reference="employees"
              perPage={1000}
              
            >
              <AutocompleteInput optionText={fullName} /* label="Conducteur" */ filterToQuery={filterToQuery} validate={[required("Champ obligatoire")]} />
            </ReferenceInput>
            <NumberInput
                source="numberOfDaysOff"
                label="Number of Days Off"
                onChange={(e) => setNumberOfDaysOff(parseInt(e.target.value, 10) || 0)}
            />
            <DateInput
                source="returnDate"
                label="Return Date"
                value={returnDate}
                disabled
            />
            
            <TextInput source={"circumstance"} />
            <TextInput source={"lesion"} />
            <SelectInput
              source={"typology"}
              // label="Typologie"
              choices={[
                { id: "Blessure par déchets", name: 'Blessure par déchets' },
                { id: "Blessure par objet", name: 'Blessure par objet' },
                { id: "Chute de plain-pied", name: 'Chute de plain-pied' },
                { id: "Heurt par objet", name: 'Heurt par objet' },
                { id: "Manipulation de matériel", name: 'Manipulation de matériel' },
            
              ]}
            />

        </Box>
      </SimpleForm>
    </Create>
  );
};

interface WorkAccidentTitleProps {
  record: RaRecord;
}

export const WorkAccidentEdit: React.FC<WorkAccidentTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const [accidentDate, setAccidentDate] = useState<string | null>(null);
  const [numberOfDaysOff, setNumberOfDaysOff] = useState<number>(0);
  const [returnDate, setReturnDate] = useState<string | null>(null);

    useEffect(() => {
        if (accidentDate && numberOfDaysOff !== null) {
            const accidentDateObj = new Date(accidentDate);
            const newReturnDate = new Date(accidentDateObj);
            newReturnDate.setDate(accidentDateObj.getDate() + numberOfDaysOff);
            setReturnDate(newReturnDate.toISOString().split('T')[0]); // Format as yyyy-mm-dd
        }
    }, [accidentDate, numberOfDaysOff]);

  

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/work-accidents/");
    refresh();
  };
  return (
    <Edit
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput
                source="accidentDate"
                label="Accident Date"
                onChange={(e) => setAccidentDate(e.target.value)}
                validate={[required("Champ obligatoire")]}
            />
            <SelectInput
              source={"accidentType"}
              // label="AccidentType"
              choices={[
                { id: "Mortel", name: translate('Mortel') },
                { id: "Avec arrêt", name: translate('Avec arrêt') },
                { id: "Sans arrêt", name: translate('Sans arrêt') },
                { id: "Presque accident", name: translate('Presque accident') },
                { id: "Accident de trajet", name: translate('Accident de trajet') },
            
              ]}
            />
            <ReferenceInput
              source={"victim"}
              reference="employees"
              perPage={1000}
            >
              <AutocompleteInput optionText={fullName} /* label="Conducteur" */ filterToQuery={filterToQuery} />
            </ReferenceInput>
            <NumberInput
                source="numberOfDaysOff"
                label="Number of Days Off"
                onChange={(e) => setNumberOfDaysOff(parseInt(e.target.value, 10) || 0)}
            />
            <DateInput
                source="returnDate"
                label="Return Date"
                value={returnDate}
                disabled
            />
            
            <TextInput source={"circumstance"} />
            <TextInput source={"lesion"} />
            <SelectInput
              source={"typology"}
              // label="Typologie"
              choices={[
                { id: "Blessure par déchets", name: 'Blessure par déchets' },
                { id: "Blessure par objet", name: 'Blessure par objet' },
                { id: "Chute de plain-pied", name: 'Chute de plain-pied' },
                { id: "Heurt par objet", name: 'Heurt par objet' },
                { id: "Manipulation de matériel", name: 'Manipulation de matériel' },
            
              ]}
            />

        </Box>

      </SimpleForm>
    </Edit>
  );
};

export const WorkAccidentShow: React.FC<WorkAccidentTitleProps> = (props) => {
  const translate = useTranslate()

  return(
  <Show
    actions={<EditAction />}
    sx={{ fontSize: "20px" }}
    {...props}>
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%"
      >

        <Labeled>
          <DateField source={"accidentDate"} /*label="Accident Date"*/ fontWeight="bold" />
        </Labeled>
        <Labeled>
          <SelectField
                source={"accidentType"}
                // label="AccidentType"
                choices={[
                  { id: "Mortel", name: 'Mortel' },
                  { id: "Avec arrêt", name: 'Avec arrêt' },
                  { id: "Sans arrêt", name: 'Sans arrêt' },
                  { id: "Presque accident", name: 'Presque accident' },
                  { id: "Accident de trajet", name: 'Accident de trajet' },
                
                ]}
              />
        </Labeled>
        <Labeled>
          <WrapperField source="victim">
            <Box display={"flex"}>
              <TextField source={"victim.lastName"} />
              &nbsp;
              <TextField source={"victim.firstName"} />
            </Box>
          </WrapperField>
        </Labeled>
        <Labeled>
            <NumberField source={"numberOfDaysOff"} /*label="Number Of Days Off"*/ fontWeight="bold" />
        </Labeled>
        <Labeled>
          <RecoveryDateField accidentDate="accidentDate" numberOfDaysOff="NumberOfDaysOff" />
        </Labeled>
        <Labeled>
            <TextField source={"circumstance"} /*label="Circumstance"*/ fontWeight="bold" />
        </Labeled>
        <Labeled>
            <TextField source={"lesion"} /*label="Lesion"*/ fontWeight="bold" />
        </Labeled>
        <Labeled>
        <SelectField
              source={"typology"}
              // label="Typologie"
              choices={[
                { id: "Blessure par déchets", name: 'Blessure par déchets' },
                { id: "Blessure par objet", name: 'Blessure par objet' },
                { id: "Chute de plain-pied", name: 'Chute de plain-pied' },
                { id: "Heurt par objet", name: 'Heurt par objet' },
                { id: "Manipulation de matériel", name: 'Manipulation de matériel' },
            
              ]}
            />
        </Labeled>

      </Box>

    </SimpleShowLayout>
  </Show>
)};
