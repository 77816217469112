import Box from "@mui/material/Box";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslate } from 'react-admin';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  legend: {
    display: false, //This will do the task
  },
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      suggestedMin: 0,
      suggestedMax: 100,
    },
  },
};

export function DisponibilityLine({ data }: { data: any }) {
  const translate = useTranslate();
  const textcost = translate('labels.availability30days');
  const dispoData = data.reduce((acc: any[], current: any) => {
    acc.push(current.value);
    return acc;
  }, [])
  const immoData = dispoData.map((value: any) => 100 - value);
  const config = {
    labels: data.reduce((acc: any[], current: any) => {
      const date = new Date(current.date);
      acc.push(date.getDate() + "/" + (date.getMonth() + 1));
      return acc;
    }, []),
    datasets: [
      {
        data: dispoData,
        borderColor: "rgba(103, 165, 135, 1)",
        backgroundColor: "rgba(103, 165, 135, 0.5)",
        label: textcost,
      },
      // {
      //   data: immoData,
      //   borderColor: "#ef4444",
      //   backgroundColor: "#fca5a5",
      //   label: "Immo des 30 derniers jours (en %)",
      // },
    ],
  };

  return (
    <Box>
      <Line options={options} data={config} />
    </Box>
  );
}
