import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
import { useEffect } from 'react';
import {
  VehicleCreate,
  VehicleEdit,
  VehicleList,
  VehicleShow,
} from "./resources/vehicles";
import authProvider from "./services/authProvider";
import { myDataProvider as dataProvider } from "./services/dataProvider";
import isGranted from "./services/security";
//import { dataProvider } from "./services/dataProvider";
import { CustomRoutes, Login, useRedirect } from "react-admin";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { Blocked } from "./components/Blocked";
import { MyLayout } from "./components/MyLayout";
import { MyTheme } from "./components/MyTheme";
import entrypoint from "./config/params";
import { i18nProvider } from "./i18nProvider";
import { CardCreate, CardEdit, CardList, CardShow } from "./resources/cards";
import {
  CleaningCreate,
  CleaningEdit,
  CleaningList,
  CleaningShow,
} from "./resources/cleaning";
import {
  CollectingCreate,
  CollectingEdit,
  CollectingList,
  CollectingShow,
} from "./resources/collecting";
import { ContainerMaintenanceCreate, ContainerMaintenanceEdit, ContainerMaintenanceList, ContainerMaintenanceShow } from "./resources/containerMaintenances";
import { ContainerReformCreate, ContainerReformEdit, ContainerReformList, ContainerReformShow } from "./resources/containerReforms";
import { ContainerCreate, ContainerEdit, ContainerList, ContainerShow } from "./resources/containers";
import {
  ControlCreate,
  ControlEdit,
  ControlList,
  ControlShow,
} from "./resources/controls";
import Dashboard from "./resources/dashboard";
import DashboardContainers from "./resources/dashboardContainers";
import DashboardHr from "./resources/DashboardHr";
import { DrainCreate, DrainEdit, DrainList, DrainShow } from "./resources/drains";
import {
  EmployeeCreate,
  EmployeeEdit,
  EmployeeList,
  EmployeeShow,
} from "./resources/employees";
import {
  FuelAllocationCreate,
  FuelAllocationEdit,
  FuelAllocationList,
  FuelAllocationShow,
} from "./resources/fuelAllocations";
import { FuelCreate, FuelEdit, FuelList, FuelShow } from "./resources/fuels";
import { FuelSummaryList } from "./resources/fuelsSummary";
import {
  FundingCreate,
  FundingEdit,
  FundingList,
  FundingShow,
} from "./resources/fundings";
import {
  ImmobilityCreate,
  ImmobilityEdit,
  ImmobilityList,
  ImmobilityShow,
} from "./resources/immobilities";
import {
  IncidentCreate,
  IncidentEdit,
  IncidentList,
  IncidentShow,
} from "./resources/incidents";
import {
  InsuranceCreate,
  InsuranceEdit,
  InsuranceList,
  InsuranceShow,
} from "./resources/insurances";
import {
  InterventionCreate,
  InterventionEdit,
  InterventionList,
  InterventionShow,
} from "./resources/interventions";
import {
  MaintenanceHistoryCreate,
  MaintenanceHistoryEdit,
  MaintenanceHistoryList,
} from "./resources/maintenanceHistories";
import { MaintenanceList } from "./resources/maintenances";
import { MedicalVisitCreate, MedicalVisitEdit, MedicalVisitList, MedicalVisitShow } from "./resources/medicalVisits";
import {
  NeighborhoodCreate,
  NeighborhoodEdit,
  NeighborhoodList,
  NeighborhoodShow,
} from "./resources/neighborhood";
import { OvertimeCreate, OvertimeEdit, OvertimeList, OvertimeShow } from "./resources/overtime";
import { PresenceCreate, PresenceEdit, PresenceList, PresenceShow } from "./resources/presence";
import {
  SensitivePointCreate,
  SensitivePointEdit,
  SensitivePointList,
  SensitivePointShow,
} from "./resources/sensitivepoint";
import Sites from "./resources/sites";
import { ArticleCreate, ArticleEdit, ArticleList, ArticleShow } from "./resources/stockArticles";
import { StockMovementCreate, StockMovementEdit, StockMovementList, StockMovementShow } from "./resources/stockMovements";
import {
  StreetCreate,
  StreetEdit,
  StreetList,
  StreetShow,
} from "./resources/street";
import { SupplierCreate, SupplierEdit, SupplierList, SupplierShow } from "./resources/suppliers";
import { TaxCreate, TaxEdit, TaxList, TaxShow } from "./resources/taxes";
import { TireCreate, TireEdit, TireList, TireShow } from "./resources/tires";
import { TrafficCodeViolationCreate, TrafficCodeViolationEdit, TrafficCodeViolationList, TrafficCodeViolationShow } from "./resources/trafficCodeViolations";
import { TrainingCreate, TrainingEdit, TrainingList, TrainingShow } from "./resources/trainings";
import { VacationCreate, VacationEdit, VacationList, VacationShow } from "./resources/vacations";
import { WorkAccidentCreate, WorkAccidentEdit, WorkAccidentList, WorkAccidentShow } from "./resources/workAccidents";

const MyLoginPage = () => (
  <Login
    // A random image that changes everyday
    // backgroundImage="https://img.freepik.com/free-photo/man-working-as-truck-driver_23-2151489780.jpg?t=st=1721941697~exp=1721945297~hmac=365162db83be6c51435ea2cc6968bb395b94845c2c0c253209d6861caf85f7b7&w=1380"
    backgroundImage="https://www.radius.com/_next/image/?url=https%3A%2F%2Fcms-radius-com-bucket.s3.eu-west-2.amazonaws.com%2F878895980cd2584066c8bec17c208e97_a5cc856217.jpg&w=3840&q=75"
    sx={{ backgroundClip: "content-box", backgroundSize: "fit", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}
  />
);

function App() {
  const redirect = useRedirect();
  const siteId = localStorage.getItem("siteId");

  // Use useEffect to avoid infinite loop
  useEffect(() => {
    if (siteId === "28") {
      console.log('Redirecting to login');
      redirect("/blocked/");
    }
  }, [siteId, redirect]);  // Only run when siteId or redirect function changes

  return (
    <HydraAdmin
      entrypoint={entrypoint}
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={MyLayout}
      theme={MyTheme}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      loginPage={MyLoginPage}>
      {(permissions) => {
        return [
          <ResourceGuesser
            name="vehicles"
            list={VehicleList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? VehicleCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? VehicleEdit : <></>}
            show={VehicleShow}
          />,
          <ResourceGuesser
            name="vehicle-incident-histories"
            list={IncidentList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? IncidentCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? IncidentEdit : <></>}
            show={IncidentShow}
          />,
          <ResourceGuesser
            name="vehicle-control-histories"
            list={ControlList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? ControlCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? ControlEdit : <></>}
            show={ControlShow}
          />,
          <ResourceGuesser
            name="vehicle-funding-histories"
            list={FundingList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? FundingCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? FundingEdit : <></>}
            show={FundingShow}
          />,
          <ResourceGuesser
            name="vehicle-insurance-histories"
            list={InsuranceList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? InsuranceCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? InsuranceEdit : <></>}
            show={InsuranceShow}
          />,
          <ResourceGuesser
            name="vehicle-tax-histories"
            list={TaxList}
            create={isGranted("ROLE_MANAGER", permissions) ? TaxCreate : <></>}
            edit={isGranted("ROLE_MANAGER", permissions) ? TaxEdit : <></>}
            show={TaxShow}
          />,
          <ResourceGuesser
            name="vehicle-drain-histories"
            list={DrainList}
            create={isGranted("ROLE_MANAGER", permissions) ? DrainCreate : <></>}
            edit={isGranted("ROLE_MANAGER", permissions) ? DrainEdit : <></>}
            show={DrainShow}
          />,
          <ResourceGuesser
            name="vehicle-immobility-histories"
            list={ImmobilityList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? ImmobilityCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? ImmobilityEdit : <></>}
            show={ImmobilityShow}
          />,
          <ResourceGuesser
            name="vehicle-intervention-histories"
            list={InterventionList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? InterventionCreate : <></>
            }
            edit={
              isGranted("ROLE_MANAGER", permissions) ? InterventionEdit : <></>
            }
            show={InterventionShow}
          />,
          <ResourceGuesser
            name="vehicle-tire-histories"
            list={TireList}
            create={isGranted("ROLE_MANAGER", permissions) ? TireCreate : <></>}
            edit={isGranted("ROLE_MANAGER", permissions) ? TireEdit : <></>}
            show={TireShow}
          />,
          <ResourceGuesser
            name="vehicle-maintenance-histories"
            list={MaintenanceHistoryList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? (
                MaintenanceHistoryCreate
              ) : (
                <></>
              )
            }
            edit={
              isGranted("ROLE_MANAGER", permissions) ? (
                MaintenanceHistoryEdit
              ) : (
                <></>
              )
            }
          />,
          <ResourceGuesser
            name="containers"
            list={ContainerList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? (
                ContainerCreate
              ) : (
                <></>
              )
            }
            edit={
              isGranted("ROLE_MANAGER", permissions) ? ContainerEdit : <></>
            }
            show={ContainerShow}
          />,
          <ResourceGuesser
            name="container-reforms"
            list={ContainerReformList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? (
                ContainerReformCreate
              ) : (
                <></>
              )
            }
            edit={
              isGranted("ROLE_MANAGER", permissions) ? ContainerReformEdit : <></>
            }
            show={ContainerReformShow}
          />,
          <ResourceGuesser
            name="container-maintenances"
            list={ContainerMaintenanceList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? (
                ContainerMaintenanceCreate
              ) : (
                <></>
              )
            }
            edit={
              isGranted("ROLE_MANAGER", permissions) ? ContainerMaintenanceEdit : <></>
            }
            show={ContainerMaintenanceShow}
          />,
          <ResourceGuesser name="maintenance-cycles" list={MaintenanceList} />,
          <ResourceGuesser
            name="fuel-allocations"
            list={FuelAllocationList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? (
                FuelAllocationCreate
              ) : (
                <></>
              )
            }
            edit={
              isGranted("ROLE_MANAGER", permissions) ? FuelAllocationEdit : <></>
            }
            show={FuelAllocationShow}
          />,
          <ResourceGuesser
            name="cards"
            list={CardList}
            create={isGranted("ROLE_MANAGER", permissions) ? CardCreate : <></>}
            edit={isGranted("ROLE_MANAGER", permissions) ? CardEdit : <></>}
            show={CardShow}
          />,
          <ResourceGuesser
            name="fuel-consumptions"
            list={FuelList}
            create={isGranted("ROLE_MANAGER", permissions) ? FuelCreate : <></>}
            edit={isGranted("ROLE_MANAGER", permissions) ? FuelEdit : <></>}
            show={FuelShow}
          />,
          <ResourceGuesser
            name="stock-articles"
            list={ArticleList}
            create={isGranted("ROLE_MANAGER", permissions) ? ArticleCreate : <></>}
            edit={isGranted("ROLE_MANAGER", permissions) ? ArticleEdit : <></>}
            show={ArticleShow}
          />,
          <ResourceGuesser
            name="stock-movements"
            list={StockMovementList}
            create={isGranted("ROLE_MANAGER", permissions) ? StockMovementCreate : <></>}
            edit={isGranted("ROLE_MANAGER", permissions) ? StockMovementEdit : <></>}
            show={StockMovementShow}
          />,
          <ResourceGuesser
            name="suppliers"
            list={SupplierList}
            create={isGranted("ROLE_MANAGER", permissions) ? SupplierCreate : <></>}
            edit={isGranted("ROLE_MANAGER", permissions) ? SupplierEdit : <></>}
            show={SupplierShow}
          />,
          <ResourceGuesser
            name="employees"
            list={EmployeeList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? EmployeeCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? EmployeeEdit : <></>}
            show={EmployeeShow}
          />,
          <ResourceGuesser
            name="presences"
            list={PresenceList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? PresenceCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? PresenceEdit : <></>}
            show={PresenceShow}
          />,
          <ResourceGuesser
            name="overtimes"
            list={OvertimeList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? OvertimeCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? OvertimeEdit : <></>}
            show={OvertimeShow}
          />,
          <ResourceGuesser
            name="vacations"
            list={VacationList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? VacationCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? VacationEdit : <></>}
            show={VacationShow}
          />,
          <ResourceGuesser
            name="work-accidents"
            list={WorkAccidentList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? WorkAccidentCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? WorkAccidentEdit : <></>}
            show={WorkAccidentShow}
          />,
          <ResourceGuesser
            name="traffic-code-violations"
            list={TrafficCodeViolationList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? TrafficCodeViolationCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? TrafficCodeViolationEdit : <></>}
            show={TrafficCodeViolationShow}
          />,
          <ResourceGuesser
            name="medical-visits"
            list={MedicalVisitList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? MedicalVisitCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? MedicalVisitEdit : <></>}
            show={MedicalVisitShow}
          />,
          <ResourceGuesser
            name="trainings"
            list={TrainingList}
            create={
              isGranted("ROLE_MANAGER", permissions) ? TrainingCreate : <></>
            }
            edit={isGranted("ROLE_MANAGER", permissions) ? TrainingEdit : <></>}
            show={TrainingShow}
          />,
          <ResourceGuesser name="dashboard-stats" />,
          <CustomRoutes>
            <Route path="/sites" element={<Sites />} />
          </CustomRoutes>,
          <CustomRoutes>
            <Route path="/fuel-summary" element={<FuelSummaryList />} />
          </CustomRoutes>,
          <CustomRoutes>
            <Route path="/dashboard-containers" element={<DashboardContainers />} />
          </CustomRoutes>,
          <CustomRoutes>
            <Route path="/dashboard-hr" element={<DashboardHr />} />
          </CustomRoutes>,
          <CustomRoutes>
            <Route path="/blocked" element={<Blocked/>} />
          </CustomRoutes>,
          <ResourceGuesser
            name="neighborhoods"
            list={NeighborhoodList}
            create={isGranted("ROLE_MANAGER", permissions) ? NeighborhoodCreate : <></>}
            edit={isGranted("ROLE_MANAGER", permissions) ? NeighborhoodEdit : <></>}
            show={NeighborhoodShow}
          />,
          <ResourceGuesser
            name="streets"
            list={StreetList}
            create={isGranted("ROLE_MANAGER", permissions) ? StreetCreate : <></>}
            edit={isGranted("ROLE_MANAGER", permissions) ? StreetEdit : <></>}
            show={StreetShow}
          />,
          <ResourceGuesser
            name="sensitive-points"
            list={SensitivePointList}
            create={isGranted("ROLE_MANAGER", permissions) ? SensitivePointCreate : <></>}
            edit={isGranted("ROLE_MANAGER", permissions) ? SensitivePointEdit : <></>}
            show={SensitivePointShow}
          />,
          <ResourceGuesser
            name="collectings"
            list={CollectingList}
            create={isGranted("ROLE_MANAGER", permissions) ? CollectingCreate : <></>}
            edit={isGranted("ROLE_MANAGER", permissions) ? CollectingEdit : <></>}
            show={CollectingShow}
          />,
          <ResourceGuesser
            name="cleanings"
            list={CleaningList}
            create={isGranted("ROLE_MANAGER", permissions) ? CleaningCreate : <></>}
            edit={isGranted("ROLE_MANAGER", permissions) ? CleaningEdit : <></>}
            show={CleaningShow}
          />,
        ];
      }}
    </HydraAdmin>
  );
}

export default function Root() {
  return (
    <Router>
      <App />
    </Router>
  );
}
