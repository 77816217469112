
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import {
  Link,
  useListContext
} from "react-admin";

type Vehicle = {
  "@id": "vehicleId";
  id: number;
  parkingNumber: string;
  registrationNumber: string;
  gear: string;
  brand: string;
  model: string;
  images: any;
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const VehicleListGrid = ({ edit }: { edit: boolean }) => {
  const { data } = useListContext<Vehicle>();
  return (
    // <WithListContext<Vehicle>
    //   render={({ data }) => (
    <Grid container spacing={3}>
      {data.map((vehicle) => (
        <Grid item xs={12} md={6} lg={3} sx={{}}>
          <Link to={`/vehicles/${encodeURIComponent(vehicle["@id"])}/show`}>
            <Card
              variant="outlined"
              sx={{
                position: "relative",
                ":hover": {
                  boxShadow: 2,
                },
              }}>
              {vehicle.images.length > 0 ? (
                <>
                  <Box
                    component="img"
                    sx={{
                      height: 140,
                      width: "100%",
                      objectFit: "contain",
                      position: "absolute",
                      top: 0,
                      zIndex: 1,
                    }}
                    src={
                      vehicle.images.length > 0
                        ? vehicle.images[vehicle.images.length - 1].contentUrl
                        : require("../images/empty2.png")
                    }
                  />
                  <Box sx={{ overflow: "hidden", height: 140 }}>
                    <Box
                      component="img"
                      sx={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        filter: "blur(16px)",
                        WebkitFilter: "blur(16px)",
                      }}
                      src={
                        vehicle.images.length > 0
                          ? vehicle.images[vehicle.images.length - 1].contentUrl
                          : require("../images/empty2.png")
                      }
                    />
                  </Box>
                </>
              ) :  <Box
              component="img"
              sx={{
                height: 140,
                width: "100%",
                objectFit: "contain",
              }}
              src={
                vehicle.images.length > 0
                  ? vehicle.images[vehicle.images.length - 1].contentUrl
                  : require("../images/empty2.png")
              }
            />}
              <CardContent>
                <Typography
                  variant="h6"
                  fontWeight="700"
                  key={vehicle.id}
                  mb={1}>
                  {vehicle.parkingNumber}
                </Typography>
                <Typography variant="body2" color="text.secondary" mb={1}>
                  Équip. <strong>{vehicle.gear}</strong>
                </Typography>
                <Typography variant="body2" color="text.secondary" mb={1}>
                  Immatric. <strong>{vehicle.registrationNumber}</strong>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Modèle{" "}
                  <strong>
                    {vehicle.brand} {vehicle.model}
                  </strong>
                </Typography>
              </CardContent>
              <CardActions>
                {edit && (
                  <Button
                    href={`/vehicles/${encodeURIComponent(vehicle["@id"])}`}>
                    Modifier
                  </Button>
                )}

                {/* <EditButton label="Modifier" />
                &nbsp;
                <DeleteWithConfirmButton />
               */}
              </CardActions>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
    //   )}
    // />
  );
};
