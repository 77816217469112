import {
  fetchHydra as baseFetchHydra,
  hydraDataProvider as baseHydraDataProvider,
  useIntrospection,
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import { Navigate, Route } from "react-router-dom";
import entrypoint from "../config/params";
import { handleResourceCreate, handleResourceUpdate } from "../data/resource";

const getHeaders = () => {
  const headers = localStorage.getItem("token")
    ? {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    : {};

  //if (localStorage.getItem("site")) {
  // headers["X-Site"] = JSON.parse(localStorage.getItem("site"))["@id"];
  //}

  return headers;
};

const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem("token")) {
    introspect();
    return <></>;
  }
  return <Navigate to="/login" />;
};

const fetchHydra = (url, options = {}) => {
  return baseFetchHydra(url, {
    ...options,
    headers: getHeaders(),
  });
};

const apiDocumentationParser = () => async (entrypoint) => {
  try {
    return await parseHydraDocumentation(entrypoint, { headers: getHeaders });
  } catch (result) {
    const { api, response, status } = result;

    if (status === 401) {
      localStorage.removeItem("token");
      return Promise.reject(response);
    }

    return {
      api,
      response,
      status,
      customRoutes: [<Route path="/" component={RedirectToLogin} />],
    };
  }
};

const dataProvider = baseHydraDataProvider({
  entrypoint: entrypoint,
  httpClient: fetchHydra,
  apiDocumentationParser: apiDocumentationParser(function () {}),
  useEmbedded: true,
});

const myDataProvider = { ...dataProvider };

myDataProvider.create = async (resource, params) => {
  let data = params.data;
  data = await handleResourceCreate(params);
  return dataProvider.create(resource, {
    ...params,
    data: {
      ...data,
    },
  });
};

myDataProvider.update = async (resource, params) => {
  let data = params.data;
  data = await handleResourceUpdate(params);
  return dataProvider.update(resource, {
    ...params,
    data: {
      ...data,
    },
  });
};

export {
  apiDocumentationParser, dataProvider, myDataProvider, parseHydraDocumentation
};

