import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  Labeled,
  List,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TimeInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh,
  required,
  AutocompleteArrayInput,
  useDataProvider,
  ArrayField,
  SingleFieldList,
  ReferenceArrayInput,
  ReferenceArrayField,
  ChipField,
  FunctionField
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from "react-admin";
import { useEffect, useState } from "react";

const exporter = (trainings: any) => {
  const trainingsForExport = trainings.map((training: any) => {
    if (training.dueDate) {
      training.dueDate = training.dueDate.slice(0, 10);
    }
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      createdAt,
      ...trainingForExport
    } = training;
    return trainingForExport;
  });
  jsonexport(
    trainingsForExport,
    {
      headers: [
        "employee['firstName']",
        "employee['lastName']",
        "date",
        "situation",
      ],
      rename: ["Prenom", "Nom", "Date", "Situation"],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Trainings"); // download as 'trainings.csv` file
    }
  );
};

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const fullName = (choice: { firstName: string; lastName: string }) =>
  `${choice.firstName} ${choice.lastName}`;

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const TrainingListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};

const TrainingFilters = [
  <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const TrainingList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<TrainingListActions />}
      filters={TrainingFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField source={"trainingDate"} validate={[required("Champ obligatoire")]} /*label="Training Date"*/ />
        <TextField source={"trainingTitle"} validate={[required("Champ obligatoire")]} />
        <SelectField
          source={"typeOfTraining"}
          // label="typeOfTraining"
          choices={[
            { id: "accueil-securite", name: "Accueil sécurité" },
            { id: "recueil-securite", name: "Recueil sécurité" },
            { id: "formation-incendie", name: "Formation incendie" },
            { id: "gestes-postures", name: "Gestes et Postures" },
            { id: "sst-initial", name: "SST INITIAL" },
            { id: "sst-recyclage", name: "SST recyclage" },
            { id: "formation-metier-bom", name: "Formation métier BOM" },
            { id: "formation-metier-balayage", name: "Formation métier Balayage" },
            { id: "conduite-preventive", name: "Conduite préventive" },
            { id: "conduite-pro", name: "Conduite Pro" },
          ]}
        />
        


        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const TrainingCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();
  const [employeeChoices, setEmployeeChoices] = useState<any[]>([]);
  const dataProvider = useDataProvider();

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const { data } = await dataProvider.getList('employees', {
                  pagination: { page: 1, perPage: 1000 },
                  sort: { field: 'name', order: 'ASC' },
                  filter: {},  
              });
                setEmployeeChoices(data.map((employee: any) => ({ id: employee.id, name: employee.lastName })));
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };

        fetchEmployees();
    }, [dataProvider]);

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/trainings/");
    refresh();
  };
  return (
    <Create
      mutationOptions={{ onSuccess }}
      {...props}
    >
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
            <DateInput source={"trainingDate"} /*label="Training Date"*/ />
            <TextInput source={"trainingTitle"} />
            <SelectInput
              source={"typeOfTraining"}
              // label="typeOfTraining"
              choices={[
                { id: "accueil-securite", name: "Accueil sécurité" },
                { id: "recueil-securite", name: "Recueil sécurité" },
                { id: "formation-incendie", name: "Formation incendie" },
                { id: "gestes-postures", name: "Gestes et Postures" },
                { id: "sst-initial", name: "SST INITIAL" },
                { id: "sst-recyclage", name: "SST recyclage" },
                { id: "formation-metier-bom", name: "Formation métier BOM" },
                { id: "formation-metier-balayage", name: "Formation métier Balayage" },
                { id: "conduite-preventive", name: "Conduite préventive" },
                { id: "conduite-pro", name: "Conduite Pro" },
              ]}
            />
            <ReferenceArrayInput
                source="participants"
                reference="employees"
                //label="participants"
            >
              <AutocompleteArrayInput optionText={fullName} />
            </ReferenceArrayInput>

        </Box>
      </SimpleForm>
    </Create>
  );
};

interface TrainingTitleProps {
  record: RaRecord;
}

const TrainingTitle: React.FC<TrainingTitleProps> = ({ record }) => {
  return <span>Contrôle {record ? `"${record.title}"` : ""}</span>;
};

export const TrainingEdit: React.FC<TrainingTitleProps> = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const translate = useTranslate();
    const [employeeChoices, setEmployeeChoices] = useState<any[]>([]);
    const dataProvider = useDataProvider();

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const { data } = await dataProvider.getList('employees', {
                  pagination: { page: 1, perPage: 1000 },
                  sort: { field: 'name', order: 'ASC' },
                  filter: {},  
              });
                setEmployeeChoices(data.map((employee: any) => ({ id: employee, name: employee.lastName })));
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };

        fetchEmployees();
    }, [dataProvider]);

  
    const onSuccess = () => {
      notify(`L'élément a bien été créé`);
      redirect("/trainings/");
      refresh();
    };
  
    return (
      <Edit
        title={<TrainingTitle record={props.record} />}
        mutationMode="pessimistic"
        {...props}
      >
        <SimpleForm>
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(5, 1fr)",
            }}
            gap={2}
            width="100%"
          >
            <DateInput source={"trainingDate"} /*label="Training Date"*/ />
            <TextInput source={"trainingTitle"} />
            <SelectInput
              source={"typeOfTraining"}
              // label="typeOfTraining"
              choices={[
                { id: "accueil-securite", name: "Accueil sécurité" },
                { id: "recueil-securite", name: "Recueil sécurité" },
                { id: "formation-incendie", name: "Formation incendie" },
                { id: "gestes-postures", name: "Gestes et Postures" },
                { id: "sst-initial", name: "SST INITIAL" },
                { id: "sst-recyclage", name: "SST recyclage" },
                { id: "formation-metier-bom", name: "Formation métier BOM" },
                { id: "formation-metier-balayage", name: "Formation métier Balayage" },
                { id: "conduite-preventive", name: "Conduite préventive" },
                { id: "conduite-pro", name: "Conduite Pro" },
              ]}
            />
            <AutocompleteArrayInput
                source="participants"
                choices={employeeChoices}
                optionText="name"
                //label="participants"
            />


          </Box>
  
        </SimpleForm>
      </Edit>
    );
  };
  
  export const TrainingShow: React.FC<TrainingTitleProps> = (props) => (
    <Show
      actions={<EditAction />}
      title={<TrainingTitle record={props.record} />}
      sx={{ fontSize: "20px" }}
      {...props}
    >
      <SimpleShowLayout>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <Labeled>
            <DateField source={"trainingDate"} /*label="Training Date"*/ fontWeight="bold" />
          </Labeled>
          <Labeled>
            <TextField source={"trainingTitle"} />
          </Labeled>
          <Labeled>
          <SelectField
              source={"typeOfTraining"}
              // label="typeOfTraining"
              choices={[
                { id: "accueil-securite", name: "Accueil sécurité" },
                { id: "recueil-securite", name: "Recueil sécurité" },
                { id: "formation-incendie", name: "Formation incendie" },
                { id: "gestes-postures", name: "Gestes et Postures" },
                { id: "sst-initial", name: "SST INITIAL" },
                { id: "sst-recyclage", name: "SST recyclage" },
                { id: "formation-metier-bom", name: "Formation métier BOM" },
                { id: "formation-metier-balayage", name: "Formation métier Balayage" },
                { id: "conduite-preventive", name: "Conduite préventive" },
                { id: "conduite-pro", name: "Conduite Pro" },
              ]}
            />
          </Labeled>
          <Labeled>
            <ReferenceArrayField reference="employees" source={"participants"} >
              <SingleFieldList>
                <FunctionField render={(record : any) => <Box>
                    <TextField source="firstName" />
                    &nbsp;
                    <TextField source="lastName" />
                  </Box>}>
                  
                </FunctionField>
              </SingleFieldList >

            </ReferenceArrayField>
          </Labeled>

        </Box>

      </SimpleShowLayout>
    </Show>
  );
  