import { Box, Chip, Stack, Typography } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
    AutocompleteInput,
    Create,
    CreateButton,
    DatagridConfigurable,
    DateField,
    DateInput,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    ExportButton,
    Labeled,
    List,
    NumberField,
    NumberInput,
    RaRecord,
    ReferenceInput,
    SelectColumnsButton,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    WrapperField,
    downloadCSV,
    useRedirect,
    useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";

// Export function for CSV
const exporter = (neighborhoods: any) => {
    const neighborhoodsForExport = neighborhoods.map((neighborhood: any) => {
        const {
            "@id": arobasId,
            "@type": arobasType,
            ...neighborhoodForExport
        } = neighborhood;
        return neighborhoodForExport;
    });

    jsonexport(
        neighborhoodsForExport,
        {
            headers: [
                "name",
                "nbrMenages",
                "housingType",
                "collectionMode",
                "district",
            ],
            rename: [
                "Nom",
                "Nombre de ménages",
                "Type de logement",
                "Mode de collecte",
                "District",
            ],
        },
        (err: any, csv: string) => {
            downloadCSV(csv, "Quartiers");
        }
    );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
    choice.children;

const permissions = localStorage.getItem("permissions");

const NeighborhoodListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
        <ExportButton />
    </TopToolbar>
);

const NeighborhoodFilters = [<TextInput source="query" alwaysOn />];

export const NeighborhoodList: React.FC = (props) => (
    <List
        actions={<NeighborhoodListActions />}
        filters={NeighborhoodFilters}
        empty={<Empty create />}
        exporter={exporter}
        {...props}
    >
        <DatagridConfigurable bulkActionButtons={false} rowClick="show">
            <TextField source="name" label="Nom" />
            <NumberField source="nbrMenages" label="Nombre de ménages" />
            <TextField source="housingType" label="Type de logement" />
            <TextField source="collectionMode" label="Mode de collecte" />
            <TextField source="district" label="District" />
            {isGranted("ROLE_MANAGER", permissions) && (
                <FieldWrapper label="Actions">
                    <EditButton />
                    &nbsp;
                    <DeleteWithConfirmButton />
                </FieldWrapper>
            )}
        </DatagridConfigurable>
    </List>
);

export const NeighborhoodCreate: React.FC = (props) => {
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        redirect("/neighborhoods/");
        refresh();
    };

    return (
        <Create mutationOptions={{ onSuccess }} {...props}>
            <SimpleForm>
                <Box
                    display="grid"
                    gridTemplateColumns={{
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(3, 1fr)",
                        lg: "repeat(4, 1fr)",
                        xl: "repeat(5, 1fr)",
                    }}
                    gap={2}
                    width="100%"
                >
                    <TextInput source="name" label="Nom" />
                    <NumberInput source="nbrMenages" label="Nombre de ménages" />
                    <TextInput source="housingType" label="Type de logement" />
                    <SelectInput
                        source="collectionMode"
                        label="Mode de collecte"
                        choices={[
                            { id: "P&P", name: "P&P" },
                            { id: "PAV", name: "PAV" },
                            { id: "Mixte", name: "Mixte" },
                        ]}
                    />
                    <TextInput source="district" label="Arrondissement" />
                </Box>
            </SimpleForm>
        </Create>
    );
};

export const NeighborhoodEdit: React.FC = (props) => {
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        redirect("/neighborhoods/");
        refresh();
    };

    return (
        <Edit mutationMode="pessimistic" mutationOptions={{ onSuccess }} {...props}>
            <SimpleForm>
                <Box
                    display="grid"
                    gridTemplateColumns={{
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(3, 1fr)",
                        lg: "repeat(4, 1fr)",
                        xl: "repeat(5, 1fr)",
                    }}
                    gap={2}
                    width="100%"
                >
                    <TextInput source="name" label="Nom" />
                    <NumberInput source="nbrMenages" label="Nombre de ménages" />
                    <TextInput source="housingType" label="Type de logement" />
                    <SelectInput
                        source="collectionMode"
                        label="Mode de collecte"
                        choices={[
                            { id: "P&P", name: "P&P" },
                            { id: "PAV", name: "PAV" },
                            { id: "Mixte", name: "Mixte" },
                        ]}
                    />
                    <TextInput source="district" label="Arrondissement" />
                </Box>
            </SimpleForm>
        </Edit>
    );
};

export const NeighborhoodShow: React.FC = (props) => (
    <Show actions={<EditAction />} {...props}>
        <SimpleShowLayout>
            <Box
                display="grid"
                gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(5, 1fr)",
                }}
                gap={2}
                width="100%"
            >
                <Labeled>
                    <TextField source="name" label="Nom" />
                </Labeled>
                <Labeled>
                    <NumberField source="nbrMenages" label="Nombre de ménages" />
                </Labeled>
                <Labeled>
                    <TextField source="housingType" label="Type de logement" />
                </Labeled>
                <Labeled>
                    <TextField source="collectionMode" label="Mode de collecte" />
                </Labeled>
                <Labeled>
                    <TextField source="district" label="District" />
                </Labeled>
            </Box>
        </SimpleShowLayout>
    </Show>
);
