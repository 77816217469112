import { Box, Chip } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  FunctionField,
  Labeled,
  List,
  NumberField,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const exporter = (insurances: any) => {
  const insurancesForExport = insurances.map((insurance: any) => {
    const {
      "@id": arobasVehicleId,
      "@type": arobasVehicleType,
      createdAt,
      site: vehicleSite,
      operator: vehicleOperator,
      updatedAt: vehicleUpdatedAt,
      ...vehicleForExport
    } = insurance.vehicle;
    insurance.vehicle = vehicleForExport;
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      originId,
      site,
      updatedAt,
      totalPlacesAllowed,
      totalSeats,
      usage,
      taxHorsePower,
      damageValue,
      fireValue,
      brokenWindowValue,
      rcBonus,
      damageBonus,
      fireBonus,
      theftBonus,
      brokenWindowBonus,
      der,
      ...insuranceForExport
    } = insurance; // omit backlinks and author
    return insuranceForExport;
  });
  jsonexport(
    insurancesForExport,
    {
      headers: [
        "date",
        "vehicle.parkingNumber",
        "vehicle.brand",
        "vehicle.model",
        "totalAmountNet",
        "createdAt",
      ],
      rename: [
        "Date échéance",
        "Numéro de parc",
        "Marque",
        "Modèle",
        "Montant total en net (MAD)",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Assurances"); // download as 'posts.csv` file
    }
  );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;
const VehicleInfo = (choice: { parkingNumber: string }) =>
  `${choice.parkingNumber}`;

const InsuranceListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
export const InsuranceList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem('permissions'); // Assuming permissions are stored in localStorage

  const InsuranceFilters = [
    <TextInput source={"query"} alwaysOn />,
    <SelectInput
      source={"status"}
      alwaysOn
      choices={[
        { id: 'yes', name: translate('labels.effectue') },
        { id: 'todo', name: translate('labels.avenir') },
        { id: 'expired', name: translate('labels.enretard') },
        { id: 'no', name: translate('labels.noneffectue') },
      ]}
      resettable
    />,
  ];

  return (
    <List
      actions={<InsuranceListActions />}
      title={translate('labels.insurance')}
      filters={InsuranceFilters}
      empty={<Empty create />}
      {...props}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField source={"date"} /*label="Date échéance"*/ locales="fr-FR" />
        <WrapperField source="vehicle" /*label="Véhicule"*/ sortBy="vehicle.parkingNumber">
          <TextField source={"vehicle.parkingNumber"} />
        </WrapperField>
        <TextField source={"totalAmountNet"} /*label="Montant (net)"*/ />
        <FunctionField
          source={"status"}
          //label="Statut"
          render={(record: any) => {
            if (record.status === "yes") return <Chip color="success" size="small" label={translate('labels.effectue')} />;
            else if (record.status === "expired") return <Chip color="error" size="small" label={translate('labels.enretard')} />;
            else if (record.status === "no") return <Chip size="small" label={translate('labels.noneffectue')} />;
            else if (record.status === "todo") return <Chip color="warning" size="small" label={translate('labels.avenir')} />;
            else return <></>;
          }}
        />
        {isGranted('ROLE_MANAGER', permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const InsuranceCreate: React.FC = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const defaultValue = () => ({ status: 'no' });

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/vehicle-insurance-histories/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.addinsurance')}
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center" defaultValues={defaultValue}>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateInput source={"date"} /*label="Date échéance"*/ />
          <ReferenceInput source={"vehicle"} reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText={VehicleInfo} /*label="Véhicule"*/ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <NumberInput
            source={"totalPlacesAllowed"}
            //label="Nombre de places autorisées"
          />
          <NumberInput source={"totalSeats"} /*label="Nombre de sièges"*/ />
          <TextInput source={"usage"} /*label="Usage"*/ />
          <NumberInput
            source={"taxHorsePower"}
            //label="Puissance fiscale (CV)"
          />
          <NumberInput source={"der"} /*label="DER"*/ />
          <NumberInput source={"damageValue"} /*label="Valeur dommage"*/ />
          <NumberInput source={"fireValue"} /*label="Valeur incendie"*/ />
          <NumberInput
            source={"brokenWindowValue"}
            //label="Valeur brise glace"
          />
          <NumberInput source={"rcBonus"} /*label="Prime RC"*/ />
          <NumberInput source={"damageBonus"} /*label="Prime dommage"*/ />
          <NumberInput source={"fireBonus"} /*label="Prime incendie"*/ />
          <NumberInput source={"theftBonus"} /*label="Prime vol"*/ />
          <NumberInput source={"brokenWindowBonus"} /*label="Prime brise glace"*/ />
          <NumberInput source={"totalAmountNet"} /*label="Total (Net)"*/ />
          <SelectInput
            source={"status"}
            //label="Effectué"
            choices={[
              { id: "yes", name: translate('labels.effectue') },
              { id: "no", name: translate('labels.non-effcetue') },
            ]}
          />
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

interface InsuranceTitleProps {
  record: RaRecord;
}

const InsuranceTitle: React.FC<InsuranceTitleProps> = ({ record }) => {
  return <span>Insurance {record ? `"${record.title}"` : ""}</span>;
};

export const InsuranceEdit: React.FC<InsuranceTitleProps> = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`L'élément a bien été modifié`);
    redirect("/vehicle-insurance-histories/");
    refresh();
  };
  return (
    <Edit
      title={<InsuranceTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateInput source={"date"} /*label="Date échéance"*/ />
          <ReferenceInput source={"vehicle[@id]"} reference="vehicles" perPage={1000}>
            <AutocompleteInput optionText={VehicleInfo} /*label="Véhicule"*/ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <NumberInput
            source={"totalPlacesAllowed"}
            //label="Nombre de places autorisées"
          />
          <NumberInput source={"totalSeats"} /*label="Nombre de sièges"*/ />
          <TextInput source={"usage"} /*label="Usage"*/ />
          <NumberInput
            source={"taxHorsePower"}
            //label="Puissance fiscale (CV)"
          />
          <NumberInput source={"der"} /*label="DER"*/ />
          <NumberInput source={"damageValue"} /*label="Valeur dommage"*/ />
          <NumberInput source={"fireValue"} /*label="Valeur incendie"*/ />
          <NumberInput
            source={"brokenWindowValue"}
            //label="Valeur brise glace"
          />
          <NumberInput source={"rcBonus"} /*label="Prime RC"*/ />
          <NumberInput source={"damageBonus"} /*label="Prime dommage"*/ />
          <NumberInput source={"fireBonus"} /*label="Prime incendie"*/ />
          <NumberInput source={"theftBonus"} /*label="Prime vol"*/ />
          <NumberInput source={"brokenWindowBonus"} /*label="Prime brise glace"*/ />
          <NumberInput source={"totalAmountNet"} /*label="Total (Net)"*/ />
          <SelectInput
            source={"status"}
            //label="Effectué"
            choices={[
              { id: "yes", name: translate('labels.effectue') },
              { id: "no", name: translate('labels.non-effcetue') },
            ]}
          />
        </Box>
        <FileInput
          source={"attachments"}
          //label="Fichiers"
          accept="image/*,.pdf,.doc,.docx"
          multiple>
          <FileField source={"src"} title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export const InsuranceShow: React.FC<InsuranceTitleProps> = (props) => (
  <Show
    actions={<EditAction />}
    title={<InsuranceTitle record={props.record} />}
    {...props}
    sx={{ fontSize: "20px" }}>
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%">
        <Labeled label="Véhicule">
          <DateField locales="fr-FR" source={"date"} /*label="Date échéance"*/ fontWeight="bold" />
        </Labeled>
        <Labeled label="Véhicule">
          <Box display="flex" fontWeight="bold">
            <TextField source={"vehicle.parkingNumber"} />
            &nbsp;-&nbsp;
            <TextField source={"vehicle.brand"} />
            &nbsp;
            <TextField source={"vehicle.model"} />
          </Box>
        </Labeled>
        <Labeled label="Usage">
          <NumberField
            source={"totalPlacesAllowed"}
            //label="Nombre de places autorisées"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Usage">
          <NumberField
            source={"totalSeats"}
            //label="Nombre de sièges"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Usage">
          <TextField source={"usage"} /*label="Usage"*/ fontWeight="bold" />
        </Labeled>
        <Labeled label="Puissance fiscale (CV)">
          <NumberField
            source={"taxHorsePower"}
            //label="Puissance fiscale (CV)"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="DER">
          <NumberField source={"der"} /*label="DER"*/ fontWeight="bold" />
        </Labeled>
        <Labeled label="Valeur dommage">
          <NumberField
            source={"damageValue"}
            //label="Valeur dommage"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Valeur incendie">
          <NumberField
            source={"fireValue"}
            //label="Valeur incendie"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Valeur brise glace">
          <NumberField
            source={"brokenWindowValue"}
            //label="Valeur brise glace"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Prime RC">
          <NumberField source={"rcBonus"} /*label="Prime RC"*/ fontWeight="bold" />
        </Labeled>
        <Labeled label="Prime dommage">
          <NumberField
            source={"damageBonus"}
            //label="Prime dommage"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Prime incendie">
          <NumberField
            source={"fireBonus"}
            //label="Prime incendie"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Prime vol">
          <NumberField
            source={"theftBonus"}
            //label="Prime vol"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Prime brise glace">
          <NumberField
            source={"brokenWindowBonus"}
            //label="Prime brise glace"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Total (Net)">
          <NumberField
            source={"totalAmountNet"}
            //label="Total (Net)"
            fontWeight="bold"
          />
        </Labeled>
      </Box>
      <Labeled label="Fichiers" marginTop="16px">
        <FileField
          source={"attachments"}
          src="contentUrl"
          title="title"
          target="_blank"
        />
      </Labeled>
    </SimpleShowLayout>
  </Show>
);
