export const roleHierarchy = {
  ROLE_SUPER_ADMIN: [
    "ROLE_ADMIN",
    "ROLE_OWNER",
    "ROLE_MANAGER",
    "ROLE_AGENT",
    "ROLE_USER",
  ],
  ROLE_ADMIN: ["ROLE_OWNER", "ROLE_MANAGER", "ROLE_AGENT", "ROLE_USER"],
  ROLE_OWNER: ["ROLE_MANAGER", "ROLE_AGENT", "ROLE_USER"],
  ROLE_MANAGER: ["ROLE_AGENT", "ROLE_USER"],
  ROLE_AGENT: ["ROLE_USER"],
  ROLE_USER: [],
};

const isGranted = (role, permissions) => {
  if (!permissions) return false;

  let hasPermission = false;

  permissions.split(",").every((p) => {
    if (role === p || roleHierarchy[p].includes(role)) {
      hasPermission = true;
      return false;
    }
    return true;
  });

  return hasPermission;
};

export default isGranted;
