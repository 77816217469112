import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  Labeled,
  List,
  NumberInput,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TimeInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  useNotify,
  useRedirect,
  useRefresh,
  required
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const exporter = (trafficCodeViolations: any) => {
  const trafficCodeViolationsForExport = trafficCodeViolations.map((trafficCodeViolation: any) => {
    if (trafficCodeViolation.dueDate) {
      trafficCodeViolation.dueDate = trafficCodeViolation.dueDate.slice(0, 10);
    };
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      createdAt,
      ...trafficCodeViolationForExport
    } = trafficCodeViolation;
    return trafficCodeViolationForExport;
  });
  jsonexport(
    trafficCodeViolationsForExport,
    {
      headers: [
        "date",
        "coducteur['firstName']",
        "conducteur['lastName']",
        "nature",

      ],
      rename: [
        "Date",
        "Prenom",
        "Nom",
        "Nature",

      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "TrafficCodeViolation"); // download as 'posts.csv` file
    }
  );
};

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const fullName = (choice: { firstName: string; lastName: string }) =>
  `${choice.firstName} ${choice.lastName}`;

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const TrafficCodeViolationListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const TrafficCodeViolationFilters = [
  <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export const TrafficCodeViolationList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<TrafficCodeViolationListActions />}
      filters={TrafficCodeViolationFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField source={"date"} /*label="Matricule"*/ />
        <WrapperField source="conducteur" sortBy="conducteur.lastName">
          <TextField source={"conducteur.lastName"} />
          &nbsp;
          <TextField source={"conducteur.firstName"} />
        </WrapperField>
        <TextField source={"nature"} />

        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const TrafficCodeViolationCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/traffic-code-violations/");
    refresh();
  };
  return (
    <Create
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} validate={[required("Champ obligatoire")]} /*label="date"*/ />
          <ReferenceInput
            source={"conducteur"}
            reference="employees"
            perPage={1000}

          >
            <AutocompleteInput optionText={fullName} /* label="Conducteur" */ filterToQuery={filterToQuery} validate={[required("Champ obligatoire")]} />
          </ReferenceInput>
          <TextInput source={"nature"} />

        </Box>

      </SimpleForm>
    </Create>
  );
};

interface TrafficCodeViolationTitleProps {
  record: RaRecord;
}

const TrafficCodeViolationTitle: React.FC<TrafficCodeViolationTitleProps> = ({ record }) => {
  return <span>Contrôle {record ? `"${record.title}"` : ""}</span>;
};

export const TrafficCodeViolationEdit: React.FC<TrafficCodeViolationTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/traffic-code-violations/");
    refresh();
  };
  return (
    <Edit
      title={<TrafficCodeViolationTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <DateInput source={"date"} /*label="date"*/ />
          <ReferenceInput
            source={"conducteur"}
            reference="employees"
            perPage={1000}
          >
            <AutocompleteInput optionText={fullName} /* label="Conducteur" */ filterToQuery={filterToQuery} />
          </ReferenceInput>
          <TextInput source={"nature"} />

        </Box>

      </SimpleForm>
    </Edit>
  );
};

export const TrafficCodeViolationShow: React.FC<TrafficCodeViolationTitleProps> = (props) => (
  <Show
    actions={<EditAction />}
    title={<TrafficCodeViolationTitle record={props.record} />}
    sx={{ fontSize: "20px" }}
    {...props}>
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%"
      >

        <Labeled label="Date">
          <DateField source={"date"} /*label="Date"*/ fontWeight="bold" />
        </Labeled>
        <Labeled>
          <WrapperField source="conducteur">
            <Box display={"flex"}>
              <TextField source={"conducteur.lastName"} />
              &nbsp;
              <TextField source={"conducteur.firstName"} />
            </Box>
          </WrapperField>
        </Labeled>
        <Labeled label="Nature">
          <TextField source={"nature"} /*label="Nature"*/ fontWeight="bold" />
        </Labeled>

      </Box>

    </SimpleShowLayout>
  </Show>
);
