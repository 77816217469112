import {
    Create,
    CreateButton,
    DatagridConfigurable,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    ExportButton,
    List,
    NumberField,
    NumberInput,
    SelectInput,
    AutocompleteInput,
    SelectColumnsButton,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    downloadCSV,
    ReferenceInput,
    ImageInput,
    ImageField,
    FileField,
    useRedirect,
    useRefresh,
} from "react-admin";
import { Box } from "@mui/material";
import { Empty } from "../components/Empty";
import jsonexport from "jsonexport/dist";
import isGranted from "../services/security";



// Exporter for CSV
const exporter = (points: any) => {
    const pointsForExport = points.map((point: any) => {
        const { "@id": arobasId, "@type": arobasType, ...pointForExport } = point;
        return pointForExport;
    });

    jsonexport(
        pointsForExport,
        {
            headers: ["name", "latitude", "longitude"],
            rename: ["Nom", "Latitude", "Longitude"],
        },
        (err: any, csv: string) => {
            downloadCSV(csv, "Points-sensibles");
        }
    );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
    choice.children;

const permissions = localStorage.getItem("permissions");

const SensitivePointListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
        <ExportButton />
    </TopToolbar>
);

export const SensitivePointList: React.FC = (props) => (
    <List
        actions={<SensitivePointListActions />}
        empty={<Empty create />}
        exporter={exporter}
        {...props}
    >
        <DatagridConfigurable bulkActionButtons={false} rowClick="show">
            <TextField source="name" label="Nom" />
            <NumberField source="latitude" label="Latitude" />
            <NumberField source="longitude" label="Longitude" />
            {isGranted("ROLE_MANAGER", permissions) && (
                <FieldWrapper label="Actions">
                    <EditButton />
                    &nbsp;
                    <DeleteWithConfirmButton />
                </FieldWrapper>
            )}
        </DatagridConfigurable>
    </List>
);

export const SensitivePointCreate: React.FC = (props) => {
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        redirect("/sensitive-points/");
        refresh();
    };
    return (
        <Create mutationOptions={{ onSuccess }}  {...props}>
            <SimpleForm>
                <Box
                    display="grid"
                    gridTemplateColumns={{
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(3, 1fr)",
                        lg: "repeat(4, 1fr)",
                        xl: "repeat(5, 1fr)",
                    }}
                    gap={2}
                    width="100%"
                >
                    <TextInput source="name" label="Nom" />
                    <NumberInput source="latitude" label="Latitude" />
                    <NumberInput source="longitude" label="Longitude" />
                    <ReferenceInput
                        source={"street"}
                        reference="streets"
                        perPage={1000}
                    >
                        <AutocompleteInput optionText='name' label="Rue" />
                    </ReferenceInput>
                    <ReferenceInput
                        source={"neighborhood"}
                        reference="neighborhoods"
                        perPage={1000}
                    >
                        <AutocompleteInput optionText='name' label="Quartier" />
                    </ReferenceInput>
                </Box>
                <ImageInput source="images" /*label="Images"*/ accept="image/*" multiple>
                    <ImageField source="src" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};

export const SensitivePointEdit: React.FC = (props) => {
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        redirect("/sensitive-points/");
        refresh();
    };
    return (
        <Edit mutationMode="pessimistic" mutationOptions={{ onSuccess }}  {...props}>
            <SimpleForm>
                <Box
                    display="grid"
                    gridTemplateColumns={{
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(3, 1fr)",
                        lg: "repeat(4, 1fr)",
                        xl: "repeat(5, 1fr)",
                    }}
                    gap={2}
                    width="100%"
                >
                    <TextInput source="name" label="Nom" />
                    <NumberInput source="latitude" label="Latitude" />
                    <NumberInput source="longitude" label="Longitude" />
                    <ReferenceInput
                        source={"street[@id]"}
                        reference="streets"
                        perPage={1000}
                    >
                        <AutocompleteInput optionText='name' label="Rue" />
                    </ReferenceInput>
                    <ReferenceInput
                        source={"neighborhood[@id]"}
                        reference="neighborhoods"
                        perPage={1000}
                    >
                        <AutocompleteInput optionText='name' label="Quartier" />
                    </ReferenceInput>
                </Box>
                <ImageInput source="images" /*label="Images"*/ accept="image/*" multiple>
                    <ImageField source="src" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    );
};

export const SensitivePointShow: React.FC = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <Box
                display="grid"
                gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(4, 1fr)",
                    xl: "repeat(5, 1fr)",
                }}
                gap={2}
                width="100%"
            >
                <TextField source="name" label="Nom" />
                <NumberField source="latitude" label="Latitude" />
                <NumberField source="longitude" label="Longitude" />
                <TextField source="street" label="Rue" />
                <TextField source="neighborhood" label="Quartier" />
            </Box>
            <ImageField
                source="images"
                src="contentUrl"
                //label="Image"
                sx={{
                    padding: "0",
                }}
            />
            <FileField
                source="images"
                src="contentUrl"
                title="ouvrir image"
                target="_blank"
                label={false}
                sx={{ listStyle: "none", display: "flex", paddingLeft: "0px", margin: "0px", gap: "220px" }}
            />
        </SimpleShowLayout>
    </Show>
);
