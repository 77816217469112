import { Box } from "@mui/material";
import jsonexport from "jsonexport/dist";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  Labeled,
  List,
  RaRecord,
  ReferenceInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  WrapperField,
  downloadCSV,
  required,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const filterToQuery = (searchText: string) => ({ query: `${searchText}` });

const exporter = (immobilities: any) => {
  const immobilitiesForExport = immobilities.map((immobility: any) => {
    console.log("immobility", immobility);
    const {
      "@id": arobasVehicleId,
      "@type": arobasVehicleType,
      createdAt,
      site: vehicleSite,
      operator: vehicleOperator,
      updatedAt: vehicleUpdatedAt,
      ...vehicleForExport
    } = immobility.vehicle;
    immobility.vehicle = vehicleForExport;
    const {
      "@id": arobasId,
      "@type": arobasType,
      attachments,
      id,
      operator,
      originId,
      site,
      updatedAt,
      totalPlacesAllowed,
      totalSeats,
      usage,
      taxHorsePower,
      damageValue,
      fireValue,
      brokenWindowValue,
      rcBonus,
      damageBonus,
      fireBonus,
      theftBonus,
      brokenWindowBonus,
      der,
      ...immobilityForExport
    } = immobility; // omit backlinks and author
    return immobilityForExport;
  });
  jsonexport(
    immobilitiesForExport,
    {
      headers: [
        "date",
        "vehicle.parkingNumber",
        "vehicle.brand",
        "vehicle.model",
        "immobility",
        "cause",
        "status",
        "createdAt",
      ],
      rename: [
        "Date d'intervention",
        "Numéro de parc",
        "Marque",
        "Modèle",
        "Disponibilité",
        "Cause",
        "État d'intervention",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Immobilisations"); // download as 'posts.csv` file
    }
  );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;
const VehicleInfo = (choice: { parkingNumber: string }) =>
  `${choice.parkingNumber}`;

const ImmobilityListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};

export const ImmobilityList: React.FC = (props) => {
  const translate = useTranslate(); // Call the hook inside the component
  const ImmobilityFilters = [
    <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
    <SelectInput
      source={"status"}
      // label="Statut"
      alwaysOn
      choices={[
        { id: "En attente", name: translate('listItems.enAttente') },
        { id: "En urgence", name: translate('listItems.enUrgence') },
        { id: "En cours", name: translate('listItems.enCours') },
      ]}
      resettable
    />,
    <DateInput /*label="Date de l'immobilisation"*/ source={"date"} alwaysOn />,
    // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
  ];

  const permissions = localStorage.getItem("permissions");

  return (
    <List
      actions={<ImmobilityListActions />}
      title={translate('labels.immobilizations')}
      filters={ImmobilityFilters}
      empty={<Empty create />}
      exporter={exporter}
      {...props}>
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <DateField locales="fr-FR" source={"date"} /*label={"Date de l'immobilisation"}*/ />
        <WrapperField source="vehicle" /*label="Véhicule"*/ sortBy="vehicle.parkingNumber">
          <TextField source={"vehicle.parkingNumber"} />
        </WrapperField>
        <SelectField source={"cause"} /*label={"Cause"}*/ choices={[
          { id: "Maintenance corrective", name: translate('labels.maintenancecorrective') },
          { id: "Maintenance préventive", name: translate('labels.maintenancepréventive') },
          { id: "Incident", name: translate('labels.incident') },
          { id: "Dépannage", name: translate('labels.dépannage') },
          { id: "Autre", name: translate('labels.autre') },
        ]} />
        <SelectField source={"status"} /*label={"État d'intervention"}*/ choices={[
          { id: "En attente", name: translate('listItems.enAttente') },
          { id: "En urgence", name: translate('listItems.enUrgence') },
          { id: "En cours", name: translate('listItems.enCours') },
        ]} />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const ImmobilityCreate: React.FC = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/vehicle-immobility-histories/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.addimmobilization')}
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateInput
            source={"date"}
            validate={[required("Champ obligatoire")]}
          //label="Date de l'immobilisation"
          />
          <ReferenceInput source={"vehicle"} reference="vehicles" perPage={1000}>
            <AutocompleteInput
              validate={[required("Champ obligatoire")]}
              optionText={VehicleInfo}
              //label="Véhicule"
              filterToQuery={filterToQuery}
            />
          </ReferenceInput>
          <SelectInput
            source={"status"}
            //label="État d'intervention"
            validate={[required("Champ obligatoire")]}
            choices={[
              { id: "En attente", name: translate('listItems.enAttente') },
              { id: "En urgence", name: translate('listItems.enUrgence') },
              { id: "En cours", name: translate('listItems.enCours') },
            ]}
          />
          <SelectInput
            source={"cause"}
            //label="Cause d'immobilisation"
            choices={[
              { id: "Maintenance corrective", name: translate('labels.maintenancecorrective') },
              { id: "Maintenance préventive", name: translate('labels.maintenancepréventive') },
              { id: "Incident", name: translate('labels.incident') },
              { id: "Dépannage", name: translate('labels.dépannage') },
              { id: "Autre", name: translate('labels.autre') },
            ]}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};

interface ImmobilityTitleProps {
  record: RaRecord;
}

const ImmobilityTitle: React.FC<ImmobilityTitleProps> = ({ record }) => {
  return <span>Disponibilté {record ? `"${record.title}"` : ""}</span>;
};

export const ImmobilityEdit: React.FC<ImmobilityTitleProps> = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`L'élément a bien été modifié`);
    redirect("/vehicle-immobility-histories/");
    refresh();
  };
  return (
    <Edit
      title={<ImmobilityTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}>
      <SimpleForm>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateInput
            source={"date"}
            validate={[required("Champ obligatoire")]}
          //label="Date de l'immobilisation"
          />
          <ReferenceInput source={"vehicle[@id]"} reference="vehicles" perPage={1000}>
            <AutocompleteInput
              optionText={VehicleInfo}
              validate={[required("Champ obligatoire")]}
              //label="Véhicule"
              filterToQuery={filterToQuery}
            />
          </ReferenceInput>
          <SelectInput
            source={"status"}
            validate={[required("Champ obligatoire")]}
            //label="État d'intervention"
            choices={[
              { id: "En attente", name: translate('listItems.enAttente') },
              { id: "En urgence", name: translate('listItems.enUrgence') },
              { id: "En cours", name: translate('listItems.enCours') },
            ]}
          />
          <SelectInput
            source={"cause"}
            //label="Cause d'immobilisation"
            choices={[
              { id: "Maintenance corrective", name: translate('labels.maintenancecorrective') },
              { id: "Maintenance préventive", name: translate('labels.maintenancepréventive') },
              { id: "Incident", name: translate('labels.incident') },
              { id: "Dépannage", name: translate('labels.dépannage') },
              { id: "Autre", name: translate('labels.autre') },
            ]}
          />
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export const ImmobilityShow: React.FC<ImmobilityTitleProps> = (props) => (
  <Show
    actions={<EditAction />}
    title={<ImmobilityTitle record={props.record} />}
    {...props}
    sx={{ fontSize: "20px" }}>
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%">
        <Labeled label="Date de l'immobilisation">
          <DateField
            locales="fr-FR"
            source={"date"}
            //label="Date de l'immobilisation"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Véhicule">
          <Box display="flex">
            <TextField source={"vehicle.parkingNumber"} fontWeight="bold" />
            &nbsp;-&nbsp;
            <TextField source={"vehicle.brand"} fontWeight="bold" />
            &nbsp;
            <TextField source={"vehicle.model"} fontWeight="bold" />
          </Box>
        </Labeled>
        <Labeled label="Disponibilité">
          <TextField
            source={"immobility"}
            //label="Disponibilité"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="Cause d'immobilisation">
          <TextField
            source={"cause"}
            //label="Cause d'immobilisation"
            fontWeight="bold"
          />
        </Labeled>
        <Labeled label="État d'intervention">
          <TextField
            source={"status"}
            //label="État d'intervention"
            fontWeight="bold"
          />
        </Labeled>
      </Box>
    </SimpleShowLayout>
  </Show>
);
