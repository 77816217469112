import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

interface MapProps {
  latitude: number;
  longitude: number;
}

const MyMap: React.FC<MapProps> = ({ latitude, longitude }) => {

    const myIcon = L.icon({
        iconUrl: './mapMarker.png', 
        iconSize: [28, 35], 
        iconAnchor: [13, 30], 
        
      });



  return (
    <MapContainer
      center={[latitude, longitude]}
      zoom={8}
      style={{ height: '400px', width: '100%' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker icon={myIcon} position={[latitude, longitude]}>
        <Popup>
          Location
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MyMap;
