import { Box, Button } from '@mui/material';

import { useGetList } from 'react-admin';

function setSite(site: any) {
  localStorage.setItem("site", JSON.stringify(site));
}
type Site = {
    id: number;
    iri: string;
    name: string;
};

const Sites = () => {
    const { data: sites, total, isLoading, error } = useGetList<Site>(
        'sites',
        { 
            sort: { field: 'published_at', order: 'DESC' }
        }
    );
    // if (isLoading) { return <Loading />; }
    // if (error) { return <p>ERROR</p>; }
    return (
        <>
            <h1>Sites</h1>
            <Box display="grid" gridTemplateColumns={{xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)", lg: "repeat(4, 1fr)", xl: "repeat(5, 1fr)"}} gap={2} mt={4} width="100%">

                {/* TypeScript knows that posts is of type Post[] */}
                {sites?.map(site =>
                <Button variant="outlined" onClick={() => setSite(site)}>{site.name}</Button>
                )}
            </Box>
            <p>{sites?.length} / {total} sites</p>
        </>
    );
};

// const Sites = () => {
  
//   return (
//   <Box display="grid" gridTemplateColumns={{xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)", lg: "repeat(4, 1fr)", xl: "repeat(5, 1fr)"}} gap={2} mt={4} width="100%">

//         <Card sx={{padding: "12px", textAlign: "center"}}>Casablanca</Card>
//     </Box>
//   )
// };

export default Sites;