import { Box } from "@mui/material";
import {
  Create,
  CreateButton,
  DatagridConfigurable,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Labeled,
  List,
  RaRecord,
  SelectColumnsButton,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  required,
  useNotify,
  useRedirect,
  useRefresh
} from "react-admin";
import { Empty } from "../components/Empty";
import { EditAction } from "../components/editAction";
import isGranted from "../services/security";
import { useTranslate } from 'react-admin';

const SupplierFilters = [
  <TextInput /*label="Recherche"*/ source={"query"} alwaysOn />,
];

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const SupplierListActions = () => {
  const permissions = localStorage.getItem("permissions");
  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
    </TopToolbar>
  );
};

export const SupplierList: React.FC = (props) => {
  const translate = useTranslate();
  const permissions = localStorage.getItem("permissions");
  return (
    <List
      actions={<SupplierListActions />}
      title={translate('titles.suppliers')}
      empty={<Empty create />}
      {...props}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick="show">
        <TextField source={"code"} /*label="Code"*/ />
        <TextField source={"name"} /*label="Nom"*/ />
        <TextField source={"phone"} /*label="Téléphone"*/ />
        <TextField source={"email"} /*label="Email"*/ />
        <TextField source={"address"} /*label="Adresse"*/ />
        {isGranted("ROLE_MANAGER", permissions) && (
          <FieldWrapper label="Actions">
            <EditButton /*label="Modifier"*/ />
            &nbsp;
            <DeleteWithConfirmButton />
          </FieldWrapper>
        )}
      </DatagridConfigurable>
    </List>
  );
};

export const SupplierCreate: React.FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const onSuccess = () => {
    notify(`Le fournisseur a bien été créé`);
    redirect("/suppliers/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.addsupplier')}
      mutationOptions={{ onSuccess }}
      {...props}
    >
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <TextInput source={"code"} /*label="Code"*/ validate={[required("Champ obligatoire")]} />
          <TextInput source={"name"} /*label="Nom"*/ validate={[required("Champ obligatoire")]} />
          <TextInput source={"phone"} /*label="Téléphone"*/ />
          <TextInput source={"email"} /*label="Email"*/ />
          <TextInput source={"address"} /*label="Adresse"*/ />
        </Box>
      </SimpleForm>
    </Create>
  );
};

interface SupplierTitleProps {
  record: RaRecord;
}

const SupplierTitle: React.FC<SupplierTitleProps> = ({ record }) => {
  return <span>Fournisseur {record ? `"${record.name}"` : ""}</span>;
};

export const SupplierEdit: React.FC<SupplierTitleProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`Le fournisseur a bien été modifié`);
    redirect("/suppliers/");
    refresh();
  };
  return (
    <Edit
      title={<SupplierTitle record={props.record} />}
      mutationMode="pessimistic"
      {...props}
    >
      <SimpleForm justifyContent="center">
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%"
        >
          <TextInput source={"code"} /*label="Code"*/ validate={[required("Champ obligatoire")]} />
          <TextInput source={"name"} /*label="Nom"*/ validate={[required("Champ obligatoire")]} />
          <TextInput source={"phone"} /*label="Téléphone"*/ />
          <TextInput source={"email"} /*label="Email"*/ />
          <TextInput source={"address"} /*label="Adresse"*/ />
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export const SupplierShow: React.FC<SupplierTitleProps> = (props) => (
  <Show
    actions={<EditAction />}
    title={<SupplierTitle record={props.record} />}
    {...props}
    sx={{ fontSize: "20px" }}
  >
    <SimpleShowLayout>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
        }}
        gap={2}
        width="100%"
      >
        <Labeled label="Code">
          <TextField source={"code"} />
        </Labeled>
        <Labeled label="Nom">
          <TextField source={"name"} />
        </Labeled>
        <Labeled label="Téléphone">
          <TextField source={"phone"} />
        </Labeled>
        <Labeled label="Email">
          <TextField source={"email"} />
        </Labeled>
        <Labeled label="Adresse">
          <TextField source={"address"} />
        </Labeled>
      </Box>
    </SimpleShowLayout>
  </Show>
);